import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Carousel,
  CarouselItem,
  Row,
  Col,
} from "reactstrap";

import { useWindowResize } from "../../hooks/useWindowResize";
import {HealthHubContent} from "../../interfaces/models/HealthHubContent"
import {useHealthHubContents} from "../../hooks/useHealthHubContents";
import { getImageUrl } from "../../helper/getImageUrl";
import Loading from "../Loading";

export default function HealthHubCarousel() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [animating, setAnimating] = useState<boolean>(false);
  const [portionedSlides, setPortionedSlides] = useState<HealthHubContent[][]>([]);
  const { screenSizeCategory } = useWindowResize();

  const { data, loading } = useHealthHubContents();

  // Set number if slides to show depending on size of screen
  useEffect(() => {
    if(data) {
      // setLinks(data.health_hub_contents);
      // const dataLinks = data.health_hub_contents;
      // if (screenSizeCategory === "mobile") {
      //   setPortionedSlides(SeparatePortions(dataLinks, 1));
      // } else if (screenSizeCategory === "medium") {
      //   setPortionedSlides(SeparatePortions(dataLinks, 2));
      // } else {
      //   setPortionedSlides(SeparatePortions(dataLinks, 3));
      // }
      const dataLinks = data.health_hub_contents;
      setPortionedSlides(SeparatePortions(dataLinks, 4));
    }
    
    setActiveIndex(0);

  }, [screenSizeCategory, data]);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === portionedSlides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? portionedSlides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = portionedSlides.map((items, index) => (
    <CarouselItem 
      key={index}
      className="" 
      active={index === activeIndex}
      onExited={() => setAnimating(false)}
      onExiting={() => setAnimating(true)}
    >
      <Row className="d-flex flex-row justify-content-evenly">
        {items.map((item, index) => (
          <Col md={3} key={index}>
            <div className="d-flex justify-content-center">
              <div className="bg-white border-dark-hb text-center" style={{borderRadius : "50%", height: "140px", width : "140px"}} >
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img
                  className="mt-4"
                    src={getImageUrl(item.image_id)}
                    alt={item.alt}
                    style={{ 
                      height: "90px",
                      objectFit: "cover"
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-dark">{item.name}</a>
            </div>
          </Col>
        ))}
      </Row>
    </CarouselItem>
  ));

  return (
    <Card className="border-0" style={{backgroundColor: "transparent"}}>
      
      <CardBody className="p-0">
        {
          loading &&
          <Loading />
        }
        {
          slides.length > 0 ?
          <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={null}
          >
            {slides}
            <div className="carousel-control-prev" style={{maxWidth:"32px"}} onClick={previous}>
              <i
                className="fas fa-chevron-circle-left"
                style={{ fontSize: "2rem", color: "black" }}
              ></i>
            </div>
            <div className="carousel-control-next" style={{maxWidth:"32px"}} onClick={next}>
              <i
                className="fas fa-chevron-circle-right"
                style={{ fontSize: "2rem", color: "black" }}
              ></i>
            </div>
          </Carousel> 
          :
          !loading &&
          <h4>
            Nothing new here—keep focusing on your wellness journey!
          </h4>
        }
        
      </CardBody>
    </Card>
  );
}

function SeparatePortions(items: HealthHubContent[], portionsPerSlide: number) {
  const oldItems = [...items];
  var portions: HealthHubContent[][] = [];

  // Loop until slides are empty
  while (oldItems.length > 0) {
    if (oldItems.length >= portionsPerSlide) {
      portions.push(oldItems.splice(0, portionsPerSlide));
    } else {
      portions.push(oldItems);
      break;
    }
  }

  return portions;
}

const imagePrefixUrl = 'https://desk.coach/-jD_0YeceGKIi8TxnnZKWA';
const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%.\w_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/gm;

export const isUrlValidImage = (image: string) =>
  image.match(urlRegex) && image.match(urlRegex).length > 0;

export function getImageUrl(imageId: string) {
  return `${imagePrefixUrl}/${imageId}`;
}

export function getImageSmallUrl(imageId: string) {
  // return `https://imagedelivery.net/-jD_0YeceGKIi8TxnnZKWA/${imageId}/dcwindows`;
  // Return to default as using imagedelivery does not work 
  return `${imagePrefixUrl}/${imageId}`;
}



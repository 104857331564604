import { gql } from "@apollo/client";

export const addTask = gql`
  mutation addTask($record: CreateOneTaskInput!) {
    task_CreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;


export const updateTaskById = gql`
  mutation updateTaskById($_id: MongoID!, $record: UpdateByIdTaskInput!) {
    task_UpdateById(_id: $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

import { gql, useQuery } from "@apollo/client";
export function useVideos() {
  return useQuery(gql`
    query getVideos {
      videos {
        _id
        description
        category
        video_url
        movement
      }
    }
  `);
}

export function useVideosRandom() {
  return useQuery(gql`
    query getVideoRandom {
      video_random {
        _id
        description
        category
        video_url
        movement
      }
    }
  `);
}

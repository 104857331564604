import { Col, Row } from "reactstrap";
import LinkCards from '../../components/ContentManagement/LinkCards';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";

const ContentManagementPage = () => {

    const linkBase = `${DASHBOARD_ROOT}/content-management`
    const breadcrumbsData = [
        { name: 'Content List', path: linkBase },
    ];

    interface ContentList {
        name : string;
        url : string;
        tag : string;
    }

    interface TagList {
        name : string;
        label : string;
    }

    const tags : TagList[] = [ 
        {
            name : 'dashboard',
            label : 'Dashboard Contents'
        },
        {
            name : 'empower.me',
            label : 'Empower ME Contents'
        },
        {
            name : 'sequence',
            label : 'Day Sequence'
        },
    ]

    const contentList : ContentList[] = [
        {
            name : 'Topic of the Week',
            url : `${linkBase}/topic-of-the-week`,
            tag : 'dashboard'
        },
        {
            name : 'Challenge of the Week',
            url : `${linkBase}/challenge-of-the-week`,
            tag : 'dashboard'
        },
        {
            name : 'Health Hub',
            url : `${linkBase}/health-hub`,
            tag : 'dashboard'
        },
        {
            name : 'User Progress',
            url : `${linkBase}/user-progress`,
            tag : 'dashboard'
        },
        {
            name : 'Edit Empower Me Nudges',
            url : `${linkBase}/empower-me`,
            tag : 'empower.me'
        },
        {
            name : 'Sequence Management',
            url : `${linkBase}/day-sequences`,
            tag : 'sequence'
        },
        {
            name : 'Sequence Buckets',
            url : `${linkBase}/day-sequences/buckets`,
            tag : 'sequence'
        },
    ]
    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            {tags.map( (tag, tagIndex) =>{

                return (
                    <div key={tagIndex}>
                        <h3 className="pt-4">{tag.label}</h3>
                        <Row>
                        {contentList.filter(u=>u.tag===tag.name).map((item,index) => {
                            return (
                                <Col  md="4" className="text-center pt-4" key={index} >
                                    <LinkCards
                                        link={item}
                                    /> 
                                </Col>
                            )
                        })}
                    </Row>
                    </div>
                )

            } )}
        </>
    )
}
export { ContentManagementPage as ContentManagement };
import { gql } from "@apollo/client";

export const addHealthHubContent = gql`
  mutation addHealthHubContent($record: CreateOneHealthHubContentInput!) {
    healthHub_CreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const editHealthHubContent = gql`
  mutation editHealthHubContent($_id: MongoID!, $record: UpdateByIdHealthHubContentInput!) {
    healthHub_UpdateById(_id :$_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const removeHealthHubContent = gql`
  mutation removeHealthHubContent($_id: MongoID!) {
    healthHub_RemoveById(_id : $_id) {
      record {
        _id
      }
    }
  }
`;
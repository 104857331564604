import { gql } from "@apollo/client";

export const addContentOfTheWeek = gql`
  mutation addContentOfTheWeek($record: CreateOneContentOfTheWeekInput!) {
    content_CreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const editContentOfTheWeek = gql`
  mutation editContentOfTheWeek($_id: MongoID!,$record: UpdateByIdContentOfTheWeekInput!) {
    content_UpdateById(_id : $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const removeContentOfTheWeek = gql`
  mutation removeContentOfTheWeek($_id: MongoID!) {
    content_RemoveById(_id : $_id) {
      record {
        _id
      }
    }
  }
`;


import { gql, useQuery } from "@apollo/client";

export function useHealthHubContents() {
  return useQuery(
    gql`
      query getHealthHubContents {
        health_hub_contents{
          _id
          name
          description
          image
          image_id
          link
          alt
        }
      }
    `,
    {
      fetchPolicy : 'network-only'
    }
  );
}
import { gql } from "@apollo/client";

export const editProgressProgressContent = gql`
  mutation editProgressProgressContent($_id: MongoID!, $record: UpdateByIdUserProgressContentInput!) {
    userProgress_UpdateById(_id :$_id, record: $record) {
      record {
        _id
      }
    }
  }
`;
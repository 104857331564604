import { GenerateExcel } from "../../helper/excelJsHelpers";
import UserData from "../../interfaces/advanceReporting/UserData";
import { Column } from "exceljs"; 
import { UserStatusTypesArray } from "../../helper/constants"

interface RowData {
    name : string;
    email : string;
    status : string;
}

export const ExportUserActivity = async (data : UserData[]) => {

    if(!data) return; 

    const rows : RowData[] = data.map((item) => {
        return {
            name : item.name,
            email : item.email,
            status : UserStatusTypesArray[item.status - 1].name
        };
    });

    const columns : Partial<Column>[] = [
        { header: "Name", key: "name", width: 50 },
        { header: "Email", key: "email", width: 50 },
        { header: "Status", key: "status", width: 30 },
    ];

    await GenerateExcel("User Activity Tracker", columns, rows);
}
import * as Yup from "yup";

const MIN_TITLE_LENGTH: number = 5;
const MAX_TITLE_LENGTH: number = 100;
const MIN_DESCRIPTION_LENGTH: number = 5;
const MAX_DESCRIPTION_LENGTH: number = 250;


const editEmpowerMeNudgeValidator = Yup.object({
    id : Yup.string()
        .required("Id is Required"),
    name : Yup.string()
        .required("Title is Required")
        .min(
            MIN_TITLE_LENGTH,
            `Name must be at least ${MIN_TITLE_LENGTH} characters long`
        )
        .max(
            MAX_TITLE_LENGTH,
            `Name must be less than ${MAX_TITLE_LENGTH} characters long`
        ),
    description : Yup.string()
        .min(
            MIN_DESCRIPTION_LENGTH,
            `Description text must be at least ${MIN_DESCRIPTION_LENGTH} characters long`
        )
        .max(
            MAX_DESCRIPTION_LENGTH,
            `Description text must be less than ${MAX_DESCRIPTION_LENGTH} characters long`
        ),
    image : Yup.string()
        .test(
            "is-jpg",
            "Image must be a jpg/jpeg image file",
            function (value) {
                if (!value) return true; // Skip validation if the field is empty
                value = value.toLowerCase();

                const validExtensions = [".jpg", ".jpeg"];
                // Check if the file has a valid extension
                const isValid = validExtensions.some(extension => value.endsWith(extension));
                return isValid;
            }
        ),
});

export default editEmpowerMeNudgeValidator;
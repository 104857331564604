import { Input, Col, Button, Row } from "reactstrap";
import FormError from "../../components/Shared/Forms/FormError";
import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from "react";
import { FormikProps } from "formik";
import { getImageUrl } from "../../helper/getImageUrl";
import {useLazyQuery} from "@apollo/client"
import { getImageUploadURL } from "../../graphql/queries/getImageUploadURL";
import Loading from "../Loading";

// TODO : ISSUE it seems using this on formik the lazy query is running but itself when multiple items of this components are used so for now 
// we will have an option to not trigger the lazy query for getting the url and do it outside of this component

interface Props  {
    isDisabled? : boolean;
    formikRef : React.RefObject<FormikProps<any>>;
    extensionAllowed? : string; 
    alt? : string;
    transferFunction : (url : string, fileId : string, blob : File) => void;
    isGettingUrl : (isGetting : boolean) => void;
    imageName : string;
    imageIdName? : string;
    formGroupClassName? : string
    required? :boolean
    dontFetchUrl? : boolean 
 }

const ImageUploadInput =  forwardRef(function ImageUploadInput(
    { formikRef, extensionAllowed, isDisabled, alt, transferFunction, isGettingUrl,
         imageName, imageIdName, formGroupClassName, required , dontFetchUrl = false}: Props,
    ref: React.Ref<{ clearImageField: () => void }>
) 
{ 
    const [imageTypesAllowed, setImageTypesAllowed] = useState<string>("image/png,image/gif,image/jpeg,image/jpg,image/webp,image/svg+xml")

    const [previewImage, setPreviewImage] = useState<string>(null); 
    const [blobFile, setBlobFile] = useState<File>(null);

    const { values, setFieldValue, touched, errors } = formikRef?.current  || {};

    const [
        getSignedUrl,
        { loading: fetchingSignedUrl, error: fetchSignedUrlError },
    ] = useLazyQuery(getImageUploadURL, {
        fetchPolicy: "network-only",
        onCompleted({getImageUploadURL}){
            transferFunction(getImageUploadURL.uploadURL, getImageUploadURL.id, blobFile);
        },
        onError(){
            console.log('error')
        }
    });
    const fetchingSignedUrlBefore = useRef(false);
    useEffect(() => {
        if(fetchingSignedUrlBefore.current !== fetchingSignedUrl){
            fetchingSignedUrlBefore.current = fetchingSignedUrl
            isGettingUrl(fetchingSignedUrl);
        }
    },[fetchingSignedUrl,isGettingUrl])

    useEffect(() =>{
        if(extensionAllowed) {
            setImageTypesAllowed(extensionAllowed);
        }
    },[extensionAllowed]);

     // Function to expose to the parent
     const clearImageField = () => {
        setPreviewImage(null);
        setBlobFile(null);
        transferFunction(null,null,null);
    };

    // Expose the child function to the parent via ref
    useImperativeHandle(ref, () => ({
        clearImageField: clearImageField,
    }));
    const [reseter,setReseter] = useState<boolean>(false);
    const HandleRemoveImage = () => {
        setReseter(!reseter);
        setPreviewImage(null);
        setBlobFile(null);
        setFieldValue(imageIdName, "");
        setFieldValue(imageName, "");
        transferFunction(null,null,null);
    };

    return (
        <div className={`form-group ${formGroupClassName}`}>
            <label>Image {required ? "*" : "" }</label>
            {
                (!previewImage && !values?.[imageIdName]) && 
                <Input
                    name={imageName}
                    type="file"
                    accept={imageTypesAllowed}
                    className={`form-control`}
                    disabled={fetchingSignedUrl}
                    onChange={(event) => {
                        // Use Formik's setFieldValue to set the file value
                        const file = event.currentTarget.files[0]
                        if(file)
                        {
                            const imageUrl = URL.createObjectURL(file);
                            setFieldValue(imageName, file.name);
                            setPreviewImage(imageUrl);
                            setBlobFile(file);
                            if(!dontFetchUrl) {
                                getSignedUrl(); 
                            }
                            else {
                                transferFunction(null,null,file);
                            }
                        }
                        else
                        {
                            setFieldValue(imageName, null);
                            setPreviewImage(null);
                            setBlobFile(null);
                            
                            transferFunction(null,null,null);
                        }
                        
                    }}
                />
            }
            <FormError>
                {touched?.[imageName] && typeof errors?.[imageName] === 'string' && (errors?.[imageName] as string) }
                {touched?.[imageIdName] && typeof errors?.[imageIdName] === 'string' && (errors?.[imageIdName] as string) }
            </FormError>
            {fetchSignedUrlError && (
                <FormError>Something went wrong whilst uploading image.</FormError>
            )}
            {
                fetchingSignedUrl ?  
                    <Loading /> 
                :
                <Row>
                    <Col>
                        {
                            (previewImage || values?.[imageIdName]) &&
                            <img
                                className="pt-3"
                                style={{
                                    width: "100%",
                                    borderRadius: "0.375rem",
                                    marginBottom: "1rem",
                                }}
                                src={previewImage || getImageUrl(values?.[imageIdName])}
                                alt={alt}
                            />
                        }
                    </Col>
                    <Col className="d-flex align-items-center">
                        {
                            (previewImage || values?.[imageIdName]) &&
                            (
                                <Button 
                                    title="Click to remove this image and to upload new image."
                                    color="secondary" 
                                    type="button" 
                                    disabled={isDisabled}
                                    onClick={HandleRemoveImage}
                                >
                                    <div>
                                        <i className="fas fa-trash-alt"></i>
                                    </div>
                                </Button>
                            )
                        }       
                    </Col>
                </Row>
                
            }
        </div>
    )
});

export default ImageUploadInput;
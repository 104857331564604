import {
  Alert,
  Col,
  Row,
} from "reactstrap";
import Loading from "../../components/Loading";
import { TaskAssignment } from "../../interfaces/models/TaskAssignment";
import useDashboardTasks from "../../hooks/useDashboardTasks";
import { TaskSequence } from "../../interfaces/models/TaskSequence";
import { useEffect, useState } from "react";
import {
  DESK_COACH,
  WORKHEALTH_COACH,
} from "../../components/Routes/routingConstants";
import WeeklyChallengeCard from "../../components/Dashboard/WeeklyChallengeCard";
import HealthHubCarousel from "../../components/Dashboard/HealthHubCarousel";
import TaskSequenceCarousel from "../../components/Dashboard/TaskSequenceCarousel";
import MovementCoachPreview from "../../components/Dashboard/MovementCoachPreview";

const DashboardPage = () => {
  const [dashboardView, setDashboardView] = useState<string>(null);
  const [dashboardTaskAssignments, setDashboardTaskAssignments] = useState<
    TaskAssignment[]
  >([]);
  const [dashboardTaskSequence, setDashboardTaskSequence] =
    useState<TaskSequence>(null);


  const { data, loading, error } = useDashboardTasks();
  const products = data?.me.user_license?.products
    ?.split(",")
    .map((product: string) => {
      return product.trim();
    });
  
  const firstProduct = products?.[0] || null

  useEffect(() => {
    if (data) {
      // Set dashboard view
      if (dashboardView === WORKHEALTH_COACH) {
        let taskAssignments: TaskAssignment[] = [];

        if (data.workhealth_task_assignments !== null) {
          taskAssignments = data.workhealth_task_assignments.map((wTA: any) => {
            return { ...wTA, task: wTA.workhealth_task };
          });
        }
        setDashboardTaskAssignments(taskAssignments);

        if (data.me.workhealth_sequence !== null) {
          // Filter out any null tasks in Sequence
          const filteredWHTaskSequence =
            data.me.workhealth_sequence.sequence.filter((WSeq: any) => {
              return WSeq.workhealth_task != null;
            });
          const whTaskSequence = filteredWHTaskSequence.map((WSeq: any) => {
            return { ...WSeq, task: { ...WSeq.workhealth_task } };
          });
          const dashboardTaskSequence = {
            _id: data.me.workhealth_sequence._id,
            sequence: whTaskSequence,
          };
          setDashboardTaskSequence(dashboardTaskSequence);
        }
      } else if (dashboardView === DESK_COACH) {
        setDashboardTaskAssignments(data.task_assignments);
        if (data.me.task_sequence != null) {
          // Filter out null any null tasks in Sequence
          const filteredTaskSequence = data.me.task_sequence.sequence.filter(
            (s) => {
              return s.task != null;
            }
          );
          const dashboardTaskSequence = {
            _id: data.me.task_sequence._id,
            sequence: filteredTaskSequence,
          };
          setDashboardTaskSequence(dashboardTaskSequence);
        }
      } else if (data.me.user_license?.products?.length > 0) {
        setDashboardView(firstProduct);
      }
    }
  }, [data, dashboardView, firstProduct]);

  const userIsLicensed: boolean = products?.length > 0 ? true : false;

  return (
    <>
      {loading && <Loading />}
      {error && (
        <Alert color="danger">
          Oops, we’re having trouble refreshing this connection. Please give it a minute, and if it doesn’t resolve, let us know.
        </Alert>
      )}
      {(data && userIsLicensed) && (
        <Col>
          <Row>
            <Col md={12}>
              <h3 >Your mission, should you choose to accept it?</h3>
            </Col>
            <Col md={12} style={{minHeight:250}}>
              <WeeklyChallengeCard />
            </Col>
            <Col md={12} className="mt-1">
              <Row>
                <Col md={6}>
                  <h3 className=" mt-5">Upcoming Nudges</h3>
                  {
                    (dashboardTaskSequence && dashboardTaskAssignments) &&
                    <TaskSequenceCarousel
                      task_sequence={dashboardTaskSequence}
                      task_assignments={dashboardTaskAssignments}
                    />
                  }
                  
                </Col>
                <Col md={6}>
                  <h3 className="mt-5">Try Me!</h3>
                  <MovementCoachPreview />
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mt-5">
              <h3 >Health Hub</h3>
            </Col>
            <Col md={12} className="mt-1" style={{minHeight:170}}>
              <HealthHubCarousel />
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
};

export { DashboardPage as Dashboard };

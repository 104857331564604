import * as Yup from "yup";

const MIN_TITLE_LENGTH: number = 5;
const MAX_TITLE_LENGTH: number = 50;


const AddTaskSequenceValidator = Yup.object({
    name : Yup.string()
        .required("Name is Required")
        .min(
            MIN_TITLE_LENGTH,
            `Name must be at least ${MIN_TITLE_LENGTH} characters long`
        )
        .max(
            MAX_TITLE_LENGTH,
            `Name must be less than ${MAX_TITLE_LENGTH} characters long`
        ),
    image : Yup.string()
        .required("Image is required")
        .test(
            "is-jpg",
            "Image must be a valid jpg/jpeg image file",
            function (value) {
                if (!value) return true; // Skip validation if the field is empty
                value = value.toLowerCase();

                const validExtensions = [".jpg", ".jpeg"];
                // Check if the file has a valid extension
                const isValid = validExtensions.some(extension => value.endsWith(extension));
                return isValid;
            }
        ),
});

export default AddTaskSequenceValidator;
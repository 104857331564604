import { gql } from "@apollo/client";

export const addTaskSequence = gql`
  mutation addTaskSequence($record: CreateOneTaskSequenceInput!) {
    task_sequence_CreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const updateTaskSequence = gql`
  mutation updateTaskSequence($_id: MongoID!, ,$record: UpdateByIdTaskSequenceInput!) {
    task_sequence_UpdateById(_id :$_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const addTaskToSequence = gql`
  mutation addTaskToSequence($sequenceId: MongoID!,$taskId: MongoID! ) {
    task_sequence_AddTask(sequenceId :$sequenceId, taskId: $taskId) {
      _id
    }
  }
`;

export const removeTaskToSequence = gql`
  mutation removeTaskToSequence($sequenceId: MongoID!,$taskId: MongoID! ) {
    task_sequence_RemoveTask(sequenceId :$sequenceId, taskId: $taskId) {
      _id
    }
  }
`;
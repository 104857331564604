export enum NudgeClickUrlType {
  Link,
  Video,
}

export const getNudgeClickUrlType = (url: string) => {
  if (url && ((url.includes("/video/") && url.includes("desk")) || (url.includes("/video/") && url.includes("http://localhost:3000/")))) {
    return NudgeClickUrlType.Video;
  }
  return NudgeClickUrlType.Link;
};

import { Column, Workbook } from "exceljs";
import Logo from "../assets/img/brand/deskcoach-dark.png"
import axios from "axios";

export const GenerateExcel = async (title : string, column : Partial<Column>[], row : any[]) => {
    // Create a new workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const bgColor = "F3E9FF";
    const headerFontColor = "110035";

    // Add an image below the title
    // Fetch and add image
    const response = await axios.get(Logo, { responseType: "arraybuffer" });

    const imageId = workbook.addImage({
        buffer : response.data, // Use ArrayBuffer
        extension: "png", // Define the format
    });

    const lastColumn = String.fromCharCode(column.length + 'A'.charCodeAt(0) - 1) + 1;

    worksheet.addImage(imageId, lastColumn + ":" + lastColumn); // Define where the image should appear
    worksheet.getRow(1).height = 30;
    worksheet.getCell(lastColumn).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: bgColor }, 
    };
    worksheet.getCell(lastColumn).font = { bold: true, color: { argb: headerFontColor } }; 
        
    // Add column headers
    worksheet.columns = column;
    worksheet.getRow(1).values = [];
    worksheet.mergeCells("A1:"+ lastColumn);
    const titleCell = worksheet.getCell("A1");
    titleCell.value = title; 
    titleCell.font = { size: 16, bold: true, color: { argb: headerFontColor } }; 


    worksheet.getRow(2).values = column.map((u) => typeof u.header === "string" ? u.header : u.header.join(", "));
    titleCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: bgColor }, 
    };
    // Style the header row
    worksheet.getRow(2).font = { bold: true };
    
    //add styles
    worksheet.getRow(2).eachCell((cell) => {
        cell.font = { bold: true, color: { argb: headerFontColor } }; 
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: bgColor },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    worksheet.views = [
        {
          state: 'frozen',
          ySplit: 2, // This freezes the first two rows
        },
    ];
      
    // Add rows
    worksheet.addRows(row);
        
    // Apply border to all cells
    worksheet.eachRow((row, rowIndex) => {
        row.eachCell((cell) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            if (rowIndex > 1) {
                cell.alignment = { horizontal: "left", vertical: "middle" }; // Left-align for data rows
            }
        });
    });

    // Create a buffer to store the workbook
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a link element and download the file
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "export.xlsx";
    anchor.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
};
import BootstrapTable from "react-bootstrap-table-next";
import PaginationMenu from "../../components/Tables/PaginationMenu";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { parseRRule } from "../../helper/rruleUtilities";
import { EmpowerScheduledTask } from "../../interfaces/models/EmpowerTask";
import { getImageSmallUrl } from "../../helper/getImageUrl";
import { IsEmpowerNudgeActive } from "../../helper/empowerNudgeHelpers";
import { Badge } from "reactstrap";
import { DateFormat } from "../../helper/constants"
import moment from 'moment';

interface EmpowerTableProps {
  empowerTasks: EmpowerScheduledTask[];
  deleteEmpowerMeTask: (empowerTask: EmpowerScheduledTask) => void;
}

export default function EmpowerMeTaskTable({
  empowerTasks,
  deleteEmpowerMeTask,
}: EmpowerTableProps) {
  const thumbnailFormatter = (cell, row: EmpowerScheduledTask) => {
    return (
      <div className="d-flex justify-content-center items-center">
        <img
          alt="thumbnail"
          style={{ width: "6rem" }}
          src={getImageSmallUrl(row.empower_me_task?.image_id ?? row.empower_me_task?.task_category?.image)}
        />
      </div>
    );
  };
  const titleFormatFilter = (cell, row: EmpowerScheduledTask) => {
    const task_title = row?.empower_me_task?.name ?? "Unknown";
    return task_title;
  };
  const titleFormatter = (cell, row: EmpowerScheduledTask) => {
    return <p>{titleFormatFilter(cell, row)}</p>;
  };

  const categoryFormatter = (cell, row: EmpowerScheduledTask) => {
    return row?.empower_me_task?.task_category?.description ? (
      <p>{row.empower_me_task.task_category.description}</p>
    ) : (
      <p>No category</p>
    );
  };
  const activeFormatter = (cell, row: EmpowerScheduledTask) => {
    const isActive = IsEmpowerNudgeActive(row);
    return (
      <div className={"d-flex justify-content-center"}>
        <Badge
          style={{ fontWeight: "bold" }}
          color={isActive ? "primary" : "danger"}
          pill
        >
          {isActive ? "Active" : "Inactive"}
        </Badge>
      </div>
    );
  };
  const startDateFormatter = (cell, row: EmpowerScheduledTask) => {
    // const formattedDate = new Date(row.start_date).toLocaleString();
    const formattedDate = moment(row.start_date).format(DateFormat + " h:mm a")
    return <p>{formattedDate}</p>;
  };
  const scheduleFormatter = (cell, row: EmpowerScheduledTask) => {
    if (!row?.recurrence_rule) {
      return <p>No repeat</p>;
    } else {
      return <p>{parseRRule(row.recurrence_rule)}</p>;
    }
  };
  const buttonOptionsFormatter = (cell, row: EmpowerScheduledTask) => {
    return (
      <button className="table-button" onClick={() => deleteEmpowerMeTask(row)}>
        <i className="fas fa-trash-alt fa-2x" />
      </button>
    );
  };

  const { SearchBar } = Search;

  return (
    <div>
      <ToolkitProvider
        data={empowerTasks}
        keyField="_id"
        columns={[
          {
            dataField: "id",
            text: "",
            sort: false,
            formatter: thumbnailFormatter,
          },
          {
            dataField: "task.name",
            text: "Title",
            sort: false, // TODO: add support to sort this and categories in future if needed
            formatter: titleFormatter,
            filterValue: (cell, row) => titleFormatFilter(cell, row),
          },
          {
            dataField: "task.task_category.description",
            text: "Category",
            sort: false,
            formatter: categoryFormatter,
          },
          {
            dataField: "active",
            text: "Active/Inactive",
            sort: false, // TODO: add support to sort this field
            formatter: activeFormatter,
          },
          {
            dataField: "start_date",
            text: "Start Date",
            sort: true,
            formatter: startDateFormatter,
          },
          {
            dataField: "recurrence_rule",
            text: "Schedule",
            sort: false,
            formatter: scheduleFormatter,
          },
          {
            dataField: "buttonOptions",
            text: "",
            sort: false,
            formatter: buttonOptionsFormatter,
          },
        ]}
        search
      >
        {(props) => (
          <div className="py-4 table-empower">
            <div
              id="datatable-basic_filter"
              className="dataTables_filter px-4 pb-1"
            >
              <SearchBar
                className="form-control-sm"
                placeholder=""
                {...props.searchProps}
              />
            </div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              pagination={PaginationMenu}
              rowClasses="table-row"
              hover={true}
              defaultSorted={[{ dataField: "start_date", order: "desc" }]}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}

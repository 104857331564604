import { GenerateExcel } from "../../helper/excelJsHelpers";
import { Column } from "exceljs"; 
import { ActivityHistoryTableDataTypeValue, DateFormat } from "../../helper/constants"
import moment from "moment";
import { UserActivityLogs } from "../../interfaces/models/UserActivityLogs"

interface RowData {
    log : string;
    date : string;
}

export const ExportUserActivity = async (name : string, data : UserActivityLogs[]) => {

    if(!data) return; 

    const rows : RowData[] = data.map((item) => {
        return {
            log : ActivityHistoryTableDataTypeValue[item.type - 1],
            date : moment(item.created_at_utc).format(DateFormat)
        };
    });

    const columns : Partial<Column>[] = [
        { header: "Log", key: "log", width: 50 },
        { header: "Date", key: "date", width: 30 },
    ];

    await GenerateExcel(name + "'s Activity Logs", columns, rows);
}
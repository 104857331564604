import * as Yup from "yup";

const MIN_TITLE_LENGTH: number = 5;
const MAX_TITLE_LENGTH: number = 50;
const MIN_DESCRIPTION_LENGTH: number = 5;
const MAX_DESCRIPTION_LENGTH: number = 500;


const editTopicValidator = Yup.object({
    id : Yup.string()
        .required("Id is Required"),
    title : Yup.string()
        .required("Title is Required")
        .min(
            MIN_TITLE_LENGTH,
            `Title must be at least ${MIN_TITLE_LENGTH} characters long`
        )
        .max(
            MAX_TITLE_LENGTH,
            `Title must be less than ${MAX_TITLE_LENGTH} characters long`
        ),
    description : Yup.string()
        .required("Description is Required")
        .min(
            MIN_DESCRIPTION_LENGTH,
            `Description must be at least ${MIN_DESCRIPTION_LENGTH} characters long`
        )
        .max(
            MAX_DESCRIPTION_LENGTH,
            `Description must be less than ${MAX_DESCRIPTION_LENGTH} characters long`
        ),
    startDate : Yup.string()
        .required("Start Date is Required"),
    endDate : Yup.string()
        .required("End Date is Required")
        .test(
            "is-endDate-after-startDate",
            "End Date must be greater than Start Date",
            function (value) {
                const { startDate } = this.parent;
                if (!startDate || !value) {
                    return true; // Skip validation if either date is not provided
                }
                const start = new Date(startDate);
                const end = new Date(value);
                return end > start;
            }
        ),
    image : Yup.string()
        .required("Image is required")
        .test(
            "is-jpg",
            "Image must be a valid image file",
            function (value) {
                if (!value) return true; // Skip validation if the field is empty
                value = value.toLowerCase();

                const validExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp", ".svg"];
                // Check if the file has a valid extension
                const isValid = validExtensions.some(extension => value.endsWith(extension));
                return isValid;
            }
        ),
});

export default editTopicValidator;
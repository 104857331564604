import { gql } from "@apollo/client";

// TODO: move this to hook 'useEmpowerMeSchedule'
export const getEmpowerMeTaskSchedule = gql`
  query {
    me {
      empower_task_schedule {
        scheduled_tasks {
          _id
          start_date
          last_assigned_at
          recurrence_rule
          empower_me_task {
            _id
            name
            description
            image_id
            task_category {
              description
              image
            }
          }
        }
      }
    }
  }
`;

export const getEmpowerMeTasks = gql`
  query getEmpowerMeTasks {
    empower_me_tasks {
      _id
      name
      description
      image_id
      is_active
      task_category {
        description
        image
      }
    }
  }
`;

export const getEmpowerMeTasksActive = gql` 
  query getEmpowerMeTasksActive {
    empower_me_tasks_active {
      _id
      name
      description
      task_category {
        description
        image
      }
    }
  }
`;

export const getEmpowerMeTasksSorted = gql`
  query getEmpowerMeTasksSorted {
    empower_me_tasks_sorted_by_actives {
      _id
      name
      description
      image_id
      is_active
      task_category {
        description
        image
      }
    }
  }
`;


export const editEmpowerMeNudge = gql`
  mutation editEmpowerMeNudge($_id: MongoID!,$record: UpdateByIdEmpowerMeTaskInput!) {
    empowerMe_UpdateById(_id : $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;
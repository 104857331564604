import { Pie } from "react-chartjs-2"
import { ChartOptions, ChartData } from "chart.js";
import { useGetUserActivityOverviewData} from "../../hooks/AdvanceReporting/useGetUserActivities";
import { useEffect, useState } from "react";
import Loading from "../Loading";
import { Alert } from "reactstrap";
import "chartjs-plugin-labels";
import { FontFamily, ChartJSBaseFontColor, UserActivityGroupType } from "../../helper/constants"

//#region Interfaces
interface Props {
  organisation_id? : string;
  user_id? : string;
  is_print? : boolean;
}

interface OverviewData {
  type : number;
  count : number;
}
//#endregion Interfaces

export default function UserMetricsPieChart({organisation_id, user_id, is_print = false} :Props) {

//#region Initialization
  const { data, loading, error } = useGetUserActivityOverviewData(organisation_id, user_id);
  const [graphData, setGraphData] = useState<ChartData<"pie">>({});
  
  useEffect(() => {
    if(data) {
      
      const dataset = data.user_activity_overview_data;
      let arrayDataset = [0,0,0,0];
      let arrayDatasetGroups = [UserActivityGroupType.NUDGE,UserActivityGroupType.MIC,UserActivityGroupType.MC,UserActivityGroupType.EM];

      for(let x = 0; x < arrayDatasetGroups.length; x++) {
        const getCount : OverviewData = dataset.find((u : OverviewData) => u.type === arrayDatasetGroups[x]);
        if(getCount) {
          arrayDataset[x] = getCount.count;
        }
      }
      

      setGraphData({
        labels: ["desk.coach", "myintention.coach", "movement.coach", "Empower Me"],
        datasets: [
          {
            label: "Engagements",
            data: arrayDataset,
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  },[data]);


  // Options for the pie chart
  const options: ChartOptions<"pie"> = {
    responsive: true,
    maintainAspectRatio: false,
    legend :{
        labels: {
            fontColor : ChartJSBaseFontColor,
            fontSize : 20,
        }
    },
    title: {
        display: true, // Enable the title
        text: "User Activity Overview", // Title text
        fontSize: 22, // Font size for the title
        padding: 20, // Padding around the title
        fontColor : ChartJSBaseFontColor,
        fontFamily : FontFamily,
    },
    plugins: is_print ? {
      labels: {
        render: (args : any) => {
          const { percentage, value, label } = args; // Extract percentage and raw value
          return `${label} - ${value} (${percentage.toFixed(0)}%)`;
        },
        fontColor: ChartJSBaseFontColor, // Label color
        fontFamily : FontFamily,
        fontSize: 16,
        fontStyle: "bold",
        position: "outside", // Position outside the pie
        textMargin: 10, // Margin between the label and the pie
      }
    } 
    : {}
  };
//#endregion Initialization
    
  return (
    <div style={{ height: "400px" }}>
      {
        error &&
        <Alert color="danger">
            Oops, we’re having trouble getting the graph. Please give it a minute, and if it doesn’t resolve, let us know.
        </Alert>
      }  
      {
        loading ? 
        <Loading /> 
        : <Pie data={graphData} options={options} />
      }
    </div>
  );
}
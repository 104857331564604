import * as Yup from "yup";

const MIN_TITLE_LENGTH: number = 5;
const MAX_TITLE_LENGTH: number = 50;
const MIN_COLOR_LENGTH: number = 7;
const MAX_COLOR_LENGTH: number = 7;


const EditRingValidator = Yup.object({
    name : Yup.string()
        .required("Name is Required")
        .min(
            MIN_TITLE_LENGTH,
            `Name must be at least ${MIN_TITLE_LENGTH} characters long`
        )
        .max(
            MAX_TITLE_LENGTH,
            `Name must be less than ${MAX_TITLE_LENGTH} characters long`
        ),
    color : Yup.string()
        .required("Color is Required")
        .min(
            MIN_COLOR_LENGTH,
            `Color  must be at least ${MIN_COLOR_LENGTH} characters long`
        )
        .max(
            MAX_COLOR_LENGTH,
            `Color  must be less than ${MAX_COLOR_LENGTH} characters long`
        )
        .matches(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/, 'Enter a valid hex color (#FFF or #FFFFFF)')
});

export default EditRingValidator;
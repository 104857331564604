import { Table, Button} from 'reactstrap';
import moment from 'moment';
import { ContentOfTheWeek } from '../../interfaces/models/ContentOfTheWeek'
import Loading from '../Loading';
import { getImageSmallUrl } from "../../helper/getImageUrl";
import {DateFormat} from "../../helper/constants"

interface Props {
    contents : ContentOfTheWeek[],
    handleDelete : (item : ContentOfTheWeek) => void; 
    handleEdit : (item : ContentOfTheWeek) => void; 
    isLoading : boolean;
}

export default function ContentWeekTable({contents, handleDelete , handleEdit, isLoading} : Props){
    
    return (
        <Table striped>
            <thead>
                <tr>
                    <th style={{width : '20%'}} >Image</th>
                    <th style={{width : '10%', maxWidth : '10%'}}>Title</th>
                    <th style={{width : '30%', maxWidth : '30%'}}>Description</th>
                    <th >Date</th>
                    <th >Action</th>
                </tr>
            </thead>
            <tbody>
                {contents.length === 0 && !isLoading && 
                    <tr>
                        <td colSpan={5} className="text-center">No Data Found</td>
                    </tr>
                }
                {isLoading && 
                    <tr>
                        <td colSpan={5} className="text-center">
                            <Loading />
                        </td>
                    </tr>
                }
                {
                    contents.map((item : ContentOfTheWeek, index : number) =>
                        {
                            const currentDate = new Date()
                            return (
                                <tr key={index}>
                                    <td>
                                        <img
                                            style={{
                                                borderRadius: "0.375rem",
                                                marginBottom: "1rem",
                                                height : "100px",
                                                width : "150px",
                                                objectFit :"cover"
                                            }}
                                            src={getImageSmallUrl(item.image_id)}
                                            alt={item.title}
                                        />
                                    </td>
                                    <td>
                                        {item.title}
                                    </td>
                                    <td >
                                        <div style={{ width : 250, maxWidth : 250, 
                                            wordWrap :"break-word", overflowWrap :"break-word", 
                                            whiteSpace: "pre-wrap" }}>
                                            {item.description}
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            new Date(item.end_date) < currentDate 
                                            ? <span title="Date already passed" className=" text-danger"><i className="far fa-calendar-times fa-lg"></i> <b>{moment(item.start_date).format(DateFormat)}</b> to <b>{moment(item.end_date).format(DateFormat)}</b></span>
                                            : new Date(item.start_date) <= currentDate && new Date(item.end_date) >= currentDate 
                                            ? <span title="Currently Showing for the week" className=" text-success"><i className="fa fa-calendar-check fa-lg"></i> <b>{moment(item.start_date).format(DateFormat)}</b> to <b>{moment(item.end_date).format(DateFormat)}</b></span>
                                            : <span title="Set for the Future Dates" className=" text-primary"><i className="fas fa-hourglass-half"></i> <b>{moment(item.start_date).format(DateFormat)}</b> to <b>{moment(item.end_date).format(DateFormat)}</b></span>
                                        } 
                                    </td>
                                    <td>
                                        <Button color="primary" size='sm'onClick={()=>{ handleEdit(item) }} >Edit</Button>
                                        <Button color="danger" size='sm' onClick={()=>{handleDelete(item)}} >Delete</Button>
                                    </td>
                                </tr>
                            )
                        }
                    )
                }
                
            </tbody>
        </Table>
    )

}


import { gql, useQuery } from "@apollo/client";

export function useTaskSequenceBuckets() {
  return useQuery(gql`
    query getTaskSequenceBuckets {
      task_sequence_buckets {
        _id
        name
        description
        type
        task_sequences_ids
        status
      }
    }
  `);
}

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import { useState, useEffect, useRef  } from 'react';
import moment from 'moment';
import { Button, Alert} from 'reactstrap';
import {DataFormik, ContentWeekEditorModal } from '../../components/ContentManagement/ContentWeekEditorModal';
import PromptDialog from "../../components/PromptDialog";
import { ContentOfTheWeek } from '../../interfaces/models/ContentOfTheWeek'
import ContentWeekTable from '../../components/ContentManagement/ContentWeekTable';
import { useContentOfTheWeek, ContentOfTheWeekType} from '../../hooks/useContentOfTheWeek'
import {addContentOfTheWeek, editContentOfTheWeek, removeContentOfTheWeek} from '../../graphql/content-management/addContentOfTheWeek'
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { executeDeleteImage } from "../../graphql/queries/getImageUploadURL";

const TopicWeekEditorPage = () => {
//#region Initilization
    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Edit Topic of the Week', path: `${DASHBOARD_ROOT}/content-management/topic-of-the-week` },
    ];
    const [topics, setTopics] = useState<ContentOfTheWeek[]>([]);
    const [deleteFile] = useMutation(executeDeleteImage);

    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
    const [tobeAddedData, setTobeAddedData] = useState<DataFormik>();
    const addModalRef = useRef<{ clearImageField: () => void }>(null);

    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
    const [tobeUpdatedData, setTobeUpdatedData] = useState<DataFormik>();
    const updateModalRef = useRef<{ clearImageField: () => void }>(null);
    
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [toBeDeletedData,setToBeDeletedData] = useState<ContentOfTheWeek>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const { data, loading, error } = useContentOfTheWeek(ContentOfTheWeekType.TOPIC);

    const [addTopicHook] = useMutation(addContentOfTheWeek,{
        onCompleted : () =>{
            toast.success("Topic was successfully added");
        },
        onError : () =>{
            toast.error("Something went wrong whilst Adding the Topic. Please try again or if issue persists, please contact us");
        }
    });

    const [updateTopicHook] = useMutation(editContentOfTheWeek,{
        onCompleted : () =>{
            toast.success("Topic was successfully updated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst updating the Topic. Please try again or if issue persists, please contact us");
        }
    });

    const [removeTopicHook] = useMutation(removeContentOfTheWeek,{
        onCompleted : () =>{
            toast.success("Topic was successfully deleted");
        },
        onError : () =>{
            toast.error("Something went wrong whilst deleting the Topic. Please try again or if issue persists, please contact us");
        }
    });

    function Reloader(){
        setTimeout(()=>{
            window.location.reload();
        },1500)
    }

    useEffect(() =>{
        if(data != null) {
            setTopics(data.content_of_the_weeks_by_type);
        }
    },[data])
//#endregion Initilization
//#region Validation 
const ContentOfTheWeekDateValidator = async (formData : DataFormik) => {
    const startDate = new Date(formData.startDate)
    const endDate = new Date(formData.endDate)

    const isStartDateOverLapped = topics.find(u => new Date(u.start_date) <= startDate && new Date(u.end_date) >= startDate && formData.id !== u._id);
    if(isStartDateOverLapped) {
        toast.error(`Start date of this Topic overlaps with the dates of "${isStartDateOverLapped.title}"`, );
        return false;
    }

    const isEndDateOverLapped = topics.find(u => new Date(u.start_date) <= endDate && new Date(u.end_date) >= endDate && formData.id !== u._id);
    if(isEndDateOverLapped) {
        toast.error(`End date of this Topic overlaps with the dates of "${isEndDateOverLapped.title}"`, );
        return false;
    }

    return true;
}
//#endregion Validation
//#region Add Functions
    const toggleAddModal = () =>{
        setIsAddModalOpen(!isAddModalOpen);
    }

    const handleAdd = () =>{
        setTobeAddedData(
            {
                id : "1",
                image : "",
                title : "",
                description :  "",
                startDate : moment().format("yyyy-MM-DD"),
                endDate : moment().add(7,'d').format("yyyy-MM-DD"),
                image_id : ""
            },
        )
        addModalRef.current?.clearImageField();
        toggleAddModal();
    }

    const submitAdd = async (formData : DataFormik, fileId : string) =>{
        return new Promise<boolean>((resolve, reject) => {
            try {
                addTopicHook({
                    variables : {
                        record : {
                            image : formData.image,
                            title : formData.title,
                            description : formData.description,
                            start_date : moment(new Date(formData.startDate)).format("YYYY-MM-DDTHH:mm:ssZ"),
                            end_date : moment(new Date(formData.endDate)).format("YYYY-MM-DDTHH:mm:ssZ"),
                            type : ContentOfTheWeekType.TOPIC,
                            image_id : fileId
                        }
                    },
                })
                .then(async (res) => {
                    if(!res.errors) {
                        toggleAddModal();
                        resolve(true);
                        Reloader();
                    } 
                    else {
                        reject(false);
                    }
                })
                .catch(()=>{
                    reject(false);
                })
            }
            catch{
                reject(false);
            }
        })
    }
//#endregion Add Functions
//#region Edit Functions
    const toggleUpdateModal = () =>{
        setIsUpdateModalOpen(!isUpdateModalOpen);
    }

    const handleEdit = (item : ContentOfTheWeek) =>{
        setTobeUpdatedData({
            id : item._id,
            image : item.image,
            title : item.title,
            description : item.description,
            startDate : moment(item.start_date).format("yyyy-MM-DD"),
            endDate : moment(item.end_date).format("yyyy-MM-DD"),
            image_id : item.image_id
        })
        updateModalRef.current?.clearImageField();
        toggleUpdateModal();
    }

    const submitEdit = async (formData : DataFormik, fileId : string) =>{
        return new Promise<boolean>((resolve, reject) => {
            try {
                updateTopicHook({
                    variables : {
                        _id : tobeUpdatedData.id,
                        record : {
                            image : formData.image,
                            title : formData.title,
                            description : formData.description,
                            start_date : moment(new Date(formData.startDate)).format("YYYY-MM-DDTHH:mm:ssZ"),
                            end_date : moment(new Date(formData.endDate)).format("YYYY-MM-DDTHH:mm:ssZ"),
                            image_id : fileId ? fileId : tobeUpdatedData.image_id
                        }
                    },
                })
                .then(async (res) => {
                    if(!res.errors){
                        toggleUpdateModal();
                        resolve(true);
                        Reloader();
                    }else{
                        reject(false);
                    }
                })
                .catch(()=>{
                    reject(false);
                })
            }
            catch{
                reject(false);
            }
        })
    }

//#endregion Edit Functions
//#region Delete Functions
    const handleDelete = (item : ContentOfTheWeek) => {
        setToBeDeletedData(item);
        setIsDeleteModalOpen(true);
    }

    const handleDeleteModalCancel = () => {
        setIsDeleteModalOpen(false);
    }

    const handleDeleteModalConfirm = async () => {
        if(isDeleting) {
            return;
        }
        try {
            setIsDeleting(true);
            await removeTopicHook({
                variables : {
                    _id : toBeDeletedData._id
                }
            }).then(async (res) => {
                if(!res.errors) {
                    await deleteFile({
                        variables : {
                            fileId : toBeDeletedData.image_id
                        }
                    })
                    Reloader();
                }
                else {
                    setIsDeleting(false);
                }
            })
        }
        catch{
            setIsDeleting(false);
        }
    }
//#endregion Delete Functions

    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            <div className='d-flex justify-content-between'>
                <div>
                    <span>Legends : </span>
                    <span className="mr-2 text-danger"><i className="far fa-calendar-times "></i> Past - </span>
                    <span className="mr-2 text-success"><i className="far fa-calendar-check"></i> Present - </span>
                    <span className="mr-2 text-primary"><i className="fa fa-hourglass-half"></i> Future</span>
                </div>
                <Button color="primary mb-2" onClick={handleAdd}>Add Topic</Button>
            </div>
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the topics. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }
            <div className="table-responsive">
                <ContentWeekTable 
                    contents={topics}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    isLoading={loading}
                />
            </div>

            <ContentWeekEditorModal
                title="Add Topic"
                ref={addModalRef}
                dataFormik={tobeAddedData}
                modalOpen={isAddModalOpen}
                toggleModal={toggleAddModal}
                buttonName="Add"
                onSubmit={submitAdd}
                validationBeforeSubmit={ContentOfTheWeekDateValidator}
            />

            <ContentWeekEditorModal
                title="Edit Topic"
                ref={updateModalRef}
                dataFormik={tobeUpdatedData}
                modalOpen={isUpdateModalOpen}
                toggleModal={toggleUpdateModal}
                buttonName="Edit"
                onSubmit={submitEdit}
                validationBeforeSubmit={ContentOfTheWeekDateValidator}
            />

            <PromptDialog
            isOpen={isDeleteModalOpen}
            onCancel={handleDeleteModalCancel}
            onConfirm={handleDeleteModalConfirm}
            title="Delete Topic"
            promptMessage="Are you sure you want to delete this Topic?"
            confirmBtnTxt="Delete"
            isOnProcess={isDeleting}
            />               
           
        </>
    )

}

export { TopicWeekEditorPage as TopicWeekEditor };
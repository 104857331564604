import { gql } from "@apollo/client";

export const addCategory = gql`
  mutation addCategory($record: CreateOneCategoryInput!) {
    category_CreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const updateCategory = gql`
  mutation updateCategory($_id: MongoID!, ,$record: UpdateByIdCategoryInput!) {
    category_UpdateById(_id :$_id, record: $record) {
      record {
        _id
      }
    }
  }
`;
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/auth/azure.config";
import deskcoachlogo from "../assets/img/brand/DC_logo_for web_indigo.png";

async function handleLogin(msalInstance) {
  msalInstance.loginRedirect(loginRequest).catch((ex) => {
    //If interaction is in progress, clear status cookie
    if (ex.errorCode === "interaction_in_progress") {
      //Clear status cookie and try again
      document.cookie =
        "msal.interaction.status=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      msalInstance.loginRedirect(loginRequest);
    }
  });
}

const LoginPage = () => {
  const { instance } = useMsal();

  return (
    <>
      <Container className="mt-6">
        <Row className="justify-content-center">
          <Col md="6" lg="6">
            <Card>
              <CardHeader>
                <div className="text-muted text-center mt-2">
                  <img src={deskcoachlogo} alt="desk.coach logo" style={{maxHeight:"60px"}} />
                  <p>Welcome</p>
                </div>
              </CardHeader>
              <CardBody className="text-center mx-6">
                <Button
                  color="primary"
                  onClick={() => handleLogin(instance)}
                  block
                >
                  Sign in
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <a href="https://www.deskcoach.com.au">
        <img
          src={deskcoachlogo}
          alt="desk.coach"
          style={{ bottom: "16px", right: "16px", position: "fixed" , maxHeight: "50px" }}
        />
      </a>
    </>
  );
};

export default LoginPage;

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Table, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import { useState , useEffect, useRef } from 'react';
import { Form, Formik, FormikProps,Field } from "formik";
import EditRingValidator from "../../validators/EditRingValidator"
import TextFormInput from "../../components/Shared/Forms/TextFormInput";
import FormError from "../../components/Shared/Forms/FormError";
import { useUserProgressContents } from "../../hooks/useUserProgressContents"
import { UserProgressContent } from "../../interfaces/models/UserProgressContent"
import { editProgressProgressContent } from "../../graphql/content-management/addUserProgresssContents"
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import Loading from '../../components/Loading';

interface UserRing {
    id : string;
    name : string;
    color : string;
}

const UserProgressEditorPage = () => {

    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Edit User Progress', path: `${DASHBOARD_ROOT}/content-management/user-progress` },
    ];

    const formikRef = useRef<FormikProps<UserRing>>(null);
    const [initialValue, setInitialValue] = useState<UserRing>(null); 
    const [userRings, setUserRings] = useState<UserProgressContent[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); 
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const { data, loading, error } = useUserProgressContents();

    useEffect(() => {
        if(data) {
            setUserRings(data.user_progress_contents)
        }
    },[data])

    const [editProgressProgressHook] = useMutation(editProgressProgressContent,{
        onCompleted : () =>{
            toast.success("Ring details was successfully updated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst updating Ring details. Please try again or if issue persists, please contact us");
        }
    });

    const HandleEdit = (ring : UserProgressContent) => {
        setInitialValue({
            id : ring._id,
            name : ring.name,
            color : ring.color
        })
        toggleModal();
    }

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const triggerSubmit = ()=> {
        formikRef.current.submitForm();
    }

    const HandleSubmit = async (ring : UserRing) => {
        if(isSubmit) {
            return;
        }

        setIsSubmit(true);

        try {
            editProgressProgressHook({
                variables : {
                    _id : initialValue.id,
                    record : {
                        name : ring.name,
                        color : ring.color
                    }
                }
            })
            .then((res) => {
                if(res.errors) {
                    setIsSubmit(false);
                }
                else {
                    setTimeout(function (){
                        window.location.reload();
                    },1500);
                }
            })
        }
        catch {
            setIsSubmit(false);
        }

    }

    

    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the Ring List. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }
            <div className="table-responsive">
                <Table striped>
                    <thead>
                        <tr>
                            <th>Ring Name</th>
                            <th>Ring Color</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading && 
                            <tr>
                                <td colSpan={3}>
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {
                            (userRings.length ===0 && !loading) && 
                            <tr>
                                <td colSpan={3} className="text-center">No Data Found</td>
                            </tr>
                        }
                        {
                            userRings.map( (ring : UserProgressContent , key : number) => {
                                return (
                                    <tr key={key}>
                                        <td>{ring.name}</td>
                                        <td>
                                            <div
                                            style={{
                                                backgroundColor : ring.color,
                                                padding : "20px"
                                            }}>

                                            </div>
                                        </td>
                                        <td>
                                            <Button color="primary" size='sm'onClick={()=>{ HandleEdit(ring) }} >Edit</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            <Modal
                id="userprogress-edit-modal"
                className="modal-md"
                backdrop={true}
                isOpen={isModalOpen}>
                <ModalHeader toggle={toggleModal} className="pb-0">
                    <p className="text-lg">Edit Ring</p>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValue}
                        validationSchema={EditRingValidator}
                        onSubmit={async (value : UserRing) =>{
                            await HandleSubmit(value);
                        }}
                    >
                        {({errors}) => (
                            <Form>
                                <TextFormInput
                                    name={"name"}
                                    placeholder="Enter Name"
                                    label="Name"
                                    required={true}
                                />
                                <div className="form-group">
                                    <label>Color *</label>
                                    <Field
                                        className="form-control"
                                        name="color"
                                        placeholder="Select Color"
                                        type="color"
                                    />
                                    <FormError>{errors.color}</FormError>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={isSubmit} onClick={toggleModal} >Close</Button>
                    <Button color="primary" disabled={isSubmit} onClick={triggerSubmit} >Edit</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export { UserProgressEditorPage as UserProgressEditor };
import { Line } from "react-chartjs-2"
import { useState, useEffect } from "react";
import { getDaysInMonth } from "../../helper/weekOfTheMonthHelper"
import { Row, Col, Input, Label } from "reactstrap"
import Loading from "../Loading";
import { Months, UserActivityType, FontFamily, ChartJSBaseFontColor } from "../../helper/constants";
import { useGetUsersNudgeData } from "../../hooks/AdvanceReporting/useGetUserActivities";
import { Alert } from "reactstrap";

//#region Interfaces
interface Props {
    organisation_id? : string;
    user_id? : string;
    is_print? :boolean
}

interface NudgeData {
    type : number;
    count : number;
    day : number
}
//#endregion

export default function UserNudgeLineChartDailyBased({organisation_id, user_id, is_print = false} : Props) {

//#region Initialization
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    const yearOptions : number[] = [];
    for(let x = 2020; x <= year; x++) {
        yearOptions.push(x);
    }

    const [chartData, setChartData] = useState({});
    const [nudgeMonth, setNudgeMonth] = useState<number>(month);
    const [nudgeYear, setNudgeYear] = useState<number>(year);

    const [isRefetching, setIsRefetching] = useState<boolean>(false);
    const { data, loading, error, refetch } = useGetUsersNudgeData(month + 1, year, organisation_id, user_id);


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true, // Enable the title
            text: "Daily Nudge Report", // Title text
            fontSize: 22, // Font size for the title
            padding: 20, // Padding around the title
            fontColor : ChartJSBaseFontColor,
            fontFamily : FontFamily,
        },
        legend :{
            labels: {
                fontColor : ChartJSBaseFontColor,
                fontFamily : FontFamily,
                fontSize : 20,
            }
        },
        scales: {
            xAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Days of the Month",
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                        fontSize : 20
                    },
                    ticks: {
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                    },
                },
            ],
            yAxes: 
            [
                {
                    display: true,
                    scaleLabel: 
                    {
                        display: true,
                        labelString: "Total Count",
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                    },
                    ticks: 
                    {
                        beginAtZero: true,
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                    },
                },
            ],
        },
    };

    useEffect(() => {
        if(data) {
            // This dataset is already prepared in the backend
            // but its not seggregated yet by type
            const dataset : NudgeData[] = data.user_activity_daily_nudge_data;
            const days = getDaysInMonth(nudgeMonth, nudgeYear);

            const acknowledgements : number[] = [];
            const snoozes : number[] = [];

            // Seggregate the data by type
            for(let day = 1; day <= days.length; day++) {
                
                const getDayAcknowledge = dataset.find((u) => u.day === day && u.type === UserActivityType.NUDGE_ACKNOWLEDGE);
                acknowledgements.push(getDayAcknowledge ? getDayAcknowledge.count : 0);

                const getDaySnooze = dataset.find((u) => u.day === day && u.type === UserActivityType.NUDGE_SNOOZE);
                snoozes.push(getDaySnooze ? getDaySnooze.count : 0);

            }

            const grahpData = {
                labels: days,
                datasets: 
                [
                    {
                        label: "Acknowledges",
                        data: acknowledgements,
                        borderColor: "rgba(75, 192, 192, 1)",
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        fill: true,
                        tension: 0.1,
                    },
                    {
                        label: "Snoozes",
                        data: snoozes,
                        borderColor: "rgba(255, 99, 132, 1)",
                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                        fill: true,
                        tension: 0.1,
                    },
                ],
            };

            setChartData(grahpData);

        }
        
    }, [data, nudgeMonth, nudgeYear]);
//#endregion Initialization

//#region Actions
    
    const HandleNudgeChangeYear = async (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setNudgeYear(value);
        setIsRefetching(true);
        await refetch({
            month : nudgeMonth + 1, year : value, organisation_id, user_id
        });
        setIsRefetching(false);
    }

    const HandleNudgeChangeMonth = async (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setNudgeMonth(value);
        setIsRefetching(true);
        await refetch({
            month : value + 1, year : nudgeYear, organisation_id, user_id
        });
        setIsRefetching(false);
    }
//#endregion Actions
  return (
    <>
        {
            !is_print ?
                <Row>
                    <Col className="d-flex">
                        <Label className="mr-3 mt-2">Year:</Label>
                        <Input
                            type="select"
                            value={nudgeYear}
                            onChange={HandleNudgeChangeYear}
                        >
                            {
                                yearOptions.map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))
                            }
                        </Input>
                    </Col>
                    <Col className="d-flex">
                        <Label className="mr-3 mt-2">Month:</Label>
                        <Input
                            type="select"
                            value={nudgeMonth}
                            onChange={HandleNudgeChangeMonth}
                        >
                            {
                                Months.map((item, index) => (
                                    <option value={index} key={index}>{item}</option>
                                ))
                            }
                        </Input>
                    </Col>
                </Row>
            :
                <div className="text-center">
                    <h2> Graph as of {Months[nudgeMonth]} {nudgeYear} </h2>
                </div>

        }
        
        {
            error &&
            <Alert color="danger">
                Oops, we’re having trouble getting the graph. Please give it a minute, and if it doesn’t resolve, let us know.
            </Alert>
        }
        {
            (loading || isRefetching)  ? 
            <div className="pt-5" style={{height: "400px" }}>
                <Loading />
            </div>
            : 
            <div style={{height: "400px" }}>
                <Line data={chartData} options={options} />
            </div>
        }
    </>
  );
}
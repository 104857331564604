import { Route, Redirect, Switch, useParams } from "react-router-dom";
import Layout from "../../layouts/Layout";
import { DASHBOARD_ROOT, MOVEMENT_COACH, DESK_COACH } from "./routingConstants";
import MovementCoachPage from "../../pages/MovementCoach/MovementCoachPage";
import { useGetUsersRoutes } from "../../hooks/useGetUsersRoutes";
import { useEffect, useState } from "react";
import Loading from "../Loading";
import { useGetUsersTaskSequenceId } from "../../hooks/useGetUsersTaskSequenceId";

interface RouteParams {
  taskId: string;
}

export default function DashboardRoutes() {
  const userRoutes = useGetUsersRoutes();
  const [taskSequenceId, setTaskSequenceId] = useState<string>("");

  const RedirectDCTaskToNewUrl = () => {
    const { taskId } = useParams<RouteParams>();
    const { data, loading, error } = useGetUsersTaskSequenceId();

    useEffect(() => {
      if (data != null) {
        setTaskSequenceId(data.me?.task_sequence_id);
      }
    }, [data]);

    // Return loading spinner while taskSequenceId is being retrieved.
    if (loading || taskSequenceId === "") {
      return <Loading />;
    }
    if (error) {
      return <Redirect to={"/dash/user"} />;
    }

    return (
      <Redirect
        to={`${DASHBOARD_ROOT}/${DESK_COACH}/${taskSequenceId}?taskId=${taskId}`}
      />
    );
  };
  if (userRoutes.length === 0) {
    return <></>;
  }

  return (
    <Switch>
      <Route
        path={"/"}
        render={(props) => (
          <Layout {...props}>
            <Switch>
              <Route
                path={`${DASHBOARD_ROOT}/${MOVEMENT_COACH}`}
                component={MovementCoachPage}
                key={Math.random()} //Required for correct behavior with recursive routing
              />
              {/* Redirect to dashboard home if error occurs while fetching user's task sequence id. */}
              <Route
                path={[
                  "/dash/notifications/:taskId",
                  "/dash/notification/:taskId",
                ]}
              >
                <RedirectDCTaskToNewUrl />
              </Route>

              {userRoutes.map((route, idx: number) => {
                return (
                  <Route
                    key={idx}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}
                  />
                );
              })}
              <Redirect to="/dash/user" />
            </Switch>
          </Layout>
        )}
      />
    </Switch>
  );
}

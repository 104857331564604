import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { DASHBOARD_ROOT } from "../../components/Routes/routingConstants";
import { useParams, useHistory } from 'react-router-dom';
import { Table, Row, Col, Input, FormGroup, Label, Button, Card, CardBody, CardHeader, Alert } from 'reactstrap';
import React, { useState, useEffect, Suspense } from 'react';
import { DateFormat, LineGraphType,  UserStatusTypesArray, UserActivityGroup, UserActivity, ActivityHistoryTableDataTypeValue  } from "../../helper/constants"
import moment from 'moment';
import UserMetricsPieChart from "../../components/AdvanceReporting/UserMetricsPieChart"
import { useGetUserDetailedInfo, useGetUserActivityLogs } from "../../hooks/AdvanceReporting/useGetUserActivities";
import Loading from '../../components/Loading';
import { UserActivityLogs } from "../../interfaces/models/UserActivityLogs"
import UserNudgeLineChartDailyBased from "../../components/AdvanceReporting/UserNudgeLineChartDailyBased"
import UserUsageLineChartDailyBased from "../../components/AdvanceReporting/UserUsageLineChartDailyBased"
import UserDataLogs from "../../interfaces/advanceReporting/UserDataLogs"
import { ExportUserActivity } from "../../helper/ReportExports/ExportUserActivityLogs"
const UserNudgeLineChartMonthlyBased = React.lazy( () => import("../../components/AdvanceReporting/UserNudgeLineChartMonthlyBased"));
const UserUsageLineChartMonthlyBased = React.lazy( () => import("../../components/AdvanceReporting/UserUsageLineChartMonthlyBased"));

//#region Interfaces
interface RouteParams {
    id: string;
};
//#endregion

const UserActivityHistoryPage = () => {

//#region Initialization
    const { id } = useParams<RouteParams>();
    const searchParams = new URLSearchParams(window.location.search);
    const userName = searchParams.get("name");
    const organisation = searchParams.get("organisation");
    const userStatus = Number(searchParams.get("status")); 
    const history = useHistory();

    const breadcrumbsData = [
        { name: 'Reports', path: `${DASHBOARD_ROOT}/reporting/dashboard?status=${userStatus}&organisation=${organisation}` },
        { name: 'Activity History', path: `${DASHBOARD_ROOT}/reporting/dashboard/user-activity/${id}?name=${userName}&status=${userStatus}` },
    ];

    const [userActivity, setUserActivity] = useState<UserActivityLogs[]>([]);
    const [userActivityFiltered, setUserActivityFiltered] = useState<UserActivityLogs[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<number>(0);
    const [selectedType, setSelectedType] = useState<number>(0);
    const [userDetails, setUserDetails] = useState<UserDataLogs>(null);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    
    const { data : userData, loading : userLoading, error : userError } = useGetUserDetailedInfo(id);
    const { data : logsData, loading : logsLoading, error : logsError } = useGetUserActivityLogs(id);

    const [selectedLineGraph, setSelectedLineGraph] = useState<number>(LineGraphType.DAILY);
    const [selectedLineUsageGraph, setSelectedLineUsageGraph] = useState<number>(LineGraphType.DAILY);

    useEffect(() => {
        if(!userData) return;
        setUserDetails(userData.user_detailed);

    },[userData])

    useEffect(() => {
        if(!logsData) return;
        const logList : UserActivityLogs[] = logsData.user_activity_logs;
        setUserActivity(logList);
        setUserActivityFiltered(logList);
        
    },[logsData])
//#endregion Initialization

//#region Actions
    const HandleGroupChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value)
        setSelectedGroup(value);
        setSelectedType(0);
        setUserActivityFiltered(userActivity.filter( u => 
            value !== 0 ?
                UserActivity.findIndex( z => z.id === u.type && z.group === value) > -1
            :   
                true
        ));
    }

    const HandleTypeChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value)
        setSelectedType(value);
        const filters = userActivity.filter( u => 
            value !== 0 ?
                u.type === value
            :   
                UserActivity.findIndex( z => z.id === u.type && z.group === selectedGroup) > -1
        );
        setUserActivityFiltered(filters);
    }
    
    const HandleChangeNudgeGraphType = (type : number) => {
        setSelectedLineGraph(type);
    }

    const HandleChangeUsageGraphType = (type : number) => {
        setSelectedLineUsageGraph(type);
    }

    const HandleExport = () => {
        history.push(`${DASHBOARD_ROOT}/reporting/dashboard/user-activity/${id}/print?name=${userName}&status=${userStatus}&organisation=${organisation || ""}`);
    }

    const HandleExportLogs = async () => {
        setIsExporting(true);
        await ExportUserActivity(userName, userActivityFiltered);
        setIsExporting(false);
    }

    
    const GraphSelectorElement = (stateValue : number, changeHandler : (value : number) => void) => {
        return (
            <Row className="pt-2 pb-2">
                <Col>
                    <Card
                        className={`cursor-pointer text-center shadow` + (stateValue === LineGraphType.DAILY ? " border-primary text-primary" :"")}
                        onClick={()=>{changeHandler(LineGraphType.DAILY)}}
                    >
                        <CardBody className="pt-2 pb-2" >
                            <i className="fas fa-calendar-day"></i> <b>Daily</b>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card
                        className={`cursor-pointer text-center shadow` + (stateValue === LineGraphType.MONTHLY ? " border-primary text-primary" :"")}
                        onClick={()=>{changeHandler(LineGraphType.MONTHLY)}}
                    >
                        <CardBody className="pt-2 pb-2" >
                            <i className="fas fa-calendar-alt"></i> <b>Monthly</b>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
//#endregion Actions

    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            <Row className="pb-2">
                <Col md={6}>
                    <h4 className="text-capitalize font-weight-bold">{userName}' Activity History</h4>
                </Col>
                <Col className="d-flex justify-content-end" md={6}>
                    <Button color="primary" onClick={HandleExport}>Export</Button>
                </Col>
                
                <Col md={6}  className="mt-2">
                    <Card className="">
                        <CardHeader>
                            <h4 className="mb-0">User Profile</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                (userLoading) ?
                                <Loading />
                                : !userError && userDetails? 
                                <>
                                    <div>
                                        <Label className="font-weight-bold">Name :</Label> <span>{userDetails.name}</span>
                                    </div>
                                    <div>
                                        <Label className="font-weight-bold">Email :</Label> <span>{userDetails.email}</span>
                                    </div>
                                    <div>
                                        <Label className="font-weight-bold">Ogranisation :</Label> <span >{userDetails.organisation}</span>
                                    </div>
                                    <div>
                                        <Label className="font-weight-bold">License(s) :</Label> <span >{userDetails.licenses}</span>
                                    </div>
                                    <div>
                                        <Label className="font-weight-bold">License Allocated :</Label> <span >{moment(userDetails.license_allocated).format(DateFormat)}</span>
                                    </div>
                                    <div>
                                        <Label className="font-weight-bold">License Expiry :</Label> <span >{moment(userDetails.license_expiry).format(DateFormat)}</span>
                                    </div>
                                    <div>
                                        <Label className="font-weight-bold">Blocked :</Label> <span >{userDetails.is_blocked ? "YES" : "NO"}</span>
                                    </div>
                                    <div>
                                        <Label className="font-weight-bold mr-2">Activity Status :</Label> 
                                        <span className={`badge badge-${UserStatusTypesArray[userDetails.status - 1].color}`} >
                                            <i className={`${UserStatusTypesArray[userDetails.status - 1].icon}  mr-2`}></i>
                                            {UserStatusTypesArray[userDetails.status - 1].name}
                                        </span>
                                    </div>
                                </>
                                :
                                <Alert color="danger">
                                    Oops, we’re having trouble getting the User info. Please give it a minute, and if it doesn’t resolve, let us know.
                                </Alert>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}  className="mt-2">
                    <Card >
                        <CardHeader>
                            <h4 className="mb-0">User Activity Graph</h4>
                        </CardHeader>
                        <CardBody>
                            <UserMetricsPieChart
                                user_id={id}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Card className="mt-2">
                <CardHeader className="pb-0">
                    <Row className="mb-3">
                        <Col className="pt-2">
                            <h4 className="mb-0">User Nudge Report</h4>
                        </Col>
                        <Col className="d-flex justify-content-end">
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                {
                    GraphSelectorElement(selectedLineGraph,HandleChangeNudgeGraphType)
                }
                {
                    selectedLineGraph === LineGraphType.DAILY ?
                    <UserNudgeLineChartDailyBased 
                        user_id={id}
                    />
                    : 
                    <Suspense fallback={<div>Loading...</div>}>
                        <UserNudgeLineChartMonthlyBased 
                        user_id={id}
                        /> 
                    </Suspense>
                }
                </CardBody>
            </Card>

            <Card className="mt-2">
                <CardHeader className="pb-0">
                    <Row className="mb-3">
                        <Col className="pt-2">
                            <h4 className="mb-0">User Usage Report</h4>
                        </Col>
                        <Col className="d-flex justify-content-end">
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                {
                    GraphSelectorElement(selectedLineUsageGraph,HandleChangeUsageGraphType)
                }
                {
                    selectedLineUsageGraph === LineGraphType.DAILY ?
                    <UserUsageLineChartDailyBased 
                        user_id={id}
                    />
                    : 
                    <Suspense fallback={<div>Loading...</div>}>
                        <UserUsageLineChartMonthlyBased
                            user_id={id}
                        /> 
                    </Suspense>
                }
                </CardBody>
            </Card>
            <Card className="mt-2"> 
                <CardHeader className="d-flex justify-content-between">
                    <h4 className="mt-2">Activity Log</h4>
                    <Button color="primary" disabled={isExporting || logsLoading} onClick={HandleExportLogs}>Export</Button>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col  md={6}>
                            <FormGroup>
                                <Label>Group : </Label>
                                <Input
                                    type="select"
                                    value={selectedGroup}
                                    onChange={(e)=>HandleGroupChange(e)}
                                >
                                    <option value="0">All</option>
                                    {
                                        UserActivityGroup.map((item, index ) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label> Type : </Label>
                                <Input
                                    type="select"
                                    value={selectedType}
                                    onChange={(e)=>HandleTypeChange(e)}
                                    disabled={ selectedGroup === 0 }
                                >
                                    <option value="0">All</option>
                                    {
                                        UserActivity.filter(u=>u.group === selectedGroup).map((item, index ) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="table-responsive bg-white" style={{maxHeight : 450}}>
                        {
                            logsError && 
                            <Alert color="danger">
                                Oops, we’re having trouble getting the User Logs. Please give it a minute, and if it doesn’t resolve, let us know.
                            </Alert>
                        }
                        <Table bordered striped>
                            <thead className="sticky-top bg-white">
                                <tr>
                                    <th>Activity</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    logsLoading &&
                                    <tr>
                                        <td colSpan={2} className="text-center">
                                            <Loading />
                                        </td>
                                    </tr>
                                }
                                {
                                    (userActivityFiltered.length ===0 && !logsLoading) &&
                                    <tr>
                                        <td colSpan={2} className="text-center">No Data Found</td>
                                    </tr>
                                }
                                {
                                    userActivityFiltered.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{ActivityHistoryTableDataTypeValue[item.type - 1]}</td>
                                                <td>{moment(item.created_at_utc).format(DateFormat)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            <tfoot className="sticky-bottom font-weight-bold bg-secondary">
                                <tr>
                                    <td className="text-right"> Total Count</td>
                                    <td> {userActivityFiltered.length.toLocaleString()}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}


export { UserActivityHistoryPage as UserActivityHistory };
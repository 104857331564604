//#region Date Related
export const  DateFormat : string = "DD-MMM-YYYY"
export const Months : string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]; 
//#endregion Date Related

//#region Chart JS Related
export const FontFamily = "Poppins";
export const ChartJSBaseFontColor = "#32325d";

export const LineGraphType = {
  DAILY : 1,
  MONTHLY : 2
}
//#endregion Chart JS Related

//#region User Activity Logs Related
export const UserActivityGroupType = {
  OPEN : 1,
  NUDGE : 2,
  WC : 3,
  MIC : 4,
  MC : 5,
  EM : 6,
  CA : 7,
}

export const UserActivityType = {
  OPEN : 1,
  NUDGE_ACKNOWLEDGE : 2,
  NUDGE_SNOOZE : 3,
  NUDGE_DEEPWORK : 4,
  WC : 5,
  MIC : 6,
  MC : 7,
  EM_CREATE : 8,
  EM_DELETE : 9,
  EM_ACKNOWLEDGE : 10,
  EM_DEEPWORK : 11,
  CA : 12
}

export const UserActivityTypeWithGroup = {
  OPEN : {
    id : 1,
    group : UserActivityGroupType.OPEN
  },
  NUDGE_ACKNOWLEDGE : {
    id : 2,
    group : UserActivityGroupType.NUDGE
  },
  NUDGE_SNOOZE : {
    id : 3,
    group : UserActivityGroupType.NUDGE
  },
  NUDGE_DEEPWORK : {
    id : 4,
    group : UserActivityGroupType.NUDGE
  },
  WC : {
    id : 5,
    group : UserActivityGroupType.WC
  },
  MIC : {
    id : 6,
    group : UserActivityGroupType.MIC
  },
  MC : {
    id : 7,
    group : UserActivityGroupType.MC
  },
  EM_CREATE : {
    id : 8,
    group : UserActivityGroupType.EM
  },
  EM_DELETE : {
    id : 9,
    group : UserActivityGroupType.EM
  },
  EM_ACKNOWLEDGE : {
    id : 10,
    group : UserActivityGroupType.EM
  },
  EM_DEEPWORK : {
    id : 11,
    group : UserActivityGroupType.EM
  },
  CA : {
    id : 11,
    group : UserActivityGroupType.EM
  },
}


interface UserActivityGroupTypeInterface {
  id : number;
  name : string;
}

export const UserActivityGroup : UserActivityGroupTypeInterface[] = [
  {
    id : UserActivityGroupType.OPEN,
    name : `Log In`,
  },
  {
    id : UserActivityGroupType.NUDGE,
    name : `Nudge`
  },
  // {
  //   id : UserActivityGroupType.WC,
  //   name : `Workhealth.Coach`
  // },
  {
    id : UserActivityGroupType.MIC,
    name : `MyIntention.Coach`
  },
  {
    id : UserActivityGroupType.MC,
    name : `Movement.Coach`
  },
  {
    id : UserActivityGroupType.EM,
    name : `Empower ME`
  },
  {
    id : UserActivityGroupType.CA,
    name : `Challenge Acknowledgement`
  },
  
]

interface UserActivityTypeInterface {
  id : number;
  name : string;
  group : number
}

export const UserActivity : UserActivityTypeInterface[] = [
  {
    id : UserActivityType.OPEN,
    name : `Log In`,
    group : UserActivityGroupType.OPEN
  },
  {
    id : UserActivityType.NUDGE_ACKNOWLEDGE,
    name : `Nudge Acknowledge`,
    group : UserActivityGroupType.NUDGE
  },
  {
    id : UserActivityType.NUDGE_SNOOZE,
    name : `Nudge Snooze`,
    group : UserActivityGroupType.NUDGE
  },
  {
    id : UserActivityType.NUDGE_DEEPWORK,
    name : `Nudge Deep Work`,
    group : UserActivityGroupType.NUDGE
  },
  {
    id : UserActivityType.WC,
    name : `Workhealth Coach`,
    group : UserActivityGroupType.WC
  },
  {
    id : UserActivityType.MIC,
    name : `MyIntention Coach`,
    group : UserActivityGroupType.MIC
  },
  {
    id : UserActivityType.MC,
    name : `Movement Coach`,
    group : UserActivityGroupType.MC
  },
  {
    id : UserActivityType.EM_CREATE,
    name : `Empower ME Create`,
    group : UserActivityGroupType.EM
  },
  {
    id : UserActivityType.EM_DELETE,
    name : `Empower ME Delete`,
    group : UserActivityGroupType.EM
  },
  {
    id : UserActivityType.EM_ACKNOWLEDGE,
    name : `Empower ME Acknowledge`,
    group : UserActivityGroupType.EM
  },
  {
    id : UserActivityType.EM_DEEPWORK,
    name : `Empower ME Deep Work`,
    group : UserActivityGroupType.EM
  },
  {
    id : UserActivityType.CA,
    name : `Challenge Acknowledgement`,
    group : UserActivityGroupType.CA
  }
];

export const ActivityHistoryTableDataTypeValue : string[] = [
  `User opened the app.`,
  `User clicked "Acknowledge" for a nudge.`,
  `User clicked "Snooze" for a nudge.`,
  `User clicked "Deep Work" for a nudge.`,
  `User watched a video in Workhealth Coach.`,
  `User watched a video in MyIntention Coach.`,
  `User watched a video in Movement Coach.`,
  `User created a task in Empower ME.`,
  `User deleted a task in Empower ME.`,
  `User clicked "Acknowledge" for an Empower ME nudge.`,
  `User clicked "Deep Work" for an Empower ME nudge.`,
  `User clicked "Acknowledge" for an Challenge.`
];

//#endregion User Activity Logs Related

//#region User Activity Monitor Related

export const UserStatusTypes = {
  ACTIVE : 1,
  LOW_ACTIVITY : 2,
  INACTIVE : 3
}

interface Status {
  id : number,
  name : string;
  icon : string;
  color : string;
}

export const UserStatusTypesArray : Status[] = [
  {
    id : UserStatusTypes.ACTIVE,
    name : "Active",
    icon : "far fa-smile",
    color : "success"
  },
  {
    id : UserStatusTypes.LOW_ACTIVITY,
    name : "Low Activity",
    icon : "far fa-meh",
    color : "warning"
  },
  {
    id : UserStatusTypes.INACTIVE,
    name : "Inactive",
    icon : "far fa-frown",
    color : "danger",
  }
]

//#endregion User Activity Monitor Related

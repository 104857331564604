import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { useEffect, useState } from 'react';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import UserMetricsPieChart from "../../components/AdvanceReporting/UserMetricsPieChart"
import UserStatusMetricsPieChart from "../../components/AdvanceReporting/UserStatusMetricsPieChart"
import UserNudgeLineChartDailyBased from "../../components/AdvanceReporting/UserNudgeLineChartDailyBased"
import UserNudgeLineChartMonthlyBased from "../../components/AdvanceReporting/UserNudgeLineChartMonthlyBased"
import UserUsageLineChartDailyBased from "../../components/AdvanceReporting/UserUsageLineChartDailyBased"
import UserUsageLineChartMonthlyBased from "../../components/AdvanceReporting/UserUsageLineChartMonthlyBased"
import { Col, Row, Button, Table, Alert, Badge } from "reactstrap"
import { useOrganisation } from "../../hooks/useOrganisation";
import { Organisation } from "../../interfaces/models/Organisation";
import { useLocation } from 'react-router-dom';
import { usePDF, Resolution, Margin, Options} from 'react-to-pdf';
import moment from 'moment';
import { DateFormat } from '../../helper/constants';
import PrintPageContainer from "../../components/AdvanceReporting/PrintPageContainer";
import { useGetUserLatestActivitiesMini } from "../../hooks/AdvanceReporting/useGetUserActivities";
import { UserStatusTypesArray} from "../../helper/constants"
import Loading from '../../components/Loading';

//#region Interfaces
interface UserData {
    name : string;
    status : number;
}
//#endregion Interfaces
const ReportingDashboardPrintPage = () => {

//#region Initialization
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const organisation = searchParams.get("organisation");

    const breadcrumbsData = [
        { name: 'Reports', path: `${DASHBOARD_ROOT}/reporting/dashboard` },
        { name: 'Dashboard - Print', path: `${DASHBOARD_ROOT}/reporting/dashboard/print?organisation=${organisation}` },
    ];

    const { data : dataUser, loading, error } = useGetUserLatestActivitiesMini(organisation);
    const { data : organisationData, loading : organisationLoading } = useOrganisation();

    const [queryOrganisation] = useState<string>(organisation || "");
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [isPrintMode, setIsPrintMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tablePages, setTablePages] = useState<number>(1);

    const [user ,setUsers] = useState<UserData[]>([]);

    const options : Options = {
        // default is `save`
        filename : `Reports as of ${moment().format(DateFormat)}.pdf`,
        method: 'save',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.MEDIUM,
        page: {
           margin: Margin.MEDIUM,
           format:"letter",
           orientation: 'portrait',
        },
        canvas: {
           // default is 'image/jpeg' for better size performance
           mimeType: 'image/png',
           qualityRatio: 1,
        },
    };
    const { toPDF, targetRef } = usePDF(options);

    let numberOfItems = 20;

    useEffect(() => {
        if(dataUser) {
            setIsLoading(true);
            const trackingData : UserData[] = dataUser.user_activity_logs_tracking;
            setUsers(trackingData);
            setTablePages(Math.ceil(trackingData.length / numberOfItems));

            setTimeout(() => {
                setIsLoading(false);
            },1500)
        }
        
    },[dataUser, numberOfItems]);
    
    useEffect(() => {
        if(organisationData) { 
            setOrganisations(organisationData.organisations);
        }
    },[organisationData]);
//#endregion Initialization
//#region Actions
    const HandlePrint = () => {
        setIsPrintMode(true);
        setIsLoading(true);
        setTimeout(() => {
            toPDF();
        },1000);
        setTimeout(() => {
            setIsLoading(false);
            setIsPrintMode(false);
        },2500)
    }

    const HandleGenerateTable = (data : UserData[], isFirstPage : boolean, key : number, isPreview : boolean) => {
        
        if(isFirstPage) 
        {
            return (
                <Table key={key} >
                    <thead>
                        <tr className="bg-primary text-white">
                            <th>Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length === 0 && 
                            <tr >
                                <td colSpan={2} className="text-center ">No Data Found</td>
                            </tr>
                        }
                        {
                            (isPreview && data.length !== 0) ? 
                                <>
                                    {
                                        data.slice(0,5).map((item , index) => {


                                            return (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <Badge color={UserStatusTypesArray[item.status - 1].color}>{UserStatusTypesArray[item.status - 1].name}</Badge>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr >
                                        <td colSpan={2} className="text-center font-weight-bold">This is just a preview more data should be shown when you click "Print"</td>
                                    </tr>
                                </>
                            :
                                data.map((item , index) => {


                                    return (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>
                                                <Badge color={UserStatusTypesArray[item.status - 1].color}>{UserStatusTypesArray[item.status - 1].name}</Badge>
                                            </td>
                                        </tr>
                                    )
                                })
                        }
                    </tbody>
                </Table>
            );
        }
        else  
        {
            return(
                <PrintPageContainer key={key}>
                    <Table className="">
                        <thead>
                            <tr className="bg-primary text-white">
                                <th>Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((item , index) => {


                                    return (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </PrintPageContainer>
            );
        }
        
    }
//#endregion Actions
    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            <Row className="pb-2">
                <Col md={9}>
                </Col>
                <Col className="d-flex justify-content-end" md={3}>
                    <Button  color="primary" className="mr-1" disabled={isLoading || loading} onClick={HandlePrint}>Print</Button>
                </Col>
            </Row>
            <div ref={targetRef} className=" bg-white" style={{width:"1000px"}}>
                <PrintPageContainer>
                    <Row className="mb-2">
                        <Col md={6}>
                            <h1>Reports as of {moment().format(DateFormat)}</h1>
                        </Col>
                        <Col className="d-flex justify-content-end" md={6}>
                            <h1 >Organisation : "{ !organisationLoading ? ( organisations.find(u => u._id === queryOrganisation)?.name || "All") :"-"}"</h1>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <h3 className="mb-0">User Activities</h3>
                    </div>
                    <div>
                        {
                            error  && 
                            <Alert color="danger">
                                Oops, we’re having trouble getting the users. Please give it a minute, and if it doesn’t resolve, let us know.
                            </Alert>   
                        }
                    </div>
                    <div>
                        {
                            loading  && 
                            <Loading />   
                        }
                    </div>
                    <div>
                        {
                            HandleGenerateTable(user.slice(0,20),true, 1, !isPrintMode)
                        }
                    </div>
                </PrintPageContainer>
                {
                    isPrintMode && tablePages > 1 && 
                    Array.from({length : tablePages - 1}).map((_, i) => {
                        const sliced = user.slice(numberOfItems, numberOfItems + 20);
                        numberOfItems += 20;
                        return HandleGenerateTable(sliced, false, i, false);
                    })
                }
                <PrintPageContainer>
                    <UserStatusMetricsPieChart
                        organisation_id={queryOrganisation}
                        is_print={true}
                    />
                    <div className="mt-5"></div>
                    <UserMetricsPieChart
                        organisation_id={queryOrganisation}
                        is_print={true}
                    />
                </PrintPageContainer>
                <div className="mt-2"></div>
                <PrintPageContainer>
                    <UserNudgeLineChartDailyBased 
                        organisation_id={queryOrganisation}
                        is_print={isPrintMode}
                    />
                    <div className="mt-5"></div>
                    <UserNudgeLineChartMonthlyBased
                        organisation_id={queryOrganisation}
                        is_print={isPrintMode}
                    />
                </PrintPageContainer>
                <div className="mt-2"></div>
                <PrintPageContainer
                    isLast={true}    
                >
                    <UserUsageLineChartDailyBased 
                        organisation_id={queryOrganisation}
                        is_print={isPrintMode}
                    /> 
                    <div className="mt-5"></div>
                    <UserUsageLineChartMonthlyBased 
                        organisation_id={queryOrganisation}
                        is_print={isPrintMode}
                    />
                </PrintPageContainer>
                
            </div>
        </>
    )
}


export { ReportingDashboardPrintPage as ReportingDashboardPrint };
import { gql } from "@apollo/client";

export const addTaskSequenceBucket = gql`
  mutation addTaskSequenceBucket($record: CreateOneTaskSequenceBucketsInput!) {
    TaskSequenceBucket_CreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const updateTaskSequenceBucket = gql`
  mutation updateTaskSequenceBucket($_id : MongoID!, $record: UpdateByIdTaskSequenceBucketsInput!) {
    TaskSequenceBucket_UpdateById(_id : $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;

export const activateTaskSequenceBucket = gql`
  mutation activateTaskSequenceBucket($bucketId : MongoID!) {
    TaskSequenceBucket_UpdateActiveBucket(bucketId : $bucketId) {
        _id
    }
  }
`;

export const removeTaskSequenceBucket = gql`
  mutation removeTaskSequenceBucket($_id: MongoID!) {
    TaskSequenceBucket_RemoveById(_id : $_id) {
      record {
        _id
      }
    }
  }
`;


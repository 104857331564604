import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { ContentOfTheWeek } from "../../interfaces/models/ContentOfTheWeek"
import { useContentOfTheWeekByDate, ContentOfTheWeekType} from '../../hooks/useContentOfTheWeek'
import { useGetUserChallengeAcknowledgement} from '../../hooks/useChallengeAcknowledgements'
import Loading from "../Loading";
import {useState, useEffect} from "react"
import { getImageUrl } from "../../helper/getImageUrl";
import {useMemo} from "react"
import ChallengeAcknowledgement from "../../interfaces/models/ChallengeAcknowledgement";
import { addChallengeAcknowledgement } from "../../graphql/challenge-acknowledgement/addChallengeAcknowledgement";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";

interface ButtonDesign {
  color : string;
  buttonName : string;
  icon : string;
  disabled : boolean;
}

export default function WeeklyChallengeCard() {

  const dateNow = useMemo(() => new Date(), []);
  const { data, loading, error } = useContentOfTheWeekByDate(ContentOfTheWeekType.CHALLENGE, dateNow);

  const [currentChallenge, setCurrentChallenge] = useState<ContentOfTheWeek>(null);
  const [acknowledgement, setAcknowledgement] = useState<ChallengeAcknowledgement>(null);
  const [acknowledgementLoading, setAcknowledgementLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { data : dataChall, error : errorChall, refetch } = useGetUserChallengeAcknowledgement(currentChallenge?._id  || "");

  const [buttonDesign, setButtonDesign] = useState<ButtonDesign>({
    color : "primary",
    buttonName : "Accept",
    icon : "fas fa-thumbs-up",
    disabled : false
  });

  const [addChallengeAcknowledgementHook] = useMutation(addChallengeAcknowledgement,{
    onCompleted : () =>{
        toast.success("Acknowledged was successfully added");
    },
    onError : () =>{
        toast.error("Something went wrong whilst Acknowledging the Challenge. Please try again or if issue persists, please contact us");
    }
  });

  useEffect(() => {
    if(data) {
      const getChallenge = data.content_of_the_week_by_date_and_type;
      if(getChallenge) {
        setCurrentChallenge(getChallenge);
        refetch({
          content_id : getChallenge._id
        });
      }
     
    }
  },[data, refetch]);

  useEffect(() => {

    if(!dataChall) return;
    const getAcknowledgement = dataChall.me_challenge_acknowledgement;
    setAcknowledgementLoading(false);
    if(!getAcknowledgement) return;
    setAcknowledgement(dataChall.me_challenge_acknowledgement);
    setButtonDesign({
      color : "success",
      buttonName : "Accepted!",
      icon : "fas fa-check-circle",
      disabled : true
    });

  },[dataChall])

  const HandleAcknowledge = async () => {
    if(errorChall || !currentChallenge || acknowledgement) return;

    setIsProcessing(true);
    const isAdded = await addChallengeAcknowledgementHook({
      variables : {
        content_id : currentChallenge._id
      }
    }).then((res) => res.errors ? false : true).catch(()=>false);
    setIsProcessing(false);

    if(!isAdded) return;

    setButtonDesign({
      color : "success",
      buttonName : "Accepted!",
      icon : "fas fa-check-circle",
      disabled : true
    });
    
  }

  return (
    <Card className="h-100">
      <CardBody>
        {
          loading &&
          <Loading />
        }
        {
          ((error || currentChallenge == null) && !loading) && 
          <Row>
            <Col md={
              {
                offset : 3,
                size : 6
              }
            }>
              <div>
                <h4 style={{ marginBottom: "1rem" }}>You’re all caught up for the week!</h4>
                <p className="text-xs">Challenges are the stepping stones to growth; they’re not barriers but invitations to discover your resilience and potential.</p>
              </div>
            </Col>
          </Row>
        }
        {
          currentChallenge && 
          <Row>
            <Col  md={3}>
              <img
                style={{
                  height : "200px",
                  width : "100%",
                  objectFit :"cover",
                }}
                src={getImageUrl(currentChallenge.image_id)}
                alt={currentChallenge.title}
              />
            </Col>
            <Col md={9}>
                <Row className="p-0 m-0">
                  <Col md={9} className="d-flex align-items-center">
                    <div>
                      <div><h4 style={{ marginBottom: "1rem" }}>{currentChallenge.title}</h4></div>
                      <div>
                        <p className="text-xs" style={{ wordWrap :"break-word",whiteSpace: "pre-wrap"}}>{currentChallenge.description}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="d-flex align-items-center">
                    {
                      (acknowledgementLoading || isProcessing) ? 
                      <Button color="secondary"><i className="fas fa-circle-notch fa-spin"></i> Loading</Button>
                      :
                      <Button disabled={buttonDesign.disabled || isProcessing} onClick={HandleAcknowledge} color={buttonDesign.color}> 
                        <i className={buttonDesign.icon}></i> {buttonDesign.buttonName}
                      </Button>
                    }
                    
                    
                  </Col>
                </Row>
            </Col>
          </Row>
        }
      </CardBody>
    </Card>
  );
}

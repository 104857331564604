import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import { useState , useEffect, useRef } from 'react';
import { Table, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Label, ButtonGroup, Input } from 'reactstrap';
import { useParams, useLocation   } from "react-router-dom";
import { Form, Formik, FormikProps, Field } from "formik";
import {useTaskSequence} from "../../hooks/useTaskSequence"
import Loading from '../../components/Loading';
import { Sequence, TaskSequenceWithCategory } from "../../interfaces/models/TaskSequence";
import {Task} from "../../interfaces/models/Task";
import { getImageSmallUrl } from "../../helper/getImageUrl";
import TextFormInput from '../../components/Shared/Forms/TextFormInput';
import FormError from "../../components/Shared/Forms/FormError";
import ImageUploadInput from '../../components/ContentManagement/ImageUploadInput';
import { NudgeClickUrlType, getNudgeClickUrlType } from "../../interfaces/models/NudgeClickUrlType"
import { getVideoUploadURLQuery } from "../../graphql/queries/getVideoUploadURL";
import { useLazyQuery, useMutation } from "@apollo/client"
import AddTaskValidator from '../../validators/AddTaskValidator';
import { getVideoUrlFromCloudflareId } from "../../helper/getVideoUrlFromCloudflareId";
import {addTask, updateTaskById} from "../../graphql/updateTaskById"
import toast from 'react-hot-toast';
import useImageUpload from "../../hooks/useImageUpload";
import { executeDeleteImage ,executeDeleteVideo} from "../../graphql/queries/getImageUploadURL";
import useVideoUpload from "../../hooks/useVideoUpload";
import { getImageUploadURL } from "../../graphql/queries/getImageUploadURL";
import {addTaskToSequence, removeTaskToSequence} from "../../graphql/content-management/addTaskSequences"
import PromptDialogs from "../../components/PromptDialog";

//#region Interfaces
interface RouteParams {
    id: string;
};

interface DataFormik {
    _id : string;
    name : string;
    description : string;
    image1 : string;
    image_id1 : string;
    instruction1 : string;
    image2 : string;
    image_id2 : string;
    instruction2 : string;
    video_name : string;
    video_url : string;
    video_link : string;
}

interface ModalLabels {
    name : string;
    buttonName : string;
    action : string;
}
//#endregion Interfaces

const SequenceDayEditorPage = () => {

//#region Initializations
    const { id } = useParams<RouteParams>();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Edit Day Sequences', path: `${DASHBOARD_ROOT}/content-management/day-sequences` },
        { name: `Edit ${searchParams.get("name")}`, path: `${DASHBOARD_ROOT}/content-management/day-sequences/${id}` },
    ];

    const [sequence, setSequence] = useState<TaskSequenceWithCategory>(null);
    const [tasks, setTasks] = useState<Sequence[]>([]);
    const [selectedTask, SetSelectedTask] = useState<DataFormik>(null);
    const [nudgeType , setNudgeType] = useState<number>(NudgeClickUrlType.Link);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isOnProcess, setIsOnProcess] = useState<boolean>(false);

    const formikRef = useRef<FormikProps<DataFormik>>(null);
    const [modalLabels, setModalLabels] = useState<ModalLabels>({
        name : "",
        buttonName : "",
        action : ""
    });

    //instructions 1 
    const [fileId, setFileId] = useState<string>("");
    const [blobFile, setBlobFile] = useState<File>(null); 
    const imageInputRef = useRef<{ clearImageField: () => void }>(null);

    //instructions 2
    const [fileId2, setFileId2] = useState<string>("");
    const [blobFile2, setBlobFile2] = useState<File>(null); 
    const imageInputRef2 = useRef<{ clearImageField: () => void }>(null);

    //video
    const [videoUrl, setVideoUrl] = useState<string>("");
    const [videoFileId, setVideoFileId] = useState<string>("");
    const [videoPreview, setVideoPreview] = useState<string>("");
    const [videoBlob, setVideoBlob] = useState<File>(null); 

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [toBeDeletedData, setToBeDeletedData] = useState<Task>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const { data, loading, error } = useTaskSequence(id);

    const [
        getSignedVideoUrl,
        { loading: gettingSignedVideoUrl, error: signedVideoUrlError },
      ] = useLazyQuery(getVideoUploadURLQuery, {
        fetchPolicy: "network-only",
        onCompleted(data) {
          if (data.getVideoUploadURL) {
            setVideoUrl(data.getVideoUploadURL.uploadURL);
            setVideoFileId(data.getVideoUploadURL.cloudflareId);
          }
        },
        onError() {
          console.log('error on getting signed url');
        },
      });

    const signedUrl = useRef<string>("");
    const signedId = useRef<string>("");
    const signedError = useRef<boolean>(false);
    const [
        getSignedUrl,
    ] = useLazyQuery(getImageUploadURL, {
        fetchPolicy: "network-only",
        onCompleted({getImageUploadURL}){
            signedUrl.current = getImageUploadURL.uploadURL;
            signedId.current = getImageUploadURL.id;
        },
        onError(){
            console.log('error')
            signedError.current = true;
        }
    });


    const { uploadImage } = useImageUpload();
    const { uploadVideo } = useVideoUpload();
    const [deleteFile] = useMutation(executeDeleteImage);
    const [deleteVideo] = useMutation(executeDeleteVideo);

    
    const GetFileUrl = async () => {
        return new Promise<void>((resolve, reject) => {
            getSignedUrl();
            signedId.current = ""
            signedUrl.current = ""
            signedError.current = false;
            const interval = setInterval(()=>{
                if(signedError.current) {
                    reject()
                }
                if(signedUrl.current){
                    clearInterval(interval);
                    resolve();
                }
            },100)
        })
    }

    const Reloader = () =>{
        setTimeout(function() {
            window.location.reload();
        },1500)
    }

    const UploadFileExecute = async (url :string, blob : File) =>{
        if(blob){
            const isUploaded = await uploadImage(url, blob);
            if(!isUploaded){
                toast.error("Something went wrong whilst uploading your image. Please try again or if issue persists, please contact us");
                
                return false;
            }
            return true;
        }
        return true;
    }

    const UploadVideoExecute = async (url :string, blob : File) =>{
        if(blob){
            return new Promise(async (resolve, reject) => {
                await uploadVideo(url, blob, {
                    onError: () => {
                      toast.error("Something went wrong whilst uploading your Video. Please try again or if issue persists, please contact us");
                      reject(false);

                    },
                    onSuccess: () => {
                      resolve(true);
                    },
                  });
            })
        }
        return true;
    }

    const DeleteFileExecute = async (fileIdCloud : string) => {
        if(!fileIdCloud){
            return; 
        }
        try{
            await deleteFile({
                variables : {
                    fileId : fileIdCloud
                },
            })
        }
        catch(err){
            console.log(err);
        }
    }

    const DeleteVideoExecute = async (fileIdCloud : string) => {
        if(!fileIdCloud){
            return; 
        }
        try{
            await deleteVideo({
                variables : {
                    fileId : fileIdCloud
                },
            })
        }
        catch(err){
            console.log(err);
        }
    }
    
    const [addTaskHook] = useMutation(addTask,{
        onCompleted : () =>{
        },
        onError : () =>{
            toast.error("Something went wrong whilst adding Nudge. Please try again or if issue persists, please contact us");
        }
    });

    const [updateTaskByIdHook] = useMutation(updateTaskById,{
        onCompleted : () =>{
            toast.success("Nudge Task was successfully Updated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst updating Nudge. Please try again or if issue persists, please contact us");
        }
    });

    const [addTaskToSequenceHook] = useMutation(addTaskToSequence,{
        onCompleted : () =>{
            toast.success("Nudge Task was successfully Added");
        },
        onError : () =>{
            toast.error("Something went wrong whilst adding Nudge. Please try again or if issue persists, please contact us");
        }
    });

    const [removeTaskToSequenceHook] = useMutation(removeTaskToSequence,{
        onCompleted : () =>{
            toast.success("Nudge Task was successfully Deleted");
        },
        onError : () =>{
            toast.error("Something went wrong whilst deleting Nudge. Please try again or if issue persists, please contact us");
        }
    });

    useEffect(() => {
        if(data) {
            setSequence(data.task_sequence);
            setTasks(data.task_sequence.sequence);
        }
    },[data])

    //instructions 1
    const HandleImageChange = (url : string, fileId : string, blob : File) => {
        setFileId(fileId);
        setBlobFile(blob);
    }
    const IsFetchingToggle = (onProcess : boolean) =>{
        setIsOnProcess(onProcess);
    }

    //instructions 2
    const HandleImageChange2 = (url : string, fileId : string, blob : File) => {
        setFileId2(fileId);
        setBlobFile2(blob);
    }
    const IsFetchingToggle2 = (onProcess : boolean) =>{
        setIsOnProcess(onProcess);
    }

    const TriggerSubmit = () => {
        formikRef.current?.submitForm();
    }
    const TriggerNudgeTypeChange = (type : number) => {
        setNudgeType(type);
    }
    const ToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const getLastPart = (url : string) => {
        const parts = url.split('/');
        return parts[parts.length - 1];
    };

//#endregion Initializations
//#region Add and Edit Functions
    const HandleOpenAdd = () =>{
        SetSelectedTask({
            _id : "",
            name :"",
            description :"",
            image1 : "",
            image_id1 : "",
            instruction1 : "",
            image2 :  "",
            image_id2 : "",
            instruction2 : "",
            video_name : "",
            video_url : "",
            video_link : "",
        });
        setModalLabels({
            name : "Add Nudge",
            buttonName : "Add",
            action : "add"
        })
        imageInputRef.current?.clearImageField();
        imageInputRef2.current?.clearImageField();
        setVideoPreview(null);
        setVideoBlob(null);
        setIsModalOpen(true);

    }

    const HandleOpenEdit = async (item : Task ) => {

        const type = getNudgeClickUrlType(item.action_url);
        let reformat : DataFormik = {
            _id : item._id,
            name : item.name,
            description :item.description,
            image1 : "image1.jpg",
            image_id1 : item.images[0],
            instruction1 : item.instructions[0]?.text,
            image2 :  "",
            image_id2 : "",
            instruction2 : "",
            video_name : "",
            video_url : "",
            video_link : "",
        }

        if(item.images.length > 1) {
            reformat.image2 = "image1.jpg";
            reformat.image_id2 = item.images[1];
            reformat.instruction2 = item.instructions[1].text;
        }

        if(type === NudgeClickUrlType.Link) {
            reformat.video_link = item.action_url || ""
        } else {
            reformat.video_name = "myVideo.mp4"
            reformat.video_url = item.action_url
        }
        setNudgeType(type);
 
        SetSelectedTask(reformat);
        setModalLabels({
            name : "Edit Nudge",
            buttonName : "Edit",
            action : "edit"
        })
        imageInputRef.current?.clearImageField();
        imageInputRef2.current?.clearImageField();
        setVideoPreview(null);
        setVideoBlob(null);
        setIsModalOpen(true);
    }

    const HandleOpenDelete = (item : Task) => {
        setToBeDeletedData(item);
        setIsDeleteModalOpen(true);
    }

    const HandleSubmit = async (formData : DataFormik) => {
        if(isOnProcess) return;
        setIsOnProcess(true);
        let imageList : string[] = []
        let instructionList : string [] = []
        let fileIdImage1 = ""
        let fileIdImage2 = ""
        
        if(blobFile){
            //manually get the url
            await GetFileUrl(); 
            //upload the files
            const isImage1Uploaded = await UploadFileExecute(signedUrl.current, blobFile)
            if(!isImage1Uploaded) {
                setIsOnProcess(false);
                return;   
            }
            fileIdImage1 = signedId.current;
            imageList.push(fileIdImage1)
            instructionList.push(formData.instruction1)
        } else if(formData.image_id1) {
            imageList.push(formData.image_id1)
            instructionList.push(formData.instruction1)
        }

        if(blobFile2) {
            
            await GetFileUrl(); 
            const isImage2Uploaded = await UploadFileExecute(signedUrl.current, blobFile2)
            if(!isImage2Uploaded) {
                //delete the 1st image as a clean up
                await DeleteFileExecute(fileIdImage1);
                setIsOnProcess(false);
                return;   
            }
            fileIdImage2 = signedId.current
            imageList.push(fileIdImage2)
            instructionList.push(formData.instruction2)
        } 
        else if (formData.image_id2) {
            // no changes in the instructions image
            imageList.push(formData.image_id2)
            instructionList.push(formData.instruction2)
        }


        if(nudgeType ===NudgeClickUrlType.Video && videoBlob) {
            const isVideoUploaded = await UploadVideoExecute(videoUrl,videoBlob);
            if(!isVideoUploaded){
                //do clean up
                let deletePromises : Promise<void>[] = [DeleteFileExecute(fileIdImage1), DeleteFileExecute(fileIdImage2)]
                await Promise.all(deletePromises);

                setIsOnProcess(false);
                return;
            }
        }
        let action_url = "";
        if (nudgeType === NudgeClickUrlType.Link) {
            action_url = formData.video_link === "" ? null : formData.video_link;
          } else {
            if (videoFileId) {
              //If a new video was uploaded, we should update the action url to use the presigned url id.
              const newVideoUrl = getVideoUrlFromCloudflareId(
                videoFileId
              );
              action_url = newVideoUrl;
            } 
            else if(formData.video_url) {
                action_url = formData.video_url;
            }
          }

        if(modalLabels.action === "add") {
            const isAdded = await addTaskHook({
                variables : {
                    record : {
                        name : formData.name,
                        description : formData.description,
                        images : imageList,
                        instructions : instructionList.map( u => {
                            return {
                                text : u
                            }
                        }),
                        action_url : action_url,
                        nudge : true,
                        video : nudgeType === NudgeClickUrlType.Video,
                        category : sequence.category
                    }
                }
            }).then((res)=> res.errors ? {ok : false, data :null} : {ok : true, data : res.data.task_CreateOne.record._id}).catch(()=>{ return {ok : false, data :null}; });

            if(!isAdded.ok) {
                //do clean up
                let deletePromises : Promise<void>[] = [DeleteFileExecute(fileIdImage1), DeleteFileExecute(fileIdImage2), DeleteVideoExecute(videoFileId)]
                await Promise.all(deletePromises);
                setIsOnProcess(false);
                return;
            }

            const isUpdatedToSequence = addTaskToSequenceHook({
                variables :{
                    sequenceId : sequence._id,
                    taskId : isAdded.data
                }

            }).then((res)=> res.errors ? false : true).catch(()=>false);

            if(!isUpdatedToSequence) {
                //do clean up
                let deletePromises : Promise<void>[] = [DeleteFileExecute(fileIdImage1), DeleteFileExecute(fileIdImage2), DeleteVideoExecute(videoFileId)]
                await Promise.all(deletePromises);
                setIsOnProcess(false);
                return;
            }

            Reloader();
        }
        else if(modalLabels.action === "edit") {
            const isUpdated = await updateTaskByIdHook({
                variables : {
                    _id : selectedTask._id,
                    record : {
                        name : formData.name,
                        description : formData.description,
                        images : imageList,
                        instructions : instructionList.map( u => {
                            return {
                                text : u
                            }
                        }),
                        action_url : action_url
                    }
                }
            }).then((res)=> res.errors ? false : true).catch(()=>false);
            if(!isUpdated) {
                //do clean up
                let deletePromises : Promise<void>[] = [DeleteFileExecute(fileId), DeleteFileExecute(fileId2), DeleteVideoExecute(videoFileId)];
                await Promise.all(deletePromises);
                setIsOnProcess(false);
                return;
            }
            else {
                let deletePromises : Promise<void>[] = [];
                // if there is new image for instructions 1 delete the previous image
                if(blobFile) {
                    deletePromises.push(DeleteFileExecute(selectedTask.image_id1));
                }

                // if there is new image for instructions 2 delete the previous image
                if(blobFile2) {
                    deletePromises.push(DeleteFileExecute(selectedTask.image_id2));
                }

                if((nudgeType === NudgeClickUrlType.Link && selectedTask.video_url) ||
                    (nudgeType === NudgeClickUrlType.Video && !formData.video_url)){
                    deletePromises.push(DeleteVideoExecute(getLastPart(selectedTask.video_url)));
                } 

                await Promise.all(deletePromises);
            }

            Reloader();
        }
    }

//#endregion Add and Edit Functions
//#region Delete Functions
    const HandleDeleteModalConfirm = async () => {
        if(isDeleting) {
            return;
        }

        setIsDeleting(true);

        const isDeleted = await removeTaskToSequenceHook({
            variables : {
                sequenceId : sequence._id,
                taskId : toBeDeletedData._id
            }
        }).then((res) => res.errors ? false : true).catch(()=>false);

        if(!isDeleted) {
            setIsDeleting(false);
            return;
        }
        let promises = []

        toBeDeletedData.images.map((u) => {
            promises.push(DeleteFileExecute(u))
            return u;
        });

        
        const type = getNudgeClickUrlType(toBeDeletedData.action_url);

        if(type === NudgeClickUrlType.Video) {
            promises.push(DeleteVideoExecute(getLastPart(toBeDeletedData.action_url)));
        }

        await Promise.all(promises);
        Reloader();
        
    }

//#endregion Delete Functions
    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            <div className="d-flex justify-content-end mb-3">
                <Button color="primary" onClick={HandleOpenAdd}>Add</Button>
            </div>
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the Nudges. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            } 
            <div className="table-responsive">
                <Table striped>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading && 
                            <tr>
                                <td colSpan={4}>
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {(tasks.length === 0 && ! loading) && 
                            <tr>
                                <td colSpan={4} className="text-center">No Data Found</td>
                            </tr>
                        }
                        {
                            tasks.map((item, index) => {
                                const task = item.task;
                                return (
                                    <tr key={index}>
                                        <td>
                                            <img
                                                style={{
                                                    borderRadius: "0.375rem",
                                                    marginBottom: "1rem",
                                                    height : "100px"
                                                }}
                                                src={getImageSmallUrl(task.images[0])}
                                                alt={task.name}
                                            />
                                        </td>
                                        <td>{task.name}</td>
                                        <td >
                                            <div style={{ width : 350, maxWidth : 350, 
                                                wordWrap :"break-word", overflowWrap :"break-word", 
                                                whiteSpace: "normal" }}>
                                                {task.description}
                                            </div>
                                        </td>
                                        <td>
                                            <Button color="primary" size="sm" onClick={()=> HandleOpenEdit(item.task)}>Edit</Button>
                                            <Button color="danger" size="sm" onClick={() => { HandleOpenDelete(item.task); }}>Delete</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
            <Modal
                id="sequence-edit-modal"
                className="modal-lg"
                backdrop={true}
                isOpen={isModalOpen}>
                <ModalHeader toggle={ToggleModal} className="pb-0">
                    <p className="text-lg">{modalLabels.name}</p>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        innerRef={formikRef}
                        initialValues={selectedTask}
                        validationSchema={AddTaskValidator}
                        onSubmit={async (value : DataFormik) =>{
                            await HandleSubmit(value);
                        }}
                    >
                        {({values, touched, errors, setFieldValue}) => (
                            <Form>
                                {errors._id}
                                <TextFormInput
                                    name={"name"}
                                    placeholder="Enter Name"
                                    label="Name"
                                    required={true}
                                    className="mb-3"
                                />
                                <FormGroup>
                                    <label>Description *</label>
                                    <Field
                                        className="form-control"
                                        name="description"
                                        placeholder="Enter description"
                                        as="textarea"
                                    />
                                    
                                    <FormError>{touched.description && (errors.description)}</FormError>
                                </FormGroup>
                            
                                <Label>Instruction 1</Label>
                                <ImageUploadInput
                                    ref={imageInputRef}
                                    formikRef={formikRef}
                                    transferFunction={HandleImageChange}
                                    isGettingUrl={IsFetchingToggle}
                                    imageName="image1"
                                    imageIdName="image_id1"
                                    formGroupClassName="mb-0"
                                    required={true}
                                    extensionAllowed="image/jpeg,image/jpg"
                                    dontFetchUrl={true}
                                />
                                <div className="form-group">
                                    <label>Instructions </label>
                                    <Field
                                        className="form-control"
                                        name="instruction1"
                                        placeholder="Enter instruction"
                                        as="textarea"
                                    />
                                    
                                    <FormError>{touched.instruction1 && (errors.instruction1)}</FormError>
                                </div>
                                <Label>Instruction 2 <span className="text-muted">(Optional)</span></Label>
                                <ImageUploadInput
                                    ref={imageInputRef2}
                                    formikRef={formikRef}
                                    transferFunction={HandleImageChange2}
                                    isGettingUrl={IsFetchingToggle2}
                                    imageName="image2"
                                    imageIdName="image_id2"
                                    formGroupClassName="mb-0"
                                    extensionAllowed="image/jpeg,image/jpg"
                                    dontFetchUrl={true}
                                />
                                <div className="form-group">
                                    <label>Instructions </label>
                                    <Field
                                        className="form-control"
                                        name="instruction2"
                                        placeholder="Enter instruction"
                                        as="textarea"
                                    />
                                    
                                    <FormError>{touched.instruction2 && (errors.instruction2)}</FormError>
                                </div>
                                <Label>Nudge Click URL</Label>
                                <ButtonGroup className="d-flex">
                                    <Button 
                                        color={nudgeType === NudgeClickUrlType.Link ? "primary" : "secondary"}
                                        onClick={()=>TriggerNudgeTypeChange(NudgeClickUrlType.Link)}
                                        className="w-100"
                                    >
                                        Link
                                    </Button>
                                    <Button
                                        color={nudgeType === NudgeClickUrlType.Video ? "primary" : "secondary"}
                                        onClick={()=>TriggerNudgeTypeChange(NudgeClickUrlType.Video)}
                                        className="w-100"
                                    >
                                        Video
                                    </Button>
                                </ButtonGroup>
                                {
                                    nudgeType === NudgeClickUrlType.Link ?
                                    <FormGroup className="pt-4">
                                        <label>Link </label>
                                        <Field 
                                            className="form-control"
                                            name="video_link"
                                            placeholder="Enter a link"
                                        />
                                        <FormError>{touched.video_link && (errors.video_link)}</FormError>
                                    </FormGroup>
                                    :
                                    <div>
                                        {
                                            !values.video_url && 
                                            <FormGroup className="mt-3 mb-3">
                                                <label>Video</label>
                                                <Input
                                                    name="video_name"
                                                    type="file"
                                                    accept="video/*"
                                                    className={`form-control`}
                                                    onChange={(event) => {
                                                        // Use Formik's setFieldValue to set the file value
                                                        const file = event.currentTarget.files[0]
                                                        if(file)
                                                        {
                                                            const videoUrl = URL.createObjectURL(file);
                                                            setFieldValue("video_name", file.name);
                                                            setVideoPreview(videoUrl);
                                                            setVideoBlob(file);
                                                            getSignedVideoUrl({
                                                                variables: {
                                                                    fileName: file.name,
                                                                    fileSize: file.size,
                                                                },
                                                            }); 
                                                        }
                                                        else
                                                        {
                                                            setVideoPreview(null);
                                                            setFieldValue("video_name", null);
                                                            setVideoBlob(null);
                                                        }
                                                        
                                                    }}
                                                />
                                                <FormError>{touched.video_name && (errors.video_name)}</FormError>
                                            </FormGroup>
                                        }
                                        {
                                            signedVideoUrlError && 
                                            <FormError>Error on setting up for video upload</FormError>
                                        }
                                        {
                                            gettingSignedVideoUrl &&
                                            <Loading />
                                        }
                                        {
                                            (videoPreview && !values.video_url && !gettingSignedVideoUrl && !signedVideoUrlError && !errors.video_name) && 
                                            <video 
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "0.375rem",
                                                    marginBottom: "1rem",
                                                }}
                                                controls 
                                                src={videoPreview} 
                                            />
                                        }
                                        {
                                            values.video_url && 
                                            <FormGroup className="pt-4">
                                                <label>Video </label>
                                                <div className="d-flex">
                                                    <a 
                                                        target="_blank"
                                                        href={values.video_url}
                                                        rel="noopener noreferrer"
                                                        className="w-100 pt-2"
                                                    >
                                                        {values.video_url}
                                                    </a>
                                                    <Button color="secondary" onClick={() => {
                                                        setFieldValue("video_url",null);
                                                    }}><i className="fas fa-trash-alt"></i></Button>
                                                </div>
                                            </FormGroup>
                                        }
                                    </div>
                                    
                                }
                                
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={isOnProcess} onClick={ToggleModal} >Close </Button>
                    <Button color="primary" disabled={isOnProcess} onClick={TriggerSubmit} >{modalLabels.buttonName}</Button>
                </ModalFooter>
            </Modal>

            <PromptDialogs
                isOpen={isDeleteModalOpen}
                onCancel={() => { setIsDeleteModalOpen(false); }}
                onConfirm={HandleDeleteModalConfirm}
                title="Delete Nudge"
                promptMessage="Are you sure you want to delete this Nudge?"
                confirmBtnTxt="Delete"
                isOnProcess={isDeleting}
            />  
        </>
    )
}

export { SequenceDayEditorPage as SequenceDayEditor };
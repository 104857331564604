import { Carousel, CarouselItem, Card, CardBody} from "reactstrap";
import { TaskAssignment } from "../../interfaces/models/TaskAssignment";
import { Sequence, TaskSequence } from "../../interfaces/models/TaskSequence";
import { getImageUrl } from "../../helper/getImageUrl";
import { useEffect, useState } from "react";
import { getMetaForTimelineStatus, TimelineItemStatus } from "../../components/Timeline/TimelineItem";
import { useHistory } from 'react-router-dom';
import {DASHBOARD_ROOT, DESK_COACH} from "../Routes/routingConstants";
import moment from "moment";
interface Props {
    task_sequence?: TaskSequence;
    task_assignments: TaskAssignment[];
}
  

export default function TaskSequenceCarousel({task_sequence, task_assignments} : Props) {

    
    const BURNOUT_PERCENTAGE = 0.1;
    const LONG_DAY_PERCENTAGE = 0.2;
    const taskSequenceSize = task_sequence.sequence.length;
    // Apply the Work Life status to the final [BURNOUT_PERCENTAGE]% of tasks in the sequence
    const worklifeThreshold = Math.ceil(taskSequenceSize * BURNOUT_PERCENTAGE);
    // Apply the Work Life Balance status to the final [LONG_DAY_PERCENTAGE]% of tasks in the sequence
    const worklifeBalanceThreshold = Math.ceil(
        taskSequenceSize * LONG_DAY_PERCENTAGE
    );

    const history = useHistory();
    
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [showButtons, setShowButtons] = useState(false);

    const onHover = () => {
        setShowButtons(true);
    }

    const onHoverOut = () => {
        setShowButtons(false);
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === task_sequence.sequence.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? task_sequence.sequence.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const HandleGoToTask = (sequence : Sequence) => {
        history.push(`${DASHBOARD_ROOT}/${DESK_COACH}/${task_sequence._id}?taskId=${sequence.task._id}`);
    }

    const getTimelineStatus = (sequence : Sequence) => {
        const assignment = task_assignments.find(u => u.task._id === sequence.task._id);

        if (assignment) {
          if (assignment.task_complete === true) {
            return TimelineItemStatus.Completed;
          } else if (assignment?.assigned_at) {
            return TimelineItemStatus.Scheduled;
          }
        }
    
        if (sequence.order >= taskSequenceSize - worklifeThreshold) {
          return TimelineItemStatus.WorkLifeBalanceEnd;
        } else if (
            sequence.order >=
          taskSequenceSize - worklifeThreshold - worklifeBalanceThreshold
        ) {
          return TimelineItemStatus.WorkLifeBalance;
        }
        return TimelineItemStatus.Upcoming;

    }

    let currentTaskIndex = 0;

    const slides = task_sequence.sequence.map((item, index) => {
        const getStatus = getTimelineStatus(item);
        const iconAndBadge = getMetaForTimelineStatus(getStatus);
        const assignment = task_assignments.find(u => u.task._id === item.task._id);

        let time = "";
        if(assignment) {
            if(assignment.assigned_at) {
                time = moment(assignment.assigned_at).format("HH:mm");
            }
            else if(assignment.user_acknowledged_at) {
                time = moment(assignment.user_acknowledged_at).format("HH:mm");
            }
            currentTaskIndex = index;
        }

        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}
            >
                <Card
                    key={index}                
                >
                    <div >
                        <CardBody 
                    onMouseEnter={onHover}
                    onMouseLeave={onHoverOut} >
                            <div className="text-center">
                                <img
                    
                                    onClick={()=>{HandleGoToTask(item)}} className="cursor-pointer"
                                    src={getImageUrl(item.task?.images[0])}
                                    alt={item.task?.name}
                                    style={{ 
                                        height: "300px",
                                        width :"100%",
                                        objectFit : "cover"
                                    }}
                                />
                            </div>
                            <div className="mt-2">
                                <h4 onClick={()=>{HandleGoToTask(item)}} className="cursor-pointer" >{time && time + " - "}{item.task?.name}</h4>
                            </div>
                            <div className="d-flex">
                                <div className="mt-1 mr-2">{iconAndBadge.iconNonStep}</div> 
                                <div>{iconAndBadge.badge}</div>
                            </div>
                            <div className="text-sm" >
                                {item.task?.description}
                            </div>
                        </CardBody>
                    </div>
                </Card>
            </CarouselItem>
        );
    });

    useEffect(() => {
        setActiveIndex(currentTaskIndex);
    },[currentTaskIndex])

    return (
    <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={null}
    >
        {slides}
        {
            (activeIndex !== 0 && showButtons) && 
            <div className="carousel-control-prev pr-4" onClick={previous} onMouseEnter={onHover} onMouseLeave={onHoverOut}>
                <i
                className="fas fa-chevron-circle-left"
                style={{ fontSize: "2rem", color: "black" }}
                ></i>
            </div>
        }
        {
            (activeIndex < task_sequence.sequence.length - 1 && showButtons) &&
            <div className="carousel-control-next pl-4" onClick={next}  onMouseEnter={onHover} onMouseLeave={onHoverOut}>
                <i
                className="fas fa-chevron-circle-right"
                style={{ fontSize: "2rem", color: "black" }}
                ></i>
            </div>
        }
        
    </Carousel>
    )
}
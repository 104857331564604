import { Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Field, Form, Formik, FormikProps } from "formik";
import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import TextFormInput from "../Shared/Forms/TextFormInput";
import EditTopicValidator from '../../validators/EditTopicValidator';
import FormError from "../Shared/Forms/FormError";
import { executeDeleteImage } from "../../graphql/queries/getImageUploadURL";
import useImageUpload from "../../hooks/useImageUpload";
import { useMutation} from "@apollo/client";
import toast from "react-hot-toast";
import DateTime from "react-datetime";
import {DateFormat} from "../../helper/constants"
import moment from 'moment';
import ImageUploadInput from '../../components/ContentManagement/ImageUploadInput';
 
export interface DataFormik {
    id : string;
    image : string;
    image_id : string;
    title : string;
    description : string;
    startDate : string;
    endDate : string;
}

interface Props {
    title : string;
    dataFormik : DataFormik;
    modalOpen : boolean;
    toggleModal : () => void; 
    onSubmit :  (data : DataFormik, fileId : string) => Promise<boolean>; 
    buttonName? : string;
    validationBeforeSubmit? :  (data : DataFormik) => Promise<boolean>; 
}


export const ContentWeekEditorModal = forwardRef(function ContentWeekEditorModal(
    { title, toggleModal, dataFormik, modalOpen, buttonName = "Save", onSubmit, validationBeforeSubmit }: Props,
    ref: React.Ref<{ clearImageField: () => void }>
  ) {

    const [url, setUrl] = useState<string>("");
    const [fileId, setFileId] = useState<string>("");
    const [blobFile, setBlobFile] = useState<File>(null); 
    const imageInputRef = useRef<{ clearImageField: () => void }>(null);

    const formikRef = useRef<FormikProps<DataFormik>>(null);
    const initialValues = dataFormik;
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const HandleReformatDate = (date: moment.Moment | string) => {
        if (typeof date !== "string" && date.isValid()) {
            return moment(date).format("yyyy-MM-DD");;
        }
    };


    // Function to expose to the parent
    const clearImageField = () => {
        imageInputRef.current?.clearImageField();
    };

    // Expose the child function to the parent via ref
    useImperativeHandle(ref, () => ({
        clearImageField: clearImageField,
    }));

    const triggerSubmit = () =>{
        formikRef.current.submitForm();
    }

    const { uploadImage } = useImageUpload();
    const [deleteFile] = useMutation(executeDeleteImage);

    const HandleSubmit  = async ( data : DataFormik) => {
        if(isSubmit) {
            return;
        }

        try {
            setIsSubmit(true);

            //validator before submit 
            const isAllowed = await validationBeforeSubmit(data);
            if(!isAllowed) {
                setIsSubmit(false);
                return;
            }

            //if there a new file
            if(blobFile){
                // proceed to upload
                const isUploaded : boolean = await uploadImage(url,blobFile);
                if(!isUploaded) {
                    toast.error("Something went wrong whilst Uploading image. Please try again or if issue persists, please contact us");
                    setIsSubmit(false);
                    return;
                }
            }
            const isSubmitted = await onSubmit(data, fileId).then((res)=>res).catch((err)=>err);
            if(isSubmitted) {
                // if the submission is successfull
                // delete the previous image if there is any
                if(initialValues.image_id && fileId){
                    // do house cleaning
                    await deleteFile({
                        variables : {
                            fileId : initialValues.image_id
                        }
                    })
                }
            } 
            else {
                //if not delete the recently uploaded image
                if(fileId){
                    // do house cleaning
                    await deleteFile({
                        variables : {
                            fileId : fileId
                        }
                    })
                }
            }
            setIsSubmit(false);
        }
        catch {
            setIsSubmit(false);
        }

    }
//#region Image sync 
    const HandleImageChange = (url : string, fileId : string, blob : File) => {
        setUrl(url);
        setFileId(fileId);
        setBlobFile(blob);
    }

    const IsFetchingToggle = (isGetting : boolean) =>{
        setIsSubmit(isGetting);
    }
//#endregion Image sync 

    return (
        <Modal
            id="weekly-edit-modal"
            className="modal-lg"
            backdrop={true}
            isOpen={modalOpen}>
                <ModalHeader toggle={toggleModal} className="pb-0">
                    <p className="text-lg">{title}</p>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        onSubmit={async (values : DataFormik) => {
                            
                            await HandleSubmit(values);
                        }}
                        validationSchema={EditTopicValidator}
                    >

                        {({ values, setFieldValue, errors, touched }) => (
                            <Form className="d-flex flex-column">
                                <TextFormInput
                                    name={"title"}
                                    placeholder="Enter title"
                                    label="Title"
                                    required={true}
                                />
                                <div className="form-group">
                                    <label>Description *</label>
                                    <Field
                                        className="form-control"
                                        name="description"
                                        placeholder="Enter description"
                                        rows={8}
                                        as="textarea"
                                    />
                                    
                                    <FormError>{touched.description && (errors.description)}</FormError>
                                </div>
                                    
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Date *</label>
                                            <DateTime 
                                                className="w-100" 
                                                onChange={(date) => {
                                                    const reformated = HandleReformatDate(date);
                                                    setFieldValue("startDate",reformated)   
                                                }} 
                                                dateFormat={DateFormat} 
                                                timeFormat={false}  
                                                inputProps={{readOnly : true ,className:"form-control bg-white"}}
                                                initialValue={new Date(values.startDate)}
                                            />
                                            <FormError>{errors.startDate}</FormError>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Date *</label>
                                            <DateTime 
                                                className="w-100" 
                                                onChange={(date) => {
                                                    const reformated = HandleReformatDate(date);
                                                    setFieldValue("endDate",reformated)   
                                                }} 
                                                dateFormat={DateFormat} 
                                                timeFormat={false} 
                                                inputProps={{readOnly : true ,className:"form-control bg-white"}} 
                                                initialValue={new Date(values.endDate)}
                                            />
                                            <FormError>{touched.endDate && (errors.endDate)}</FormError>
                                        </div>
                                    </div>
                                </div>
                                <ImageUploadInput
                                    ref={imageInputRef}
                                    formikRef={formikRef}
                                    transferFunction={HandleImageChange}
                                    isGettingUrl={IsFetchingToggle}
                                    imageName="image"
                                    imageIdName="image_id"
                                    extensionAllowed="image/jpeg,image/jpg"
                                    isDisabled={isSubmit}
                                />

                            </Form>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end">
                    <Button color="secondary" onClick={toggleModal} disabled={isSubmit} >Close</Button>
                    <Button color="primary" onClick={triggerSubmit} disabled={isSubmit} >{buttonName}</Button>
                </ModalFooter>
            </Modal>
    )

});
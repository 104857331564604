import { useFormikContext } from "formik";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { EmpowerScheduleForm } from "../EmpowerMeTaskModal";
import {
  RRuleEndAfterCountFieldName,
  RRuleEndConditionFieldName,
  RRuleEndNever,
  RRuleEndOnDateFieldName,
  RRuleIntervalFieldName,
  ScheduleDateFieldName,
  ScheduleTimeFieldName,
} from "./EmpowerMeFormFieldConstants";
import { RecurrenceSelection } from "./EmpowerMeRecurrenceSelection";
import styles from "./EmpowerMeScheduling.module.scss";
import DateTime from "react-datetime";
import {DateFormat} from "../../../helper/constants"
import moment from 'moment';

interface ScheduleSelectionProps {
  recurrenceEnabled: boolean;
  enableRecurrence: (enableRecurrence: boolean) => void;
}

export default function EmpowerScheduleSelection({
  recurrenceEnabled,
  enableRecurrence,
}: ScheduleSelectionProps) {
  const formikCtx = useFormikContext<EmpowerScheduleForm>();

  // function validateDate(dateValue: string) {
  //   if (!(dateValue !== "" && dateValue !== undefined && dateValue !== null)) {
  //     return "Please provide a valid date";
  //   }
  // }

  // function validateStartDate(dateValue: string) {
  //   const validationError = validateDate(dateValue);
  //   if (validationError) {
  //     return validationError;
  //   }

  //   // Ensure Date is not in the past
  //   var parsed = new Date(Date.parse(dateValue));
  //   const startDateReset = parsed.setHours(0, 0, 0, 0);
  //   const nowTimeReset = new Date().setHours(0, 0, 0, 0);

  //   if (startDateReset < nowTimeReset) {
  //     return "Please provide a date in the future.";
  //   }
  // }

  // async function validateTime(timeValue: string) {
  //   if (timeValue == null || timeValue.length <= 0) {
  //     formikCtx.setFieldError(
  //       ScheduleTimeFieldName,
  //       "Please provide a valid time"
  //     );
  //     return "Please provide a valid time";
  //   }

  //   // Cross-check when a start-date has been selected - that the specified time is in the future
  //   const startDateSelection = formikCtx.getFieldMeta(
  //     ScheduleDateFieldName
  //   ).value;

  //   if (startDateSelection !== null && startDateSelection !== undefined) {
  //     const parsedStartDate = new Date(
  //       Date.parse(startDateSelection as string)
  //     );
  //     const currentDate = new Date();

  //     if (parsedStartDate.toDateString() === currentDate.toDateString()) {
  //       const split = timeValue.split(":");
  //       const scheduledHour = parseInt(split[0]);
  //       const scheduledTime = parseInt(split[1]);

  //       if (
  //         scheduledHour < currentDate.getHours() ||
  //         (scheduledHour === currentDate.getHours() &&
  //           scheduledTime <= currentDate.getMinutes())
  //       ) {
  //         return "Please provide a time in the future";
  //       }
  //     }
  //   }

  // }

  return (
    <div className={styles.schedulingForm}>
      {/* Date and Time Selector */}
      <div className={`${styles.dateTimeFields} form-group`}>
        <div className={styles.recurrenceRepeatRule}>
          <label>Select Date:</label>
          <div className="w-100">
            {/* <Field
              className="form-control"
              name={ScheduleDateFieldName}
              type="hidden"
              validate={validateStartDate}
            /> */}
            <DateTime 
              className="w-100" 
              onChange={(date) => {
                  const reformated = moment(date).format("yyyy-MM-DD");
                  formikCtx.setFieldValue(ScheduleDateFieldName,reformated)   
              }} 
              dateFormat={DateFormat} 
              timeFormat={false}  
              initialValue={new Date()}
              inputProps={{readOnly : true ,className:"form-control bg-white"}}
            />
            <p className={styles.fieldError}>
              {formikCtx.getFieldMeta(ScheduleDateFieldName).touched &&
                formikCtx.getFieldMeta(ScheduleDateFieldName).error}
            </p>
          </div>
        </div>
        <div className={styles.recurrenceRepeatRule}>
          <label>Time:</label>
          <div className="w-100">
            {/* <Field
              className="form-control"
              name={ScheduleTimeFieldName}
              type="time"
              validate={validateTime}
            /> */}
            <DateTime 
              className="w-100" 
              onChange={(date) => {
                const reformated = moment(date).format("HH:mm");
                formikCtx.setFieldValue(ScheduleTimeFieldName,reformated)   
              }} 
              dateFormat={false} 
              timeFormat={true}  
              initialValue={new Date()}
              inputProps={{readOnly : true ,className:"form-control bg-white"}}
            />
            <p className={styles.fieldError}>
              {formikCtx.getFieldMeta(ScheduleTimeFieldName).touched &&
                formikCtx.getFieldMeta(ScheduleTimeFieldName).error}
            </p>
          </div>
        </div>
      </div>

      {/* Recurrence prompt */}
      <div className={`${styles.recurrencePrompt} mt-5 mb-5`}>
        <p>Would you like your task to repeat?</p>
        <ToggleButtonGroup
          type="radio"
          name="enableRecurrence"
          defaultValue={recurrenceEnabled ? 1 : 0}
          className={styles.recurrenceButtonsGroup}
        >
          <ToggleButton
            id="yes"
            type="radio"
            onClick={() => {
              enableRecurrence(true);
            }}
            value={0}
            checked={recurrenceEnabled}
            className={`${styles.recurrenceButton} ${
              recurrenceEnabled ? "btn-primary" : "btn-secondary"
            }`}
          >
            Yes
          </ToggleButton>
          <ToggleButton
            id="no"
            type="radio"
            onClick={() => {
              enableRecurrence(false);

              // Clear existing formik errors for disabled fields
              formikCtx.setFieldError(RRuleIntervalFieldName, undefined);
              formikCtx.setFieldError(RRuleEndOnDateFieldName, undefined);
              formikCtx.setFieldError(RRuleEndAfterCountFieldName, undefined);
            }}
            value={1}
            checked={!recurrenceEnabled}
            className={`${styles.recurrenceButton} ${
              !recurrenceEnabled ? "btn-primary" : "btn-secondary"
            }`}
          >
            No
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <RecurrenceSelection
        recurrenceEnabled={recurrenceEnabled}
        endConditionFieldName={RRuleEndConditionFieldName}
        endNever={RRuleEndNever}
        endOnDateFieldName={RRuleEndOnDateFieldName}
        endAfterCountFieldName={RRuleEndAfterCountFieldName}
      />
    </div>
  );
}

import { gql, useQuery } from '@apollo/client';

export function useGetUserLatestActivities(organisation_id : string = null) {
    return useQuery(
      gql`
        query useGetUserLatestActivities($organisation_id : MongoID) {
          user_activity_logs_tracking(organisation_id : $organisation_id) {
            _id
            name
            date
            email
            status
          }
        }
      `,
      {
        fetchPolicy : 'network-only',
        variables : {
            organisation_id : organisation_id
        }
      }
    );
}

export function useGetUserLatestActivitiesMini(organisation_id : string = null) {
  return useQuery(
    gql`
      query useGetUserLatestActivitiesMini($organisation_id : MongoID) {
        user_activity_logs_tracking(organisation_id : $organisation_id) {
          name
          status
        }
      }
    `,
    {
      fetchPolicy : 'network-only',
      variables : {
          organisation_id : organisation_id
      }
    }
  );
}

export function useGetUserStatusOverviewData(organisation_id : string = null) {
    return useQuery(
      gql`
        query useGetUserStatusOverviewData($organisation_id : MongoID) {
          user_status_overview_data(organisation_id : $organisation_id) {
            type
            count
          }
        }
      `,
      {
        fetchPolicy : 'network-only',
        variables : {
            organisation_id : organisation_id
        }
      }
    );
}

export function useGetUserActivityOverviewData(organisation_id : string = null, user_id : string = null) {
    return useQuery(
      gql`
        query useGetUserActivityOverviewData($organisation_id : MongoID, $user_id : String) {
          user_activity_overview_data(organisation_id : $organisation_id, user_id : $user_id) {
            type
            count
          }
        }
      `,
      {
        fetchPolicy : 'network-only',
        variables : {
            organisation_id : organisation_id,
            user_id : user_id
        }
      }
    );
}

export function useGetUsersNudgeData(month : Number, year : Number, organisation_id : string = null, user_id : string = null) {
    return useQuery(
      gql`
        query useGetUsersNudgeData($month : Int!, $year : Int!, $organisation_id : MongoID, $user_id : String) {
          user_activity_daily_nudge_data(month : $month, year : $year, organisation_id : $organisation_id, user_id : $user_id) {
            type
            count
            day
          }
        }
      `,
      {
        fetchPolicy : 'network-only',
        variables : {
            month : month,
            year : year,
            organisation_id : organisation_id,
            user_id : user_id,
        }
      }
    );
}

export function useGetUsersNudgeDataMonthly(year : Number, organisation_id : string = null, user_id : string = null) {
    return useQuery(
      gql`
        query useGetUsersNudgeDataMonthly($year : Int!, $organisation_id : MongoID, $user_id : String) {
          user_activity_monythly_nudge_data(year : $year, organisation_id : $organisation_id, user_id : $user_id) {
            type
            count
            month
          }
        }
      `,
      {
        fetchPolicy : 'network-only',
        variables : {
            year : year,
            organisation_id : organisation_id,
            user_id : user_id,
        }
      }
    );
}

export function useGetUsersUsageDataDaily(month : Number, year : Number, organisation_id : string = null, user_id : string = null) {
    return useQuery(
      gql`
        query useGetUsersUsageDataDaily($month : Int!, $year : Int!, $organisation_id : MongoID, $user_id : String) {
          user_activity_daily_usage_data(month : $month, year : $year, organisation_id : $organisation_id, user_id : $user_id) {
            count
            day
          }
        }
      `,
      {
        fetchPolicy : 'network-only',
        variables : {
            month : month,
            year : year,
            organisation_id : organisation_id,
            user_id : user_id,
        }
      }
    );
}

export function useGetUsersUsageDataMonthly(year : Number, organisation_id : string = null, user_id : string = null) {
    return useQuery(
      gql`
        query useGetUsersUsageDataMonthly($year : Int!, $organisation_id : MongoID, $user_id : String) {
          user_activity_monythly_usage_data(year : $year, organisation_id : $organisation_id, user_id : $user_id) {
            count
            month
          }
        }
      `,
      {
        fetchPolicy : 'network-only',
        variables : {
            year : year,
            organisation_id : organisation_id,
            user_id : user_id,
        }
      }
    );
}


export function useGetUserDetailedInfo(user_id : string) {
  return useQuery(
    gql`
      query useGetUserDetailedInfo($user_id : MongoID!) {
        user_detailed(user_id : $user_id) {
          _id
          name
          email
          organisation
          licenses
          license_expiry
          license_allocated
          is_blocked
          status
        }
      }
    `,
    {
      fetchPolicy : 'network-only',
      variables : {
          user_id : user_id,
      }
    }
  );
}

export function useGetUserActivityLogs(user_id : string) {
  return useQuery(
    gql`
      query useGetUserActivityLogs($filter: UserActivityLogsFilterInput) {
        user_activity_logs(filter: $filter) {
          _id
          type
          type_group
          created_at_utc
        }
      }
    `,
    {
      fetchPolicy : 'network-only',
      variables : {
        filter :{
          user_id : user_id,
        }
      }
    }
  );
}
import { gql, useQuery } from "@apollo/client";

export function useTaskSequence(taskSequenceId: string) {
  return useQuery(
    gql`
      query getTaskSequenceById($_id: MongoID!) {
        task_sequence(_id: $_id) {
          _id
          name
          include_in_rotation
          image
          category
          sequence {
            order
            task {
              _id
              action_url
              category
              description
              images
              movement_video
              name
              organisation_id
              video
              instructions {
                title
                text
              }
            }
          }
        }
      }
    `,
    {
      variables: { _id: taskSequenceId },
    }
  );
}
import { Table, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Alert, FormGroup, Label } from 'reactstrap';
import { Field, Form, Formik, FormikProps } from "formik";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
    DASHBOARD_ROOT,
} from "../../components/Routes/routingConstants";
import { useState , useEffect, useRef } from 'react';
import Loading from '../../components/Loading';
import TextFormInput from "../../components/Shared/Forms/TextFormInput";
import { useTaskSequencesNameIdOnly } from "../../hooks/useTaskSequencesForManagement"
import { useTaskSequenceBuckets } from "../../hooks/useTaskSequencesBuckets" 
import { addTaskSequenceBucket , updateTaskSequenceBucket , activateTaskSequenceBucket, removeTaskSequenceBucket } from "../../graphql/content-management/addTaskSequenceBuckets"
import toast from 'react-hot-toast';
import { useMutation } from "@apollo/client"
import PromptDialogs from "../../components/PromptDialog";
import AddTaskSequenceBucketValidator from "../../validators/AddTaskSequenceBucketValidator"
import FormError from '../../components/Shared/Forms/FormError';

//#region Interfaces 
interface TaskSequence { 
    name : string;
    _id: string;
}

interface DataFormik {
    _id : string;
    name : string;
    description : string;
    sequenceList : string[];
    type : number;
}

interface ModalLabels { 
    name : string;
    buttonName : string;
    action : string;
}

interface TaskSequenceBucket {
    _id : string;
    name : string;
    description : string;
    type : number;
    status : boolean;
    task_sequences_ids : string[];
}
//#endregion Interfaces

const SequenceBucketEditorPage = () => {
//#region Initializations
    const breadcrumbsData = [
        { name: 'Content List', path: `${DASHBOARD_ROOT}/content-management/` },
        { name: 'Edit Sequence Buckets', path: `${DASHBOARD_ROOT}/content-management/day-sequences/buckets` },
    ];

    const [taskSequences, setTaskSequences] = useState<TaskSequence[]>([]);
    const [bucket, setBucket] = useState<TaskSequenceBucket[]>([]);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); 
    const [modalLabels, setModalLabels] = useState<ModalLabels>({
        name : "",
        buttonName : "",
        action : ""
    });

    const formikRef = useRef<FormikProps<DataFormik>>(null);
    const [isOnProcess, setIsOnProcess] = useState<boolean>(false);
    const [initialValue, setInitialValue] = useState<DataFormik>(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [toBeDeletedData, setToBeDeletedData] = useState<TaskSequenceBucket>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const [isActivateModalOpen, setIsActivateModalOpen] = useState<boolean>(false);
    const [toBeActivatedData, setToBeActivatedData] = useState<TaskSequenceBucket>(null);
    const [isActivating, setIsActivating] = useState<boolean>(false);
    
    const { data : sequenceData, loading : sequenceLoading , error : sequenceError } = useTaskSequencesNameIdOnly();
    const { data, loading, error } = useTaskSequenceBuckets();

    const [addTaskSequenceBucketHook] = useMutation(addTaskSequenceBucket,{
        onCompleted : () =>{
            toast.success("Bucket was successfully Added");
        },
        onError : () =>{
            toast.error("Something went wrong whilst adding Bucket details  . Please try again or if issue persists, please contact us");
        }
    });

    const [updateTaskSequenceBucketHook] = useMutation(updateTaskSequenceBucket,{
        onCompleted : () =>{
            toast.success("Bucket was successfully Updated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst updating Bucket details  . Please try again or if issue persists, please contact us");
        }
    });

    const [removeTaskSequenceBucketHook] = useMutation(removeTaskSequenceBucket,{
        onCompleted : () =>{
            toast.success("Bucket was successfully Deleted");
        },
        onError : () =>{
            toast.error("Something went wrong whilst Deleting Bucket details  . Please try again or if issue persists, please contact us");
        }
    });

    const [activateTaskSequenceBucketHook] = useMutation(activateTaskSequenceBucket,{
        onCompleted : () =>{
            toast.success("Bucket was successfully Activated");
        },
        onError : () =>{
            toast.error("Something went wrong whilst Activating Bucket details  . Please try again or if issue persists, please contact us");
        }
    });
    
    useEffect(() =>{
        if(sequenceData){
            setTaskSequences(sequenceData.task_sequences_sort_by_name)
        }
    },[sequenceData])

    useEffect(() => {
        if(data) {
            setBucket(data.task_sequence_buckets)
        }
    },[data])

    const Reloader = () => {
        setTimeout(function (){
            window.location.reload();
        },1500);
    }

    const ToggleModal = () =>{
        setIsModalOpen(!isModalOpen);
    }

    const TriggerSubmit = () => {
        formikRef.current?.submitForm();
    }
//#endregion Initializations
//#region Add and Edit Functions
    const HandleOpenAdd = () => {
        setInitialValue({
            _id : "",
            name : "",
            description : "",
            sequenceList : [],
            type : 0,
        })
        setModalLabels({
            name : "Add Bucket",
            buttonName : "Add",
            action : "add"
        })

        setIsModalOpen(true);
        
    }

    const HandleOpenEdit = (item : TaskSequenceBucket) => {
        setInitialValue({
            _id : item._id,
            name : item.name,
            sequenceList : item.task_sequences_ids,
            description : item.description,
            type : item.type,
        })
        setModalLabels({
            name : "Edit Bucket",
            buttonName : "Edit",
            action : "edit"
        })

        setIsModalOpen(true);
    }

    const HandleSubmit = async  (formData : DataFormik) =>{
        if(isOnProcess) return;

        setIsOnProcess(true);
        
        if(modalLabels.action === "add") {
            const isSuccess = await addTaskSequenceBucketHook({
                variables : {
                    record : {
                        name : formData.name,
                        task_sequences_ids : formData.sequenceList,
                        description : formData.description,
                        type : Number(formData.type),
                        status : false,
                    }
                }
            }).then((res) => res.errors ? false : true).catch(()=>false);

            if(!isSuccess) {
                setIsOnProcess(false);
                return;
            }

            Reloader();
        }
        else if(modalLabels.action === "edit") {
            const isSuccess = await updateTaskSequenceBucketHook({
                variables : {
                    _id : initialValue._id,
                    record : {
                        name : formData.name,
                        task_sequences_ids : formData.sequenceList,
                        description : formData.description,
                        type : Number(formData.type)
                    }
                }
            }).then((res) => res.errors ? false : true).catch(()=>false);

            if(!isSuccess) {
                setIsOnProcess(false);
                return;
            }

            Reloader();
        }
    }
//#endregion Add and Edit Functions
//#region Delete Functions
    const HandleOpenDelete = (item : TaskSequenceBucket) => {
        setToBeDeletedData(item);
        setIsDeleteModalOpen(true);
    }

    const HandleDelete = async () =>{
        if(isDeleting) return;

        setIsDeleting(true);

        const isSuccess = await removeTaskSequenceBucketHook({
            variables : {
                _id : toBeDeletedData._id,
            }
        }).then((res) => res.errors ? false : true).catch(()=>false);

        if(!isSuccess) {
            setIsDeleting(false);
            return;
        }

        Reloader(); 
    }

//#endregion Delete Functions
//#region Activate Functions
    const HandleOpenActivate = (item : TaskSequenceBucket) => {
        setToBeActivatedData(item);
        setIsActivateModalOpen(true);
    }

    const HandleActivate = async () =>{
        if(isActivating) return;

        setIsActivating(true);

        const isSuccess = await activateTaskSequenceBucketHook({
            variables : {
                bucketId : toBeActivatedData._id,
            }
        }).then((res) => res.errors ? false : true).catch(()=>false);

        if(!isSuccess) {
            setIsActivating(false);
            return;
        }

        Reloader(); 
    }
 
//#endregion Activate Functions
    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            <div className="d-flex justify-content-end mb-3">
                <Button color="primary" onClick={HandleOpenAdd}>Add</Button>
            </div>
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the Bucket List. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }
            <div className="table-responsive">
                <Table striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (!loading &&  bucket.length === 0) &&
                            <tr>
                                <td colSpan={5} className="text-center">
                                    No Data Found
                                </td>
                            </tr>
                        }
                        {
                            loading && 
                            <tr>
                                <td colSpan={5}>
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {
                            bucket.map((item : TaskSequenceBucket, index ) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td >
                                            <div style={{ width : 350, maxWidth : 350, 
                                                wordWrap :"break-word", overflowWrap :"break-word", 
                                                whiteSpace: "normal" }}>
                                                {item.description}
                                            </div>
                                        </td>
                                        <td>{item.type}-Day Sequence</td>
                                        <td>
                                            {
                                                item.status ? 
                                                <span className="badge bg-success text-white">Active</span>
                                                :
                                                <span className="badge bg-secondary text-dark  border-dark">Inactive</span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                !item.status && 
                                                <>
                                                    <Button color="success" size='sm'onClick={()=>{ HandleOpenActivate(item) }} >Activate</Button>
                                                    <Button color="primary" size='sm'onClick={()=>{ HandleOpenEdit(item) }} >Edit</Button>
                                                    <Button color="danger" size='sm' onClick={()=>{ HandleOpenDelete(item)}} >Delete</Button>
                                                </>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </Table>
            </div>
            <Modal
                id="sequenceBucket-edit-modal"
                className="modal-lg"
                backdrop={true}
                isOpen={isModalOpen}>
                <ModalHeader toggle={ToggleModal} className="pb-0">
                    <p className="text-lg">{modalLabels.name}</p>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValue}
                        validationSchema={AddTaskSequenceBucketValidator}
                        onSubmit={async (value : DataFormik) =>{
                            await HandleSubmit(value);
                        }}
                    >
                        {
                            ({errors, touched, values}) => (
                                <Form>
                                    <TextFormInput
                                        name={"name"}
                                        placeholder="Enter Name"
                                        label="Name"
                                        required={true}
                                    />
                                    <div className="form-group mb-0">
                                        <label>Description *</label>
                                        <Field
                                            className="form-control"
                                            name="description"
                                            placeholder="Enter Description"
                                            as="textarea"
                                        />
                                        <FormError>{touched.description && (errors.description)}</FormError>
                                    </div>
                                    <FormGroup className="mb-0">
                                        <Label>Type</Label>
                                        <Field as="select" name="type" className="form-control">
                                            <option value="0" label="Select Type" />
                                            <option value="5" label="5-Day Sequence" />
                                            <option value="10" label="10-Day Sequence" />
                                            <option value="15" label="15-Day Sequence" />
                                            <option value="20" label="20-Day Sequence" />
                                        </Field>
                                    </FormGroup>
                                    <div className="table-responsive" style={{maxHeight:"400px"}}>
                                        
                                        {
                                            (!sequenceLoading && sequenceError) &&
                                            <Alert color="danger">
                                                Oops, we’re having trouble getting the Task Sequences. Please give it a minute, and if it doesn’t resolve, let us know.
                                            </Alert>
                                        }  
                                        {   
                                            
                                            (touched.sequenceList && errors.sequenceList) &&
                                            <Alert color="warning">
                                                { errors.sequenceList } - Select {values.type} Items
                                            </Alert>
                                        }
                                        <Table>
                                            <thead className="bg-white sticky-top">
                                                <tr>
                                                    <th></th>
                                                    <th>Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (!sequenceLoading && taskSequences.length === 0) &&
                                                    <tr>
                                                        <td colSpan={3} className="text-center">
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    sequenceLoading &&
                                                    <tr>
                                                        <td colSpan={3} className="text-center">
                                                            <Loading />
                                                        </td>
                                                    </tr> 
                                                }
                                                {
                                                    taskSequences.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <Field className="form-control" type="checkbox" name="sequenceList" value={item._id} />
                                                                </td>
                                                                <td valign="bottom">{item.name}</td>
                                                                <td>
                                                                    <a className="btn btn-secondary btn-sm" href={`${DASHBOARD_ROOT}/content-management/day-sequences/${item._id}?name=${item.name}`} target="_blank" rel="noreferrer">
                                                                        View Sequence
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={isOnProcess} onClick={ToggleModal} >Close </Button>
                    <Button color="primary" disabled={isOnProcess} onClick={TriggerSubmit} >{modalLabels.buttonName}</Button>
                </ModalFooter>
            </Modal>

            <PromptDialogs
                isOpen={isDeleteModalOpen}
                onCancel={() => { setIsDeleteModalOpen(false); }}
                onConfirm={HandleDelete}
                title="Delete Bucket"
                promptMessage="Are you sure you want to delete this Bucket?"
                confirmBtnTxt="Delete"
                isOnProcess={isDeleting}
            />
            <PromptDialogs
                isOpen={isActivateModalOpen}
                onCancel={() => { setIsActivateModalOpen(false); }}
                onConfirm={HandleActivate}
                title="Activate Bucket"
                promptMessage="Are you sure you want to Activate this Bucket? It will be applied instantly"
                confirmBtnTxt="Activate"
                isOnProcess={isActivating}
            />
        </>
    )

}

export { SequenceBucketEditorPage as SequenceBucketEditor };
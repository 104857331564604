import { gql, useQuery } from "@apollo/client";
import moment from "moment";
export const ContentOfTheWeekType = {
  TOPIC : 1,
  CHALLENGE : 2
}

export function useContentOfTheWeek(type: number) {
  return useQuery(
    gql`
      query getContentOfTheWeekByType($type: Int!) {
        content_of_the_weeks_by_type(type: $type) {
          _id
          title
          description
          image
          image_id
          start_date
          end_date
          type
        }
      }
    `,
    {
      variables: { type: type },
      fetchPolicy : 'network-only'
    }
  );
}

export function useContentOfTheWeekByDate(type: number, date : Date) {
  return useQuery(
    gql`
      query getContentOfTheWeekByTypeAndDate($type: Int!, $date : Date!) {
        content_of_the_week_by_date_and_type(type: $type, date: $date) {
          _id
          title
          description
          image_id
          start_date
          end_date
        }
      }
    `,
    {
      variables: { 
        type: type, 
        date : moment(date).format("YYYY-MM-DDTHH:mm:ssZ")
      },
      fetchPolicy : 'network-only'
    }
  );
}
import { gql, useQuery } from "@apollo/client";

export function useTaskSequencesWithoutSequence() {
  return useQuery(gql`
    query getTaskSequencesWithoutSequence {
      task_sequences_sort_by_name {
        name
        _id
        include_in_rotation
        image
        category
      }
    }
  `);
}

export function useTaskSequencesNameIdOnly() {
  return useQuery(gql`
    query getTaskSequencesNameIdOnly {
      task_sequences_sort_by_name {
        name
        _id
      }
    }
  `);
}


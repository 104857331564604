import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { DASHBOARD_ROOT } from "../../components/Routes/routingConstants";
import { useParams } from 'react-router-dom';
import { Table, Row, Col, Input, FormGroup, Label, Button, Alert } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { DateFormat,  UserStatusTypesArray, UserActivityGroup, UserActivity, ActivityHistoryTableDataTypeValue  } from "../../helper/constants"
import moment from 'moment';
import UserMetricsPieChart from "../../components/AdvanceReporting/UserMetricsPieChart"
import { useGetUserDetailedInfo, useGetUserActivityLogs } from "../../hooks/AdvanceReporting/useGetUserActivities";
import Loading from '../../components/Loading';
import { UserActivityLogs } from "../../interfaces/models/UserActivityLogs"
import UserNudgeLineChartDailyBased from "../../components/AdvanceReporting/UserNudgeLineChartDailyBased"
import UserNudgeLineChartMonthlyBased from "../../components/AdvanceReporting/UserNudgeLineChartMonthlyBased"
import UserUsageLineChartDailyBased from "../../components/AdvanceReporting/UserUsageLineChartDailyBased"
import UserUsageLineChartMonthlyBased from "../../components/AdvanceReporting/UserUsageLineChartMonthlyBased";
import { usePDF, Resolution, Margin, Options} from 'react-to-pdf';
import PrintPageContainer from "../../components/AdvanceReporting/PrintPageContainer";
import UserDataLogs from "../../interfaces/advanceReporting/UserDataLogs"

//#region Interfaces
interface RouteParams {
    id: string;
};
//#endregion Interfaces

const UserActivityHistoryPrintPage = () => {

//#region Initialization
    const { id } = useParams<RouteParams>();
    const searchParams = new URLSearchParams(window.location.search);
    const userName = searchParams.get("name");
    const organisation = searchParams.get("organisation");
    const userStatus = Number(searchParams.get("status")); 

    const breadcrumbsData = [
        { name: 'Reports', path: `${DASHBOARD_ROOT}/reporting/dashboard?status=${userStatus}&organisation=${organisation}` },
        { name: 'Activity History', path: `${DASHBOARD_ROOT}/reporting/dashboard/user-activity/${id}?name=${userName}&status=${userStatus}` },
        { name:  userName + ' - Print', path: `${DASHBOARD_ROOT}/reporting/dashboard/user-activity/${id}/print?name=${userName}&status=${userStatus}` },
    ];

    const options : Options = {
        // default is `save`
        filename : `User Reports as of ${moment().format(DateFormat)}.pdf`,
        method: 'save',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.MEDIUM,
        page: {
           margin: Margin.MEDIUM,
           format:"letter",
           orientation: 'portrait',
        },
        canvas: {
           // default is 'image/jpeg' for better size performance
           mimeType: 'image/png',
           qualityRatio: 1,
        },
    };


    const { toPDF, targetRef } = usePDF(options);

    const [userActivity, setUserActivity] = useState<UserActivityLogs[]>([]);
    const [userActivityFiltered, setUserActivityFiltered] = useState<UserActivityLogs[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<number>(0);
    const [selectedType, setSelectedType] = useState<number>(0);
    const [userDetails, setUserDetails] = useState<UserDataLogs>(null);
    const [isPrintMode, setIsPrintMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tablePages, setTablePages] = useState<number>(1);
    
    const { data : userData, loading : userLoading, error : userError } = useGetUserDetailedInfo(id);
    const { data : logsData, loading : logsLoading, error : logsError } = useGetUserActivityLogs(id);

    let numberOfItems = 20;

    useEffect(() => {
        if(!userData) return;
        setUserDetails(userData.user_detailed);

    },[userData])

    useEffect(() => {
        if(!logsData) return;
        const logList : UserActivityLogs[] = logsData.user_activity_logs;
        setUserActivity(logList);
        setUserActivityFiltered(logList);
        
    },[logsData]);
//#endregion Initialization

//#region Actions
    const HandlePrint = () => {
        setIsPrintMode(true);
        setIsLoading(true);
        setTimeout(() => {
            toPDF();
        },1000);
        setTimeout(() => {
            setIsLoading(false);
            setIsPrintMode(false);
        },2500)
    }

    const HandleGroupChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value)
        setSelectedGroup(value);
        setSelectedType(0);
        const filtered = userActivity.filter( u => 
            value !== 0 ?
                UserActivity.findIndex( z => z.id === u.type && z.group === value) > -1
            :   
                true
        );
        setUserActivityFiltered(filtered);
        setTablePages(Math.ceil(filtered.length / numberOfItems));
    }

    const HandleTypeChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value)
        setSelectedType(value);
        const filtered = userActivity.filter( u => 
            value !== 0 ?
                u.type === value
            :   
                UserActivity.findIndex( z => z.id === u.type && z.group === selectedGroup) > -1
        );
        setTablePages(Math.ceil(filtered.length / numberOfItems));
    }

    const HandleGenerateTable = (dataShowed : UserActivityLogs[], isFirstPage : boolean, key : number, isPreview : boolean, isLast : boolean = false) => {
        
        if(isFirstPage) 
        {
            return (
                <Table bordered striped>
                    <thead className="bg-primary text-white">
                        <tr>
                            <th>Activity</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            logsLoading &&
                            <tr>
                                <td colSpan={2} className="text-center">
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {
                            (dataShowed.length ===0 && !logsLoading) &&
                            <tr>
                                <td colSpan={2} className="text-center">No Data Found</td>
                            </tr>
                        }
                        {
                            (isPreview && dataShowed.length !== 0) ? 
                            <>
                                {
                                    dataShowed.slice(0,5).map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{ActivityHistoryTableDataTypeValue[item.type - 1]}</td>
                                                <td>{moment(item.created_at_utc).format(DateFormat)}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr >
                                    <td colSpan={2} className="text-center font-weight-bold">This is just a preview more data should be shown when you click "Print"</td>
                                </tr>
                            </>
                            :
                            dataShowed.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{ActivityHistoryTableDataTypeValue[item.type - 1]}</td>
                                        <td>{moment(item.created_at_utc).format(DateFormat)}</td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </Table>
            );
        }
        else  
        {
            return(
                <PrintPageContainer key={key} isLast={isLast}>
                    <Table bordered striped>
                            <thead className="bg-primary text-white">
                                <tr>
                                    <th>Activity</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataShowed.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{ActivityHistoryTableDataTypeValue[item.type - 1]}</td>
                                                <td>{moment(item.created_at_utc).format(DateFormat)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                </PrintPageContainer>
            );
        }
        
    }
    
//#endregion Actions
    return (
        <>
            <Breadcrumbs 
                crumbs={breadcrumbsData}
            />
            <Row className="pb-2">
                <Col md={6}>
                    <h4 className="text-capitalize font-weight-bold">{userName}' Activity History</h4>
                </Col>
                <Col className="d-flex justify-content-end" md={6}>
                    <Button color="primary" disabled={isLoading || logsLoading} onClick={HandlePrint}>Print</Button>
                </Col>
            </Row>
            
            <div ref={targetRef} className=" bg-white" style={{width:"1000px"}}>
                <PrintPageContainer>
                    
                    <div className="text-center">
                        <h1 className="mb-0">User Reports as of {moment().format(DateFormat)}</h1>
                    </div>
                    {
                        (userLoading) ?
                        <Loading />
                        : !userError && userDetails? 
                        <>
                            <div>
                                <Label className="font-weight-bold">Name :</Label> <span>{userDetails.name}</span>
                            </div>
                            <div>
                                <Label className="font-weight-bold">Email :</Label> <span>{userDetails.email}</span>
                            </div>
                            <div>
                                <Label className="font-weight-bold">Ogranisation :</Label> <span >{userDetails.organisation}</span>
                            </div>
                            <div>
                                <Label className="font-weight-bold">License(s) :</Label> <span >{userDetails.licenses}</span>
                            </div>
                            <div>
                                <Label className="font-weight-bold">License Allocated :</Label> <span >{moment(userDetails.license_allocated).format(DateFormat)}</span>
                            </div>
                            <div>
                                <Label className="font-weight-bold">License Expiry :</Label> <span >{moment(userDetails.license_expiry).format(DateFormat)}</span>
                            </div>
                            <div>
                                <Label className="font-weight-bold">Blocked :</Label> <span >{userDetails.is_blocked ? "YES" : "NO"}</span>
                            </div>
                            <div>
                                <Label className="font-weight-bold mr-2">Activity Status :</Label> 
                                <span className={`badge badge-${UserStatusTypesArray[userDetails.status - 1].color}`} >
                                    <i className={`${UserStatusTypesArray[userDetails.status - 1].icon}  mr-2`}></i>
                                    {UserStatusTypesArray[userDetails.status - 1].name}
                                </span>
                            </div>
                        </>
                        :
                        <Alert color="danger">
                            Oops, we’re having trouble getting the User info. Please give it a minute, and if it doesn’t resolve, let us know.
                        </Alert>
                    }
                    <div className="mt-5"></div>
                    <UserMetricsPieChart
                        user_id={id}
                        is_print={true}
                    />
                </PrintPageContainer>
                <div className="mt-2"></div>
                <PrintPageContainer>
                    <UserNudgeLineChartDailyBased 
                        user_id={id}
                        is_print={isPrintMode}
                    />
                    <div className="mt-5"></div>
                    <UserNudgeLineChartMonthlyBased
                        user_id={id}
                        is_print={isPrintMode}
                    />
                </PrintPageContainer>
                <div className="mt-2"></div>
                <PrintPageContainer>
                    <UserUsageLineChartDailyBased 
                        user_id={id}
                        is_print={isPrintMode}
                    /> 
                    <div className="mt-5"></div>
                    <UserUsageLineChartMonthlyBased 
                        user_id={id}
                        is_print={isPrintMode}
                    />
                </PrintPageContainer>
                <PrintPageContainer
                    isLast={userActivity.length <=20}
                >
                    {
                        !isPrintMode ? 
                            <Row>
                                <Col  md={6}>
                                    <FormGroup>
                                        <Label>Group : </Label>
                                        <Input
                                            type="select"
                                            value={selectedGroup}
                                            onChange={(e)=>HandleGroupChange(e)}
                                        >
                                            <option value="0">All</option>
                                            {
                                                UserActivityGroup.map((item, index ) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label> Type : </Label>
                                        <Input
                                            type="select"
                                            value={selectedType}
                                            onChange={(e)=>HandleTypeChange(e)}
                                            disabled={ selectedGroup === 0 }
                                        >
                                            <option value="0">All</option>
                                            {
                                                UserActivity.filter(u=>u.group === selectedGroup).map((item, index ) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        : <></>
                    }

                    {
                        logsError && 
                        <Alert color="danger">
                            Oops, we’re having trouble getting the User Logs. Please give it a minute, and if it doesn’t resolve, let us know.
                        </Alert>
                    }
                    <div className="text-center">
                        <h2> User Activity Log </h2>
                    </div>
                    {
                        HandleGenerateTable(userActivityFiltered.slice(0,20),true, 1, !isPrintMode)
                    }
                </PrintPageContainer>
                {
                    isPrintMode && tablePages > 1 && 
                    Array.from({length : tablePages - 1}).map((_, i) => {
                        const sliced = userActivityFiltered.slice(numberOfItems, numberOfItems + 20);
                        numberOfItems += 20;
                        return HandleGenerateTable(sliced, false, i, false, i === tablePages - 1);
                    })
                }
            </div>
            
        </>
    )
}


export { UserActivityHistoryPrintPage as UserActivityHistoryPrint };
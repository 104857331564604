import { gql, useQuery } from "@apollo/client";

export function useUserProgressContents() {
    return useQuery(
        gql`
          query getUserProgressContents {
            user_progress_contents {
              _id
              name
              color
            }
          }
        `,
        {
          fetchPolicy : 'network-only'
        }
      );
}
import { Line } from "react-chartjs-2"
import { useState, useEffect } from "react";
import { Input, Label } from "reactstrap";
import Loading from "../Loading";
import { Months } from "../../helper/constants";
import { useGetUsersNudgeDataMonthly} from "../../hooks/AdvanceReporting/useGetUserActivities";
import { Alert } from "reactstrap";
import { UserActivityType, FontFamily, ChartJSBaseFontColor  } from "../../helper/constants"
//#region Interfaces
interface Props {
    organisation_id? : string;
    user_id? : string;
    is_print? : boolean;
}

interface NudgeData {
    type : number;
    count : number;
    month : number
}
//#endregion Interfaces
export default function UserNudgeLineChartMonthlyBased({organisation_id, user_id, is_print = false} : Props) {

//#region Initializations
    const now = new Date();
    const year = now.getFullYear();
    
    const yearOptions : number[] = [];
    for(let x = 2020; x <= year; x++) {
        yearOptions.push(x);
    }

    const [chartData, setChartData] = useState({});
    const [nudgeYear, setNudgeYear] = useState<number>(year);

    const [isRefetching, setIsRefetching] = useState<boolean>(false);
    const { data, loading, error, refetch } = useGetUsersNudgeDataMonthly( year, organisation_id, user_id);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true, // Enable the title
            text: "Monthly Nudge Report", // Title text
            fontSize: 22, // Font size for the title
            padding: 20, // Padding around the title
            fontColor : ChartJSBaseFontColor,
            fontFamily : FontFamily,
        },
        legend :{
            labels: {
                fontColor : ChartJSBaseFontColor,
                fontFamily : FontFamily,
                fontSize : 20,
            }
        },
        scales: {
            xAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Months",
                        fontColor: ChartJSBaseFontColor,
                        fontSize : 20
                    },
                    ticks: {
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                    },
                },
            ],
            yAxes: 
            [
                {
                    display: true,
                    scaleLabel: 
                    {
                        display: true,
                        labelString: "Total Count",
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                    },
                    ticks: 
                    {
                        beginAtZero: true,
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                    },
                },
            ],
        },
    };
    

    useEffect(() => {
        if(data) {
            const dataset : NudgeData[] = data.user_activity_monythly_nudge_data;
            const acknowledgements : number[] = [];
            const snoozes : number[] = [];

            // Seggregate the data by type
            for(let month = 1; month <= 12; month++) {
                
                const getDayAcknowledge = dataset.find((u) => u.month === month && u.type === UserActivityType.NUDGE_ACKNOWLEDGE);
                acknowledgements.push(getDayAcknowledge ? getDayAcknowledge.count : 0);

                const getDaySnooze = dataset.find((u) => u.month === month && u.type === UserActivityType.NUDGE_SNOOZE);
                snoozes.push(getDaySnooze ? getDaySnooze.count : 0);

            }

            const graphData = {
                labels: Months,
                datasets: 
                [
                    {
                        label: "Acknowledges",
                        data: acknowledgements,
                        borderColor: "rgba(75, 192, 192, 1)",
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        fill: true,
                        tension: 0.1,
                    },
                    {
                        label: "Snoozes",
                        data: snoozes,
                        borderColor: "rgba(255, 99, 132, 1)",
                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                        fill: true,
                        tension: 0.1,
                    },
                ],
            };

            setChartData(graphData);
        }
    }, [nudgeYear, data]);

//#endregion Initializations

//#region Actions
    
    const HandleNudgeChangeYear = async (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setNudgeYear(value);
        setIsRefetching(true);
        await refetch({
            year : value, organisation_id, user_id
        });
        setIsRefetching(false);
    }

//#endregion Initializations
  return (
    <>
        {
            !is_print ? 
            <div className="d-flex">
                <Label className="mr-3 mt-2">Year:</Label>
                <Input
                    type="select"
                    value={nudgeYear}
                    onChange={HandleNudgeChangeYear}
                    >
                    {
                        yearOptions.map((item, index) => (
                            <option value={item} key={index}>{item}</option>
                        ))
                    }
                </Input>
            </div>
            :
            <div className="text-center">
                <h2> Graph for the year of {nudgeYear} </h2>
            </div>
        }
        {
            error &&
            <Alert color="danger">
                Oops, we’re having trouble getting the graph. Please give it a minute, and if it doesn’t resolve, let us know.
            </Alert>
        }
        {
            (loading || isRefetching) ? 
            <div style={{height:400}} className="pt-5">
                <Loading /> 
            </div>
            :
            <div style={{height: "400px" }}>
                <Line data={chartData} options={options} />
            </div>
        }
    </>
  );
}
import * as Yup from "yup";

const MIN_TITLE_LENGTH: number = 5;
const MAX_TITLE_LENGTH: number = 70;
const MIN_DESCRIPTION_LENGTH: number = 5;
const MAX_DESCRIPTION_LENGTH: number = 250;

const imageValidator =  (value : string) => {
    if (!value) return true; // Skip validation if the field is empty
    value = value.toLowerCase();

    const validExtensions = [".jpg", ".jpeg"];
    // Check if the file has a valid extension
    const isValid = validExtensions.some(extension => value.endsWith(extension));
    return isValid;
}

const AddTaskValidator = Yup.object({
    name : Yup.string()
        .required("Name is Required")
        .min(
            MIN_TITLE_LENGTH,
            `Name must be at least ${MIN_TITLE_LENGTH} characters long`
        )
        .max(
            MAX_TITLE_LENGTH,
            `Name must be less than ${MAX_TITLE_LENGTH} characters long`
        ),
    description : Yup.string()
        .required("Description is required")
        .min(
            MIN_DESCRIPTION_LENGTH,
            `Description text must be at least ${MIN_DESCRIPTION_LENGTH} characters long`
        )
        .max(
            MAX_DESCRIPTION_LENGTH,
            `Description text must be less than ${MAX_DESCRIPTION_LENGTH} characters long`
        ),
    image1 : Yup.string()
        .required("Image is required")
        .test(
            "is-jpg",
            "Image must be a valid jpg/jpeg image file",
            imageValidator
        ),
    instruction1 : Yup.string()
        .min(
            MIN_DESCRIPTION_LENGTH,
            `Instruction text must be at least ${MIN_DESCRIPTION_LENGTH} characters long`
        )
        .max(
            MAX_DESCRIPTION_LENGTH,
            `Instruction text must be less than ${MAX_DESCRIPTION_LENGTH} characters long`
        ),
    image2 : Yup.string()
        .test(
            "is-instruction2-exist",
            "An image is required if there is instruction",
            function (value) {

                const { instruction2 } = this.parent;
                
                return instruction2  && !value? false : true;
            }
        )
        .test(
            "is-jpg",
            "Image must be a valid jpg/jpeg image file",
            imageValidator
        ),
    instruction2 : Yup.string()
        .min(
            MIN_DESCRIPTION_LENGTH,
            `Instruction text must be at least ${MIN_DESCRIPTION_LENGTH} characters long`
        )
        .max(
            MAX_DESCRIPTION_LENGTH,
            `Instruction text must be less than ${MAX_DESCRIPTION_LENGTH} characters long`
        )
        .test(
            "is-image2-exist",
            "An instruction is required if there are Image",
            function (value) {

                const { image2 } = this.parent;
                
                return image2 && !value ? false : true;
            }
        ),
    video_link: Yup.string()
        .nullable()
        .matches(
          /((https?):\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9#-]*)?(\?[a-zA-Z0-9-_=&]*)?(#?[a-zA-Z0-9]*)?/,
          "Please provide a valid url"
        ),
    video_name : Yup.string()
        .nullable()
        .test(
            "is-video",
            "File must be a valid video file",
            function(value ) {
                if(!value) return true;
                
                const validExtensions = ['mp4', 'mkv', 'avi', 'mov', 'webm'];
                // Check if the file has a valid extension
                const isValid = validExtensions.some(extension => value.endsWith(extension));
                return isValid;
            }
        ),
});

export default AddTaskValidator;
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

interface PromptDialogProps {
  title?: string;
  promptMessage?: string;
  isOpen: boolean;
  cancelBtnTxt?: string;
  confirmBtnTxt?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isOnProcess? : boolean;
}

export default function PromptDialog({
  title,
  promptMessage,
  cancelBtnTxt,
  confirmBtnTxt,
  isOpen,
  onConfirm,
  onCancel,
  isOnProcess
}: PromptDialogProps) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        onCancel();
      }}
    >
      <ModalHeader
        tag="h1"
        toggle={() => {
          onCancel();
        }}
      >
        {title}
      </ModalHeader>
      <ModalBody>
        <p>{promptMessage}</p>
        <div className="d-flex justify-content-end">
          {/* TODO: need to create props for custom styling these buttons*/}
          <Button onClick={onCancel} disabled={isOnProcess}>{cancelBtnTxt ?? "Cancel"}</Button>
          <Button onClick={onConfirm} className="bg-danger text-white" disabled={isOnProcess} >
            {confirmBtnTxt ?? "Confirm"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

import * as Yup from "yup";

const MIN_TITLE_LENGTH: number = 5;
const MAX_TITLE_LENGTH: number = 50;
const MIN_DESCRIPTION_LENGTH: number = 5;
const MAX_DESCRIPTION_LENGTH: number = 250;


const editTopicValidator = Yup.object({
    id : Yup.string()
        .required("Id is Required"),
    name : Yup.string()
        .required("Name is Required")
        .min(
            MIN_TITLE_LENGTH,
            `Name must be at least ${MIN_TITLE_LENGTH} characters long`
        )
        .max(
            MAX_TITLE_LENGTH,
            `Name must be less than ${MAX_TITLE_LENGTH} characters long`
        ),
    alt : Yup.string()
        .required("Alt is Required")
        .min(
            MIN_DESCRIPTION_LENGTH,
            `Alt text must be at least ${MIN_DESCRIPTION_LENGTH} characters long`
        )
        .max(
            MAX_DESCRIPTION_LENGTH,
            `Alt text must be less than ${MAX_DESCRIPTION_LENGTH} characters long`
        ),
    description : Yup.string()
        .required("Description is Required")
        .min(
            MIN_DESCRIPTION_LENGTH,
            `Description text must be at least ${MIN_DESCRIPTION_LENGTH} characters long`
        )
        .max(
            MAX_DESCRIPTION_LENGTH,
            `Description text must be less than ${MAX_DESCRIPTION_LENGTH} characters long`
        ),
    url : Yup.string()
        .required("Link is required")
        .matches(
            /^https:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9-_/]*)?(\?[a-zA-Z0-9-_=&]*)?(#?[a-zA-Z0-9]*)?$/,
            "Please provide a valid url ex. https://www.google.com" 
        ),
    image : Yup.string()
        .required("Image is required")
        .test(
            "is-jpg",
            "Image must be a valid image file",
            function (value) {
                if (!value) return true; // Skip validation if the field is empty
                value = value.toLowerCase();

                const validExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp", ".svg"];
                // Check if the file has a valid extension
                const isValid = validExtensions.some(extension => value.endsWith(extension));
                return isValid;
            }
        ),
});

export default editTopicValidator;
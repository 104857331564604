import { Line } from "react-chartjs-2"
import { useState, useEffect } from "react";
import { getDaysInMonth } from "../../helper/weekOfTheMonthHelper"
import { Row, Col, Input, Label, Alert} from "reactstrap"
import Loading from "../Loading";
import { Months, FontFamily, ChartJSBaseFontColor } from "../../helper/constants";
import { useGetUsersUsageDataDaily} from "../../hooks/AdvanceReporting/useGetUserActivities";

//#region Interfaces
interface Props {
    organisation_id? : string;
    user_id? : string;
    is_print? : boolean;
}

interface UsageDate {
    count : number;
    day : number
}
//#endregion Interfaces
export default function UserNudgeLineChartDailyBased({organisation_id, user_id, is_print = false} : Props) {

//#region Initializations
    const [chartData, setChartData] = useState({});

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    const yearOptions : number[] = [];
    for(let x = 2020; x <= year; x++) {
        yearOptions.push(x);
    }

    const [usageMonth, setUsageMonth] = useState<number>(month);
    const [usageYear, setUsageYear] = useState<number>(year);

    const [isRefetching, setIsRefetching] = useState<boolean>(false);
    const { data, loading, error, refetch } = useGetUsersUsageDataDaily(month + 1, year, organisation_id, user_id);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true, // Enable the title
            text: "Daily User Usage Report", // Title text
            fontSize: 22, // Font size for the title
            padding: 20, // Padding around the title
            fontColor : ChartJSBaseFontColor,
            fontFamily : FontFamily,
        },
        legend :{
            labels: {
                fontColor : ChartJSBaseFontColor,
                fontFamily : FontFamily,
                fontSize : 20,
            }
        },
        scales: {
            xAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Days of the Month",
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                        fontSize : 20
                    },
                    ticks: {
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                    },
                },
            ],
            yAxes: 
            [
                {
                    display: true,
                    scaleLabel: 
                    {
                        display: true,
                        labelString: "Total Count",
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                    },
                    ticks: 
                    {
                        beginAtZero: true,
                        fontColor: ChartJSBaseFontColor,
                        fontFamily : FontFamily,
                    },
                },
            ],
        },
    };


    useEffect(() => {
        if(!data) return;

        const dataset : UsageDate[] = data.user_activity_daily_usage_data;
        const days = getDaysInMonth(usageMonth,usageYear);
        
        const Usage : number[] = [];
        for(let day = 1; day <= days.length; day++) {
                
            const getUsage = dataset.find((u) => u.day === day);
            Usage.push(getUsage ? getUsage.count : 0);

        }
        
        const graphData = {
            labels: days,
            datasets: 
            [
                {
                    label: "Usage",
                    data: Usage,
                    borderColor: "rgba(75, 192, 192, 1)",
                    backgroundColor: "rgba(75, 192, 192, 0.2)",
                    fill: true,
                    tension: 0.1,
                },
            ],
        };

        setChartData(graphData);
    }, [data, usageMonth, usageYear]);
//#endregion Initializations

//#region Actions
    const HandleUsageChangeYear = async (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setIsRefetching(true);
        setUsageYear(value);
        await refetch({
            month : usageMonth + 1, year : value, organisation_id, user_id
        });
        setIsRefetching(false);
    }

    const HandleUsageChangeMonth = async (e : React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setUsageMonth(value);
        setIsRefetching(true);
        await refetch({
            month : value + 1, year : usageYear, organisation_id, user_id
        });
        setIsRefetching(false);
    }
//#endregion Actions

  return (
    <>
        {
            !is_print ?
                <Row>
                    <Col className="d-flex">
                        <Label className="mr-3 mt-2">Year:</Label>
                        <Input
                            type="select"
                            value={usageYear}
                            onChange={HandleUsageChangeYear}
                        >
                            {
                                yearOptions.map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))
                            }
                        </Input>
                    </Col>
                    <Col className="d-flex">
                        <Label className="mr-3 mt-2">Month:</Label>
                        <Input
                            type="select"
                            value={usageMonth}
                            onChange={HandleUsageChangeMonth}
                        >
                            {
                                Months.map((item, index) => (
                                    <option value={index} key={index}>{item}</option>
                                ))
                            }
                        </Input>
                    </Col>
                </Row>
            :
            <div className="text-center">
                <h2> Graph as of {Months[usageMonth]} {usageYear} </h2>
            </div>
        }
        
        {
            error &&
            <Alert color="danger">
                Oops, we’re having trouble getting the graph. Please give it a minute, and if it doesn’t resolve, let us know.
            </Alert>
        }
        {
            (loading || isRefetching)  ? 
            <div className="pt-5" style={{height: "400px" }}>
                <Loading />
            </div>
            : 
            <div style={{height: "400px" }}>
                <Line data={chartData} options={options} />
            </div>
        }
    </>
  );
}
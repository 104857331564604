
import Logo from "../../assets/img/brand/deskcoach-dark.svg"
import moment from "moment";

export default function PrintPageContainer({ children, isLast = false }) {

    let height = !isLast ? 1325 : 1295;
  
    return (
        <div className="page-height-letter" style={{height : height + "px", maxHeight : height + "px", minHeight: height + "px"}}>
            {children}
            <div className="d-flex justify-content-between page-footer" style={{paddingBottom: !isLast ? "30px" : ""}}>
                <span className="text-primary font-weight-bold">©{moment().format("YYYY")} desk.coach</span>
                <img src={Logo} alt="desk.coach" />
            </div>
        </div>
    );
}

import { Link } from 'react-router-dom';
import { Card, CardBody} from 'reactstrap'
interface LinkCardProps {
    link: {
        name : string;
        url : string;
        tag : string;
    };
}

export default function LinkCards({ link }: LinkCardProps) {

    return (
        <Card>
            <CardBody>
                <h4 >{link.name}</h4>
                <Link to={link.url} className="btn btn-primary">
                    Edit 
                </Link>
            </CardBody>
        </Card>
    )
}
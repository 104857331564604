import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Alert, Breadcrumb, BreadcrumbItem, Button, Tooltip } from "reactstrap";
import Loading from "../../components/Loading";
import PromptDialog from "../../components/PromptDialog";
import { getEmpowerMeTaskSchedule } from "../../graphql/empower-me/EmpowerMeQueries";
import { SoftDeleteScheduledEmpowerMeTask } from "../../graphql/empower-me/mutations/SoftDeleteEmpowerMeTask";
import { EmpowerScheduledTask } from "../../interfaces/models/EmpowerTask";
import EmpowerMeTaskModal from "./EmpowerMeTaskModal";
import EmpowerMeTaskTable from "./EmpowerMeTaskTable";
import toast from "react-hot-toast";
import { IsEmpowerNudgeActive } from "../../helper/empowerNudgeHelpers";
import "./EmpowerMePage.scss";
import { UserActivityTypeWithGroup } from "../../helper/constants"
import { AddActivityHookExecute, ModelEnums } from "../../helper/addUserActivity"

const MAX_SCHEDULED_EMPOWER_ME_TASKS = 10;

export default function EmpowerMePage() {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState<boolean>(false);
  
  const { executeAddActivity } = AddActivityHookExecute();

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const toggleDisabledCreateTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const [selectedTask, setSelectedEmpowerTask] =
    useState<EmpowerScheduledTask>(null);
  const [empowerTasks, setEmpowerTasks] = useState<EmpowerScheduledTask[]>([]);
  const {
    data: usersEmpowerMeTasks,
    loading,
    error,
    refetch,
  } = useQuery(getEmpowerMeTaskSchedule, {
    onCompleted(data) {
      const results =
        data?.me?.empower_task_schedule?.scheduled_tasks?.map((task) => {
          return task;
        }) ?? [];
      setEmpowerTasks(results);
    },
    onError(error) {
      console.log(error);
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, // Useful for invoking `onCompleted` during `refetch` calls
  });

  const [softDeleteScheduledEmpowerMeTask] = useMutation(
    SoftDeleteScheduledEmpowerMeTask
  );

  function filterActiveEmpowerMeTasks(empowerMeTasks: EmpowerScheduledTask[]) {
    const active_tasks = empowerMeTasks.filter((task: EmpowerScheduledTask) => {
      return IsEmpowerNudgeActive(task);
    });
    return active_tasks;
  }

  const shouldDisableCreateButton =
    filterActiveEmpowerMeTasks(empowerTasks).length >=
    MAX_SCHEDULED_EMPOWER_ME_TASKS;

  const toggleModal = () => setModalOpen(!isModalOpen);

  function showDeletePrompt(empowerTask: EmpowerScheduledTask) {
    setSelectedEmpowerTask(empowerTask);
    setIsDeletePromptOpen(true);
  }
  const closeDeletePrompt = () => {
    setIsDeletePromptOpen(false);
  };
  function deleteEmpowerMeTask() {
    closeDeletePrompt();
    toast.promise(
      softDeleteScheduledEmpowerMeTask({
        variables: {
          id: selectedTask._id,
        },
      }),
      {
        loading: "Deleting...",
        success: () => {
          refetch();
          executeAddActivity({
            type : UserActivityTypeWithGroup.EM_DELETE.id, 
            type_group : UserActivityTypeWithGroup.EM_DELETE.group,
            on_model : ModelEnums.EM,
            related_id : selectedTask.empower_me_task._id,
          });
          return "Successfully deleted!\nEnsure you select 'Sync Tasks' from your app to see changes take effect on your device immediately";
        },
        error:
          "Sorry, your empower me task could not be deleted. Please try again in a few minutes or contact a System Administrator.",
      },
      {
        success: {
          duration: 9000,
          style: {
            textAlign: "center",
          },
        },
      }
    );
    setSelectedEmpowerTask(null);
  }

  if (loading) return <Loading />;
  // Display error message if user's empower me tasks cannot be retrieved
  if (!usersEmpowerMeTasks || error) {
    return (
      <Alert color="danger">
        Sorry! Something went wrong while retrieving the user's empower me tasks
        - Please try again later or contact a System Administrator.
      </Alert>
    );
  }

  return (
    <>
      <div className="w-100">
        <Breadcrumb listTag="div">
          <BreadcrumbItem>Home</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="w-100 d-flex flex-row flex-wrap justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <h3>Your empower me Tasks</h3>
          <div
            className="alert alert-info modal-alert-msg"
            style={{ backgroundColor: "rgba(55, 213, 242, 0.25)" }}
          >
            <h4 className="m-0 font-black">
              Thanks for checking out our Beta of empower me. We're adding more soon, feel free to let us know what you'd like to see!
            </h4>
          </div>
        </div>
        <div className="d-flex flex-row">
          <Button
            color="secondary"
            onClick={(e) => {
              const baseUrl =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname;
              window.location.href = baseUrl + "?syncEmpowerMe=true";
            }}
          >
            Sync Tasks
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              setSelectedEmpowerTask(null);
              toggleModal();
            }}
            disabled={shouldDisableCreateButton}
          >
            {/* This wrapping div is a workaround in order to have the Tooltip display for a disabled button */}
            <div id="tooltipTarget">Add Task</div>
          </Button>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen && shouldDisableCreateButton}
            target="tooltipTarget"
            toggle={toggleDisabledCreateTooltip}
          >
            You are limited to {MAX_SCHEDULED_EMPOWER_ME_TASKS} active empower
            me tasks. Before scheduling a new task you must delete an existing
            one.
          </Tooltip>
        </div>
      </div>
      <EmpowerMeTaskTable
        empowerTasks={empowerTasks}
        deleteEmpowerMeTask={showDeletePrompt}
      />
      {isModalOpen && (
        <EmpowerMeTaskModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          refetchTasks={refetch}
        />
      )}
      {isDeletePromptOpen && (
        <PromptDialog
          isOpen={isDeletePromptOpen}
          onCancel={closeDeletePrompt}
          onConfirm={deleteEmpowerMeTask}
          title="Delete empower me task?"
          promptMessage="Are you sure you want to delete this empower me task and all scheduled nudges?"
          confirmBtnTxt="Delete"
        />
      )}
    </>
  );
}

import { useVideosRandom } from "../../hooks/useVideos";
import { Fragment, useEffect, useState } from "react";
import { Stream } from "@cloudflare/stream-react";
import { Alert, Button, Card, CardBody, Modal, ModalBody } from "reactstrap";
import { getThumbnailUrl } from "../../helper/getThumbnailUrl";
import Loading from "../../components/Loading";
import Video from "../../interfaces/models/Video";
import { UserActivityTypeWithGroup  } from "../../helper/constants"
import { AddActivityHookExecute, ModelEnums } from "../../helper/addUserActivity"

export default function MovementCoachPreview() {

    const { data, loading, error } = useVideosRandom();

    const [randomVideo, setRandomVideo] = useState<Video>(null);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        if(!data) return;
        setRandomVideo(data.video_random);

    },[data]);
    return (
        <>
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the movement.coach. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }  
            {
                loading && 
                <div className="text-center">
                    <Loading />
                </div>
            }
            {
                randomVideo &&
                <>
                    <Card>
                        <CardBody className="text-center" >
                            <img
                                className="cursor-pointer"
                                style={{ 
                                  height: "300px",
                                  width :"100%",
                                  objectFit : "cover"
                                }}
                                onClick={toggle}
                                src={getThumbnailUrl(randomVideo.video_url)}
                                alt={randomVideo.description}
                            />
                            <div >
                                <div><h3 className="mt-2">{randomVideo.description}</h3></div>
                                {randomVideo.video_url && (
                                <Button className="btn" onClick={toggle}>
                                    <span className="btn-inner--icon">
                                    <i className="fa fa-play" aria-hidden="true" />
                                    </span>
                                    <span className="btn-inner--text">Open video</span>
                                </Button>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                    <div>
                        <Modal size="xl" isOpen={modal} toggle={toggle}>
                        <ModalBody>
                            <MovementVideoPlayer video={randomVideo} />
                        </ModalBody>
                        </Modal>
                    </div>
                </>
            }
        </>
    )
}

interface MovementVideoPlayerProps {
    video: Video;
  }
  
  function MovementVideoPlayer({video}: MovementVideoPlayerProps) {
    const [loaded, setLoaded] = useState<boolean>(false);
    const videoId = (id) => id.match(/video.\b\w([.^\S]*)\w*\b/gm)[0];
    const [isPlayed, setIsPlayed] = useState<boolean>(false);
  
    const { executeAddActivity } = AddActivityHookExecute();
  
    const handleOnPlay = () => {
      if(!isPlayed) {
        executeAddActivity({
          type : UserActivityTypeWithGroup.MC.id,
          type_group : UserActivityTypeWithGroup.MC.group,
          on_model : ModelEnums.VIDEOS,
          related_id : video._id
        });
        setIsPlayed(true);
      }
    }
  
    useEffect(() => {
      if(!loaded){
        setIsPlayed(false);
      }
    },[loaded])
  
    return (
      <Fragment>
        {!loaded && <Loading />}
        <Stream
          className={!loaded && "invisible"}
          onLoadedData={() => setLoaded(true)}
          controls
          autoplay={true}
          src={videoId(video.video_url)}
          onPlay={()=>{ handleOnPlay(); }}
        />
      </Fragment>
    );
  }
  
import { gql, useQuery } from '@apollo/client';

export function useGetUserChallengeAcknowledgement(content_id : string) {
    return useQuery(gql`
        query getUserChallengeAcknowledgement ($content_id : String!) {
                me_challenge_acknowledgement(content_id: $content_id) {
                    _id
                    user_id
                    created_at_utc
                    content_id
                }
            }
        `,
        {
            fetchPolicy : 'network-only',
            variables : {
                content_id
            }
        }
    );
}
import { addActivity } from "../graphql/advance-reporting/addUserActivityLogs"
import { useMutation } from "@apollo/client";

export const ModelEnums = {
    EM : "empower_me_tasks",
    TASK : "tasks",
    VIDEOS : "videos",
    EMPTY : ""
}

interface ActivityLogInput {
    type : number;
    type_group : number;
    related_id : string;
    on_model : string;
}

export const AddActivityHookExecute = () => {
    const [addActivityHook, { loading, error, data }] = useMutation(addActivity);

    const executeAddActivity = async (data : ActivityLogInput) => {
        try {
            await addActivityHook({
                variables: {
                    record: data,
                },
            });
        } catch (err) {
            console.error("Error logging activity:", err);
        }
    };

    return { executeAddActivity, loading, error, data };
} 
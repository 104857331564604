import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import {
  EmpowerScheduledTask,
  EmpowerTask,
} from "../../interfaces/models/EmpowerTask";
import EmpowerTaskSelection from "./EmpowerTaskSelection";
import {
  RRuleEndAfterCountFieldName,
  RRuleEndNever,
  RRuleEndOnDateFieldName,
} from "./Scheduling/EmpowerMeFormFieldConstants";
import EmpowerTaskCompletion from "./EmpowerTaskCompletion";
import { useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Frequency, RRule } from "rrule";
import { useMutation } from "@apollo/client";
import { CreateEmpowerMeTask } from "../../graphql/empower-me/mutations/CreateEmpowerMeTask";
import EmpowerScheduleSelection from "./Scheduling/EmpowerScheduleSelection";
import "./EmpowerMePage.scss";
import AddEmpowerMeTaskValidator from "../../validators/AddEmpowerMeTaskValidator"
import { UserActivityTypeWithGroup } from "../../helper/constants"
import { AddActivityHookExecute, ModelEnums } from "../../helper/addUserActivity"
import moment from "moment";

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
  editTask?: EmpowerScheduledTask;
  refetchTasks: () => void;
}

export interface EmpowerScheduleForm {
  taskId?: string;
  date?: string;
  time?: string;
  repeatInterval?: number;
  repeatFrequency?: Frequency; // RRule.Frequency: DAILY, WEEKLY, MONTHLY
  rRuleEndRadio?: string;
  endRRuleOnDate?: string;
  endRRuleAfterCount?: number;
}

const modalHeaderTexts = [
  "Please select an empower me task",
  "Please schedule a time for your empower me task",
  "",
];

export default function EmpowerMeTaskModal({
  isOpen,
  toggle,
  refetchTasks,
}: ModalProps) {
  const formRef = useRef<FormikProps<EmpowerScheduleForm>>(null);
  const [modalStep, setModalStep] = useState<number>(0);
  const [selectedTask, setSelectedTask] = useState<EmpowerTask | null>(null);
  // Use this state to determine which fields should be validated on submit and page changes
  const [recurrenceEnabled, setRecurrenceEnabled] = useState<boolean>(false);
  const [refetchUsersEmpowerTasks, setRefetchUsersEmpowerTasks] =
    useState<boolean>(false);
  // Mutations
  const [
    createEmpowerMeTask,
    { loading: creatingTask, error: createTaskError },
  ] = useMutation(CreateEmpowerMeTask);

  const { executeAddActivity } = AddActivityHookExecute();

  function enableRecurrence(enable: boolean) {
    setRecurrenceEnabled(enable);
  }

  function resetModal() {
    // Clear states on success
    setSelectedTask(null);
    formRef?.current?.resetForm();
    setModalStep(0);
    // Update the user's empower me tasks in the table if they've created a task
    if (refetchUsersEmpowerTasks) {
      refetchTasks();
      setRefetchUsersEmpowerTasks(false);
    }
  }

  async function handleNextStep() {
    if (modalStep === 0) {
      if (selectedTask !== null) {
        setModalStep((prevStep) => (prevStep += 1));
      }
    }
    if (modalStep === 1) {
      formRef?.current.handleSubmit();
    }
  }

  async function submitEmpowerMeForm(values: EmpowerScheduleForm) {
    // Construct start_date in correct format for mutation
    const start_date_iso = new Date(
      values.date + " " + values.time
    ).toISOString();

    let rrule = null;
    if (recurrenceEnabled) {
      // Construct RRULE to be passed in mutation
      try {
        const rRuleEndCondition = values.rRuleEndRadio;
        rrule = new RRule({
          freq: values.repeatFrequency,
          interval: values.repeatInterval,
          dtstart: undefined,
          until:
            rRuleEndCondition === RRuleEndOnDateFieldName
              ? new Date(Date.parse(values.endRRuleOnDate))
              : undefined,
          count:
            rRuleEndCondition === RRuleEndAfterCountFieldName
              ? values.endRRuleAfterCount
              : undefined,
        }).toString();
      } catch (error) {
        console.log(
          "Error ocurred whilst constructing recurrence rule string: ",
          error
        );
      }
    }
    try {
      setModalStep((prevStep) => (prevStep += 1));
      await createEmpowerMeTask({
        variables: {
          task_id: selectedTask._id,
          start_date: start_date_iso,
          recurrence_rule: rrule,
        },
      });
      executeAddActivity({
        type : UserActivityTypeWithGroup.EM_CREATE.id, 
        type_group : UserActivityTypeWithGroup.EM_CREATE.group,
        on_model : ModelEnums.EM,
        related_id : selectedTask._id,
      });
      // Don't unnecessarily refresh if there was an error
      setRefetchUsersEmpowerTasks(true);
    } catch (error) {
      console.log(
        "Error occurred whilst creating/updating user's scheduled empower me task:",
        error
      );
    }
  }

  const NextStepButton = () => {
    let label = "Next";
    let shouldDisable = selectedTask === null; // Disable Next button unless a task has been selected
    let onClicked = () => {
      handleNextStep();
    };
    // Final step
    if (modalStep === 2) {
      return (
        <Button
          color="primary"
          onClick={(e) => {
            resetModal();
            toggle();
          }}
          disabled={creatingTask}
        >
          Done
        </Button>
      );
    } else if (modalStep === 1) {
      label = "Schedule";
      shouldDisable = false; // TODO: this should disable until the user specifies a scheduled time
    }
    return (
      <Button color="primary" onClick={onClicked} disabled={shouldDisable}>
        {label}
      </Button>
    );
  };

  const PrevStepButton = () => {
    const hideButton = modalStep === 0 || modalStep === 2;
    return (
      <Button
        className={`${hideButton && "invisible"}`}
        disabled={hideButton}
        color="secondary"
        onClick={(e) => {
          setModalStep((prevStep) => (prevStep -= 1));
        }}
      >
        Go Back
      </Button>
    );
  };

  // Construct initial values to be passed to form
  const initialValues: EmpowerScheduleForm = {
    taskId: null,
    date: moment().format("yyyy-MM-DD"),
    time: moment().format("HH:mm"),
    repeatInterval: null,
    repeatFrequency: Frequency.DAILY,
    rRuleEndRadio: RRuleEndNever,
    endRRuleOnDate: undefined,
    endRRuleAfterCount: undefined,
  };

  return (
    <>
      <Modal modalClassName="custom-modal" isOpen={isOpen}>
        <ModalHeader
          className="pt-3 pb-0 px-4"
          toggle={() => {
            if (!creatingTask) {
              toggle();
              if (modalStep === 2) {
                resetModal();
              }
            }
          }}
        >
          <p className="text-lg">{modalHeaderTexts[modalStep]}</p>
        </ModalHeader>
        <ModalBody className="d-flex flex-column justify-content-between overflow-auto">
          <div className="custom-modal-body">
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validateOnBlur={false}
              validationSchema={AddEmpowerMeTaskValidator}
              onSubmit={(values) => {
                submitEmpowerMeForm(values);
              }}
            >
              <>
                {modalStep === 0 && (
                  <EmpowerTaskSelection
                    select={setSelectedTask}
                    currentSelection={selectedTask}
                  />
                )}
                {modalStep === 1 && (
                  <EmpowerScheduleSelection
                    recurrenceEnabled={recurrenceEnabled}
                    enableRecurrence={enableRecurrence}
                  />
                )}
                {modalStep === 2 && (
                  <EmpowerTaskCompletion
                    loading={creatingTask}
                    error={createTaskError}
                  />
                )}
              </>
            </Formik>
          </div>
          <div className="modal-step-buttons">
            <PrevStepButton />
            <NextStepButton />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

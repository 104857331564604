import { Row, Col, Card, Table, Button, Badge, CardBody, Modal, ModalBody, ModalFooter,ModalHeader, FormGroup, Label, Input, Alert } from "reactstrap";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DASHBOARD_ROOT } from "../../components/Routes/routingConstants";
import { UserStatusTypes, UserStatusTypesArray } from "../../helper/constants"
import toast from "react-hot-toast";
import FormError from "../Shared/Forms/FormError";
import { useGetUserLatestActivities} from "../../hooks/AdvanceReporting/useGetUserActivities";
import Loading from "../Loading";
import UserData from "../../interfaces/advanceReporting/UserData";
import { ExportUserActivity } from "../../helper/ReportExports/ExportUserActivityTracker";

//#region Interfaces
interface Email {
    subject : string;
    body : string;
}

interface Props {
    organisation? : string;
}
//#endregion Interfaces

//#region Interfaces
const emailMessagePreset : Email [] = [
    {
        subject : "Thank You for Being Part of desk.coach!",
        body : `We just wanted to say thank you for being part of the desk.coach community! Whether you’ve been exploring every feature or just starting your journey with us, we’re glad to have you here.

Your experience matters to us—if there’s anything we can improve or if you have any feedback, we’d love to hear it.

Stay tuned for exciting updates and features coming your way!`,
    },
    {
        subject : "Thank You for Being an Active Member!",
        body : `Thank you for being an active part of desk.coach! We love seeing you engage and make the most of what we offer.

If there’s anything we can do to make your experience even better, we’d love to hear from you.

Keep shining`,
    },
    {
        subject : "We Miss You!",
        body : `Thanks for being part of desk.coach! We’ve noticed you haven’t been as active lately, and we’re wondering if there’s anything we can improve.
Let us know how we can make your experience better—we’re here to help!

Looking forward to seeing you back`
    },
    {
        subject : "We’d Love to Have You Back",
        body : `Thank you for being part of the desk.coach community! It’s been a while since we’ve seen you, and we’d love to have you back. Is there something holding you back?

We’re here to make things better, so don’t hesitate to share your thoughts. Come explore what’s new—you might just love it!

We hope to see you soon,`
    }

]


export default function UserActivityMonitor({organisation} : Props) {

//#region Initialization
    const searchParams = new URLSearchParams(window.location.search);
    const userStatus = searchParams.get("status");

    const [userData, setUserData] = useState<UserData[]>([]);
    const [filteredUserData, setFilteredUserData] = useState<UserData[]>([]);
    const [type, setType] = useState<number>(userStatus ? Number(userStatus) : 0);
    const [statusCount, setStatusCount] = useState<number[]>([0,0,0]);
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [emailMessage, setEmailMessage] = useState<string>("");
    const [emailSubject, setEmailSubject] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    
    const { data, loading, error } = useGetUserLatestActivities(organisation);
    
    useEffect(()=> {
        if(data) {
            const trackingData : UserData[] = data.user_activity_logs_tracking;
            for(let x = 0; x < trackingData.length; x++ ) {
                const userTracking = trackingData[x];
                statusCount[userTracking.status - 1] += 1;
                setStatusCount(statusCount);
            }

            setUserData(trackingData);
            setFilteredUserData(trackingData);
        }
    },[data,statusCount])
//#endregion Initialization

//#region Actions
    const ViewUserActivity = (item : UserData) => {
        history.push(`${DASHBOARD_ROOT}/reporting/dashboard/user-activity/${item._id}?name=${item.name}&status=${type}&organisation=${organisation || ""}`);
    }

    const HandleOpenModal=  () => {
        const preset = emailMessagePreset[type];
        setEmailMessage(preset.body);
        setEmailSubject(preset.subject);
        ToggleModal();
    }

    const ToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const HandleExport = async () => {
        setIsExporting(true);
        await ExportUserActivity(userData);
        setIsExporting(false);
    }

    const HandleChangeType = (id : number) => {
        setType(id);
        let filtered : UserData[] = [];
        const searchText = search?.toLowerCase();
        if(id === 0 && !search?.trim()) {
            filtered = userData
        }
        else if (id === 0 && search) {
            filtered = userData.filter(u=>u.name.toLowerCase().includes(searchText));
        }
        else if (id !== 0 && !search) {
            filtered = userData.filter(u=>u.status === id);
        }
        else {
            filtered = userData.filter(u=>id === 0 && u.name.toLowerCase().includes(searchText));
        }
        setFilteredUserData(filtered);
    }

    const HandleOnChangeEmail = (e : React.ChangeEvent<HTMLInputElement>) => {
        setEmailMessage(e.target.value);
    }
    const HandleOnChangeEmailSubject = (e : React.ChangeEvent<HTMLInputElement>) => {
        setEmailSubject(e.target.value);
    }

    const HandleSend = () => {
        if(isSending || !emailMessage || !emailSubject) { return; }

        setIsSending(true);
        setTimeout(function () {
            toast.success("Email has been sent successfully");
            setIsSending(false);
        },1000);
    }

//#endregion Actions

    return (
        <div>
            <Row className="mb-3">
                <Col className="pt-2">
                    <h4 className="mb-0">User Activity Tracker</h4>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button color="primary mr-2" onClick={HandleOpenModal}>Send Email to {UserStatusTypesArray[type - 1]?.name || "All"} Users</Button>
                    <Button color="primary" disabled={isExporting || loading} onClick={HandleExport}>Export</Button>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col  onClick={()=> { HandleChangeType(0) }}>
                    <Card className={`cursor-pointer text-center shadow ` + (type === 0 ? " border-primary text-primary" :"")}>
                        <CardBody className="pt-2 pb-2">
                            <b>
                            
                            <i className={`fas fa-users mr-2`}></i>
                            {userData.length} All</b>
                        </CardBody>
                    </Card>
                </Col>
                {
                    UserStatusTypesArray.map((item, index) => {
                        return (
                            <Col key={index} onClick={()=> { HandleChangeType(item.id) }}>
                                <Card className={`cursor-pointer text-center shadow ` + (type === item.id ? " border-primary text-primary" :"")}>
                                    <CardBody className="pt-2 pb-2">
                                        <b>
                                        
                                        <i className={`${item.icon} mr-2`}></i>
                                        {statusCount[index]} {item.name}</b>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <div className="d-flex mb-3">
                <Label className="mt-2 mr-3">Search: </Label>
                <Input value={search} placeholder="Search by typing the User Name here" onChange={(e)=>{ setSearch(e.target.value);}} />
            </div>
            {
                error &&
                <Alert color="danger">
                    Oops, we’re having trouble getting the users. Please give it a minute, and if it doesn’t resolve, let us know.
                </Alert>
            }    
            <div className="table-responsive" style={{ maxHeight:450}}>
                <Table  hover bordered>
                    <thead className="sticky-top bg-white">
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading && 
                            <tr>
                                <td colSpan={4} className="text-center ">
                                    <Loading />
                                </td>
                            </tr>
                        }
                        {
                            (!loading && filteredUserData.length) === 0 && 
                            <tr>
                                <td colSpan={4} className="text-center ">No Data Found</td>
                            </tr>
                        }
                        {
                            filteredUserData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        {
                                            item.status === UserStatusTypes.ACTIVE ? 
                                            <Badge color="success">Active</Badge>
                                            : item.status === UserStatusTypes.LOW_ACTIVITY ? 
                                            <Badge color="warning">Low Activity</Badge>
                                            : <Badge color="danger">Inactive</Badge>
                                        }
                                    </td>
                                    <td>
                                        <Button size="sm" onClick={HandleOpenModal}> Send Email</Button>
                                        <Button size="sm" onClick={() => ViewUserActivity(item)}> Activity History </Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
            <Modal
                id="activityMonitor-edit-modal"
                className="modal-lg"
                backdrop={true}
                isOpen={isModalOpen}>
                <ModalHeader toggle={ToggleModal} className="pb-0">
                    <p className="text-lg">Send Email</p>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Email Subject *</Label>
                        <Input
                            onChange={HandleOnChangeEmailSubject}
                            value={emailSubject}
                            placeholder="Please add your subject to the user(s) here"
                        />
                        {
                            !emailMessage && 
                            <FormError>Email subject is required</FormError>
                        }
                    </FormGroup>
                    <FormGroup>
                        <Label>Email Message *</Label>
                        <Input
                            onChange={HandleOnChangeEmail}
                            type="textarea"
                            rows="8"
                            value={emailMessage}
                            placeholder="Please add your message to the user(s) here"
                        />
                        {
                            !emailMessage && 
                            <FormError>Email message is required</FormError>
                        }
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" disabled={isSending} onClick={ToggleModal} >Close </Button>
                    <Button color="primary" disabled={isSending} onClick={HandleSend} >Send Email</Button>
                </ModalFooter>
            </Modal>                

        </div>
    );
}

import * as Yup from "yup";

const AddEmpowerMeTaskValidator = Yup.object({
    date : Yup.string()
        .required("Date is Required")
        .test(
            "is-future-date",
            "Please provide a time in the future",
        function (dateValue) {
            if (!(dateValue !== "" && dateValue !== undefined && dateValue !== null)) {
                return false;
            }

            // Ensure Date is not in the past
            var parsed = new Date(Date.parse(dateValue));
            const startDateReset = parsed.setHours(0, 0, 0, 0);
            const nowTimeReset = new Date().setHours(0, 0, 0, 0);

            if (startDateReset < nowTimeReset) {
                return false;
            }

            return true;
        }),
    time : Yup.string()
        .required("Time is Required")
        .test(
            "is-future-time",
            "Please provide a time in the future",
            function (timeValue) {
                const { date } = this.parent; // Access date from the parent object (the entire form)
        
                if (!(date && timeValue)) {
                  return false;
                }
        
                // Split time and parse it into hours and minutes
                const split = timeValue.split(":");
                const scheduledHour = parseInt(split[0], 10);
                const scheduledMinute = parseInt(split[1], 10);
        
                // Create a new Date object using the selected date and time
                const selectedDateTime = new Date(date);
                selectedDateTime.setHours(scheduledHour, scheduledMinute, 0, 0); // Set the time on the selected date
        
                // Compare the selected datetime with the current date/time
                const now = new Date();
        
                if (selectedDateTime <= now) {
                  return false;
                }
        
                return true;
            })
});

export default AddEmpowerMeTaskValidator;
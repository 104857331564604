import * as Yup from "yup";

const MIN_TITLE_LENGTH: number = 5;
const MAX_TITLE_LENGTH: number = 50;
const MIN_DESCRIPTION_LENGTH: number = 5;
const MAX_DESCRIPTION_LENGTH: number = 250;


const addTaskSequenceBucketValidator = Yup.object({
    name : Yup.string()
        .required("Name is Required")
        .min(
            MIN_TITLE_LENGTH,
            `Name must be at least ${MIN_TITLE_LENGTH} characters long`
        )
        .max(
            MAX_TITLE_LENGTH,
            `Name must be less than ${MAX_TITLE_LENGTH} characters long`
        ),
    description : Yup.string()
        .required("Description is Required")
        .min(
            MIN_DESCRIPTION_LENGTH,
            `Description text must be at least ${MIN_DESCRIPTION_LENGTH} characters long`
        )
        .max(
            MAX_DESCRIPTION_LENGTH,
            `Description text must be less than ${MAX_DESCRIPTION_LENGTH} characters long`
        ),
    type : Yup.number()
        .required("Type is Required")
        .min(5, "Type must be at least 5")
        .max(20, "Type must be at most 20"),
    sequenceList : Yup.array()
        .required("Please select a number of Sequence based on the Type")
        .test(
            "is-count-matched-to-type",
            "Please select a number of Sequence based on the Type ",
            function (value) {
                if(!value) return false;

                const { type } = this.parent;

                return value.length === Number(type)
            }
        )
    
});

export default addTaskSequenceBucketValidator;
import { gql } from "@apollo/client";

export const addActivity = gql`
  mutation addActivity($record: CreateOneUserActivityLogsInput!) {
    UserActivityLogs_CreateOne(record: $record) {
      record {
        _id
      }
    }
  }
`;
import { gql, useQuery } from "@apollo/client";

export function useOrganisation() {
  return useQuery(
    gql`
      query getOrganisations {
        organisations {
          _id 
          name
        }
      }
    `
  );
}

import { gql } from "@apollo/client";

export const getImageUploadURL = gql`
  query getImageUploadURL {
    getImageUploadURL {
      id
      uploadURL
    }
  }
`;

export const executeDeleteImage = gql`
  mutation deleteFileOnServer($fileId: String!) {
    deleteFileOnServer(fileId: $fileId) {
      fileId
    }
  }
`;

export const executeDeleteVideo = gql`
  mutation deleteVideoOnServer($fileId: String!) {
    deleteVideoOnServer(fileId: $fileId) {
      fileId
    }
  }
`;
